import { Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { useRef, useState } from 'react'

import { Button } from '@app/components/ui/button'
import {
  DialogActionTrigger,
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle
} from '@app/components/ui/dialog'
import { useStore } from '@app/store'
import type { Report } from '@graphql/types'

interface Props {
  report: Pick<Report, 'id' | 'emails' | 'sendToEmail' | 'sendToSlack'> & {
    slackChannel?: Pick<Report['slackChannel'], 'name'>
  }
  onSend?: () => void
}

const SendReportButton: FC<Props> = ({ report, onSend = () => {} }) => {
  const [open, setOpen] = useState(false)
  const cancelRef = useRef()
  const sendReport = useStore.use.sendReport()
  const disabled = (!report?.emails?.length && !report?.slackChannel) || (!report?.sendToEmail && !report?.sendToSlack)

  const handleSend = () => {
    sendReport(report.id)
    onSend()

    setOpen(false)
  }

  return (
    <>
      <Button disabled={disabled} onClick={() => setOpen(true)}>
        Send
      </Button>

      <DialogRoot
        initialFocusEl={cancelRef?.current}
        onOpenChange={(e) => setOpen(e.open)}
        open={open}
        placement="center"
        role="alertdialog"
      >
        <DialogBackdrop />
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Send report</DialogTitle>
          </DialogHeader>

          <DialogBody>
            <Stack>
              <Text>Are you sure? it will send to the following recipients immediately:</Text>
              {report.sendToEmail && report.emails && (
                <Stack gap={0}>
                  <Text fontWeight="semibold">{report.emails.length > 1 ? 'Emails' : 'Email'}</Text>
                  <Text>{report.emails.split(',').join(', ')}</Text>
                </Stack>
              )}
              {report.sendToSlack && report.slackChannel && (
                <Stack gap={0}>
                  <Text fontWeight="semibold">Slack channel</Text>
                  <Text>{report.slackChannel.name}</Text>
                </Stack>
              )}
            </Stack>
          </DialogBody>

          <DialogFooter>
            <DialogActionTrigger asChild>
              <Button ref={cancelRef} variant="outline">
                Cancel
              </Button>
            </DialogActionTrigger>
            <Button onClick={handleSend}>Send</Button>
          </DialogFooter>
          <DialogCloseTrigger />
        </DialogContent>
      </DialogRoot>
    </>
  )
}

export default SendReportButton
