import { HStack, IconButton, Spacer } from '@chakra-ui/react'
import type { FC } from 'react'
import { FiEdit2, FiPlus } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import MetricDataPointDeleteButton from './metricDataPointDeleteButton'

import { Button } from '@app/components/ui/button'
import { MenuContent, MenuItem, MenuRoot, MenuTrigger } from '@app/components/ui/menu'
import { toaster } from '@app/components/ui/toaster'
import MetricCreateButton from '@app/pages/metrics/components/metricCreateButton'
import MetricCSVModal from '@app/pages/metrics/components/metricCSVModal'
import MetricDownloadCSVButton from '@app/pages/metrics/components/metricDownloadCSVButton'
import MetricDataPointsClearButton from '@app/pages/metrics/metricDataPoints/components/metricDataPointsClearButton'
import Can from '@app/shared/authorization/can'
import type { DomainMetric } from '@app/types'
import ICON_MAP from '@app/utils/iconMap'
import type { MetricDataPoint } from '@graphql/queries'

interface MetricDataPointListActionsHeaderProps {
  metric: DomainMetric
}

export const MetricDataPointListActionsHeader: FC<MetricDataPointListActionsHeaderProps> = ({ metric }) => (
  <HStack>
    <Spacer />
    <MenuRoot>
      <MenuTrigger asChild>
        <Button borderRadius="sm" size="xs">
          Actions <ICON_MAP.MoreOptions />
        </Button>
      </MenuTrigger>
      <MenuContent>
        <MenuItem value="metric_create">
          <MetricCreateButton leftIcon={<FiPlus />} text="Add" variant="plain" />
        </MenuItem>
        <MenuItem value="metric_download_csv">
          {(metric.metricDataPointsCount || 0) > 0 && <MetricDownloadCSVButton metric={metric} variant="plain" />}
        </MenuItem>
        <MenuItem value="metric_csv">
          <MetricCSVModal metric={metric} />
        </MenuItem>
        <MenuItem value="metric_data_points_clear">
          <MetricDataPointsClearButton metric={metric} />
        </MenuItem>
      </MenuContent>
    </MenuRoot>
  </HStack>
)

interface MetricDataPointListActionsProps {
  data: Pick<MetricDataPoint, 'id' | 'metricId'>
}

const MetricDataPointListActions: FC<MetricDataPointListActionsProps> = ({ data: metricDataPoint }) => {
  const { id } = metricDataPoint

  const onDelete = () => {
    toaster.create({ title: 'Deleted metric data point' })
  }

  return (
    <Can I="update" a="metricDataPoint">
      <HStack>
        <Spacer />
        <IconButton aria-label="Edit data point" asChild variant="ghost">
          <Link to={`${id}/edit`}>
            <FiEdit2 />
          </Link>
        </IconButton>
        <MetricDataPointDeleteButton metricDataPoint={metricDataPoint} onDelete={onDelete} />
      </HStack>
    </Can>
  )
}

export default MetricDataPointListActions
