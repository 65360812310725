import sortBy from 'lodash/sortBy'
import type { FC } from 'react'
import { useMemo } from 'react'

import { AvatarGroup } from '@app/components/ui/avatar'
import { useUserPresencesContext } from '@app/pages/maps/userPresencesContext'
import cardAvatarSize from '@app/shared/cards/cardAvatarSize'
import TooltipAvatar from '@app/shared/tooltipAvatar'
import profileImage from '@app/utils/profileImage'

const UserPresences: FC = () => {
  const { presences } = useUserPresencesContext()

  const users = useMemo(
    () =>
      sortBy(
        Object.values(presences).map((presence) => presence.user),
        'name'
      ),
    [presences]
  )

  if (!users || users.length === 0) {
    return null
  }

  return (
    <AvatarGroup>
      {users.slice(0, 10).map((user) => (
        <TooltipAvatar
          key={user.id}
          name={user.name}
          size={cardAvatarSize('2xs', 'normal')}
          src={profileImage(user.email)}
        />
      ))}
    </AvatarGroup>
  )
}

export default UserPresences
