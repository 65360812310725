import { Icon, Input, Link, Text } from '@chakra-ui/react'
import type { FC, MutableRefObject } from 'react'
import { useImperativeHandle, useState } from 'react'
import { FiHelpCircle } from 'react-icons/fi'

import { Field } from '@app/components/ui/field'
import { NativeSelectField, NativeSelectRoot } from '@app/components/ui/native-select'
import type { MetricSourceFormApi } from '@app/types'
import type { MetricSource } from '@graphql/types'

export interface Props {
  metricSource: Pick<MetricSource, 'configuration'> | null
  disabled: boolean
  apiRef: MutableRefObject<MetricSourceFormApi>
}

// e.g. 'https://mixpanel.com/project/123/view/456/app/boards#id=777&editor-card-id=%22report-88888%22'
// const INSIGHTS_REGEX = /https:\/\/mixpanel\.com\/project\/\d+\/view\/\d+\/app\/boards.+editor-card-id=.+report-\d+.+/

const EventFields: FC<Props> = ({ apiRef: _, metricSource, disabled }) => (
  <>
    <Field label="Event name" disabled={disabled} required>
      <Input
        defaultValue={metricSource?.configuration?.event}
        name="configuration.event"
        placeholder="Mixpanel event name"
      />
    </Field>
    <Field
      disabled={disabled}
      label={
        <>
          <Text display="inline">Mixpanel &quot;where&quot; field (optional) </Text>

          <Link
            verticalAlign="middle"
            color="link"
            href="https://developer.mixpanel.com/reference/segmentation-expressions"
            rel="onoopener noreferrer"
            target="_blank"
          >
            <Icon boxSize={4}>
              <FiHelpCircle />
            </Icon>
          </Link>
        </>
      }
    >
      <Input
        defaultValue={metricSource?.configuration?.where}
        name="configuration.where"
        placeholder='Mixpanel "where" field'
      />
    </Field>
  </>
)

const InsightsFields: FC<Props> = ({ apiRef, metricSource, disabled }) => {
  const defaultUrl = metricSource?.configuration?.url || ''
  const [url, setUrl] = useState<string>(defaultUrl)

  useImperativeHandle(
    apiRef,
    () => ({
      reset: () => setUrl(defaultUrl)
    }),
    [defaultUrl]
  )

  return (
    <Field disabled={disabled} required label="Insights report URL">
      <Input
        name="configuration.url"
        onChange={(e) => setUrl(e.target.value)}
        placeholder='https://mixpanel.com/project/1111/view/2222/app/boards#id=3333&editor-card-id="report-12345"'
        value={url}
      />
    </Field>
  )
}

type MixpanelDataSource = 'event' | 'insights'

const Mixpanel: FC<Props> = ({ apiRef, metricSource, disabled }) => {
  const [dataSource, setDataSource] = useState<MixpanelDataSource>(metricSource?.configuration?.data_source || 'event')
  const Fields = dataSource === 'insights' ? InsightsFields : EventFields

  return (
    <>
      <Field label="Report Type" disabled={disabled} required>
        <NativeSelectRoot>
          <NativeSelectField
            name="configuration.data_source"
            value={dataSource}
            placeholder={null}
            onChange={(e) => setDataSource(e.target.value as MixpanelDataSource)}
          >
            <option value="event">Event Data</option>
            <option value="insights">Insights Report</option>
          </NativeSelectField>
        </NativeSelectRoot>
      </Field>
      <Fields metricSource={metricSource} disabled={disabled} apiRef={apiRef} />
    </>
  )
}

export default Mixpanel
