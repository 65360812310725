import { HStack, Spacer, Stack, Separator, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'
import { PiDownload } from 'react-icons/pi'

import { Button } from '@app/components/ui/button'
import fetchDownload from '@app/lib/fetchDownload'
import type { DomainStrategy } from '@app/types'
import { Paths } from '@app/utils/routeHelpers'

type Props = {
  strategy: Pick<DomainStrategy, 'id'>
}

const DownloadDataButtons: FC<Props> = ({ strategy }) => {
  const [isDownloadingPlaybook, setIsDownloadingPlaybook] = useState(false)
  const [isDownloadingWorks, setIsDownloadingWorks] = useState(false)
  const [isDownloadingMetrics, setIsDownloadingMetrics] = useState(false)

  if (!strategy) {
    return null
  }

  const strategyId = strategy.id

  const downloadPlaybook = async () => {
    setIsDownloadingPlaybook(true)

    try {
      return await fetchDownload(Paths.downloadStrategyPlaybookPath({ strategyId }))
    } finally {
      setIsDownloadingPlaybook(false)
    }
  }

  const downloadWorks = async () => {
    setIsDownloadingWorks(true)

    try {
      return await fetchDownload(Paths.downloadStrategyWorksCSVPath({ strategyId }))
    } finally {
      setIsDownloadingWorks(false)
    }
  }

  const downloadMetrics = async () => {
    setIsDownloadingMetrics(true)

    try {
      return await fetchDownload(Paths.downloadStrategyMetricsCSVPath({ strategyId }))
    } finally {
      setIsDownloadingMetrics(false)
    }
  }

  return (
    <Stack gap={4} separator={<Separator />}>
      <HStack>
        <Text fontSize="md">Download playbook</Text>
        <Spacer />
        <Button
          aria-label="Download playbook"
          variant="subtle"
          size="sm"
          loading={isDownloadingPlaybook}
          onClick={downloadPlaybook}
        >
          <PiDownload /> Download
        </Button>
      </HStack>
      <HStack>
        <Text fontSize="md">Download work</Text>
        <Spacer />
        <Button
          aria-label="Download work"
          variant="subtle"
          size="sm"
          loading={isDownloadingWorks}
          onClick={downloadWorks}
        >
          <PiDownload /> Download
        </Button>
      </HStack>
      <HStack>
        <Text fontSize="md">Download metric data</Text>
        <Spacer />
        <Button
          aria-label="Download metric data"
          variant="subtle"
          size="sm"
          loading={isDownloadingMetrics}
          onClick={downloadMetrics}
        >
          <PiDownload /> Download
        </Button>
      </HStack>
    </Stack>
  )
}

export default DownloadDataButtons
