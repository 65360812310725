import { Input, Stack, VisuallyHidden } from '@chakra-ui/react'
import type { FC, FormEventHandler } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Button } from '@app/components/ui/button'
import { Field } from '@app/components/ui/field'
import { PasswordInput } from '@app/components/ui/password-input'
import { toaster } from '@app/components/ui/toaster'
import FormAlert from '@app/next/forms/formAlert'
import SessionHeader from '@app/pages/sessions/components/sessionHeader'
import unpackErrors from '@app/utils/unpackErrors'
import { useUserResetPasswordMutation } from '@graphql/queries'

const ResetPassword: FC = () => {
  const { passwordResetToken } = useParams()
  const [errors, setErrors] = useState<ReturnType<typeof unpackErrors>>({})
  const [, mutation] = useUserResetPasswordMutation()

  const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    const input = {
      password: formData.get('password') as string,
      passwordResetToken
    }

    try {
      const response = await mutation({ input })

      if (response.error) {
        setErrors(unpackErrors([response.error]))
        return
      }

      const { errors: mutationErrors } = response.data.userResetPassword

      if (mutationErrors?.length) {
        setErrors(unpackErrors(mutationErrors))
        return
      }

      toaster.create({
        title: 'Password reset',
        placement: 'bottom-end',
        type: 'success'
      })

      window.location.href = '/' // don't use navigate for this
    } catch (resp) {
      setErrors(unpackErrors([resp]))
    }
  }

  return (
    <Stack gap="8" w="full" maxW="md" px={{ base: '4', md: '8' }} py={{ base: '12', md: '48' }}>
      <SessionHeader
        title="Reset your password"
        subtitle="Remembered your password?"
        linkText="Sign in"
        linkUrl="/sign_in"
      />

      <Stack gap="6">
        <form id="reset-password-form" onSubmit={onSubmit}>
          <VisuallyHidden asChild>
            <Input defaultValue={passwordResetToken} name="passwordResetToken" />
          </VisuallyHidden>
          <FormAlert description={errors?.global?.message} />
          <Stack gap="5">
            <Field label="New Password" invalid={!!errors?.password} required errorText={errors?.password?.message}>
              <PasswordInput name="password" placeholder="Enter new password" autoComplete="new-password" />
            </Field>
            <Button form="reset-password-form" size="md" type="submit">
              Set new password
            </Button>
          </Stack>
        </form>
      </Stack>
    </Stack>
  )
}

export default ResetPassword
