import { Box, HStack } from '@chakra-ui/react'
import type { ConditionalValue } from '@chakra-ui/react'
import type { FC } from 'react'

import { Button } from '@app/components/ui/button'
import { useStore } from '@app/store'
import type { CardSize as CardSizeType, DomainNode } from '@app/types'

interface Props {
  node: DomainNode
}

const CardSize: FC<Props> = ({ node }) => {
  const updateNode = useStore.use.updateNode()
  const currentSize = node?.metadata?.size || 'normal'
  const { strategyId } = node

  const changeSize = (size: CardSizeType) => {
    updateNode(strategyId, node.id, { metadata: { ...node.metadata, size } })
  }

  const sizeButtonParams = (size: CardSizeType) => ({
    colorPalette: 'gray',
    size: 'xs' as ConditionalValue<'sm' | 'md' | 'lg' | 'xl' | '2xl' | '2xs' | 'xs'>,
    w: '100%',
    variant:
      size === currentSize
        ? 'solid'
        : ('surface' as ConditionalValue<'outline' | 'ghost' | 'solid' | 'subtle' | 'surface' | 'plain'>),

    onClick: () => changeSize(size)
  })

  return (
    <HStack>
      <Box w="100%">
        <Button {...sizeButtonParams('large')}>Large</Button>
      </Box>
      <Box w="100%">
        <Button {...sizeButtonParams('normal')}>Normal</Button>
      </Box>
    </HStack>
  )
}

export default CardSize
