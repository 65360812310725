import { Textarea } from '@chakra-ui/react'
import type { TextareaProps } from '@chakra-ui/react'
import type { FC } from 'react'
import { useEffect, useRef, useState } from 'react'

import { Field } from '@app/components/ui/field'
import useAutosizeTextArea, { resizeTextArea } from '@app/hooks/useAutosizeTextArea'
import useInlineEditorProps from '@app/hooks/useInlineEditorProps'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import type { DomainObject } from '@app/types'

interface Props extends TextareaProps {
  domainObject: DomainObject
  name: string
}

const TextAreaInput: FC<Props> = ({ domainObject, name, ...rest }) => {
  const domainObjectValue = domainObject[name]
  const [value, setValue] = useState(domainObjectValue)

  useEffect(() => {
    setValue(domainObjectValue)
  }, [domainObjectValue])

  const { onChange, onBlur } = useInlineEditorProps(domainObject)
  const { user } = useStoreCurrentUser()
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const editor = ['admin', 'editor'].includes(user?.role)

  useAutosizeTextArea(textAreaRef.current, value as string)

  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(evt.target.value)

    onChange(evt)
  }

  // See if this prevents the cut-off text issue ENG-1377
  useEffect(() => {
    resizeTextArea(textAreaRef.current)
  })

  return (
    <Field disabled={!editor}>
      <Textarea
        className="nodrag"
        ref={textAreaRef}
        overflow="hidden"
        px={0}
        border="none"
        resize="none"
        data-1p-ignore
        name={name}
        onBlur={onBlur}
        onChange={handleChange}
        rows={1}
        value={value}
        {...rest}
      />
    </Field>
  )
}

export default TextAreaInput
