import { type Dialog } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'

import MetricIntegrationModal from './metricIntegrationModal'

import { Button } from '@app/components/ui/button'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'

type Props = Partial<Dialog.RootProps>

const MetricIntegrationCreateModal: FC<Props> = (props) => {
  const [open, setOpen] = useState(false)

  const { user } = useStoreCurrentUser()

  if (!user) {
    return null
  }

  return (
    <>
      <Button size="sm" onClick={() => setOpen(true)}>
        + Add integration
      </Button>

      <MetricIntegrationModal
        open={open}
        onOpenChange={(e) => {
          setOpen(e.open)
        }}
        {...props}
      />
    </>
  )
}

export default MetricIntegrationCreateModal
