import { Input, Stack, VisuallyHidden } from '@chakra-ui/react'
import type { FC, FormEventHandler } from 'react'
import { useState } from 'react'
import { useParams, useAsyncValue } from 'react-router-dom'

// import AuthProviders from '@app/pages/sessions/components/authProviders'
import { Button } from '@app/components/ui/button'
import { Field } from '@app/components/ui/field'
import { PasswordInput } from '@app/components/ui/password-input'
import { toaster } from '@app/components/ui/toaster'
import FormAlert from '@app/next/forms/formAlert'
import SessionHeader from '@app/pages/sessions/components/sessionHeader'
import TermsAndConditionsToggle from '@app/shared/termsAndConditionsToggle'
import withAwait from '@app/shared/withAwait'
import unpackErrors from '@app/utils/unpackErrors'
import type { Invitation } from '@graphql/queries'
import { useUserCreateMutation } from '@graphql/queries'
import type { UserCreateInput } from '@graphql/types'

type ResolvedInvitation = {
  invitation?: Invitation
}

interface InvitationPromise {
  data?: ResolvedInvitation
}

const SignUp: FC = () => {
  const {
    data: { invitation }
  }: InvitationPromise = useAsyncValue()
  const { inviteCode } = useParams()
  const pageTitle = inviteCode ? 'Join your Organization' : 'Create your account'
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
  const [errors, setErrors] = useState<ReturnType<typeof unpackErrors>>({})
  const [loading, setLoading] = useState(false)
  const [, mutation] = useUserCreateMutation()

  const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    const input = Object.fromEntries(formData.entries()) as UserCreateInput

    try {
      const response = await mutation({ input })

      if (response.error) {
        const errs = unpackErrors([response.error])
        setErrors(errs)
        return
      }

      const { errors: createErrors } = response.data.userCreate

      if (createErrors?.length) {
        setErrors(unpackErrors(createErrors))
        return
      }

      toaster.create({
        title: 'Creating your account',
        placement: 'bottom-end',
        type: 'success'
      })

      window.location.href = '/' // don't use navigate() for this
    } catch (resp) {
      setErrors(unpackErrors(resp))
    } finally {
      setLoading(false)
    }
  }

  return (
    <Stack gap="8">
      <SessionHeader title={pageTitle} subtitle="Already have an account?" linkText="Sign in" linkUrl="/sign_in" />

      <Stack gap="6">
        <form id="sign-up-form" onSubmit={onSubmit}>
          <VisuallyHidden asChild>
            <Input defaultValue={inviteCode} name="inviteCode" />
          </VisuallyHidden>
          <VisuallyHidden asChild>
            <Input defaultValue={timeZone} name="timeZone" />
          </VisuallyHidden>

          <Stack gap="5">
            <FormAlert description={errors?.global?.message} />

            <Field label="Name" id="name" invalid={!!errors?.name} required errorText={errors?.name?.message}>
              <Input autoComplete="name" id="name" name="name" placeholder="Your name" />
            </Field>

            <Field label="Email" id="email" invalid={!!errors?.email} required errorText={errors?.email?.message}>
              <Input
                autoComplete="email"
                defaultValue={invitation?.email}
                id="email"
                name="email"
                placeholder="Your work email"
                type="email"
              />
            </Field>

            <Field label="Password" invalid={!!errors?.password} required errorText={errors?.password?.message}>
              <PasswordInput name="password" placeholder="Password" autoComplete="new-password" minLength={8} />
            </Field>
            <Field
              label="Password Confirmation"
              invalid={!!errors?.passwordConfirmation}
              errorText={errors?.passwordConfirmation?.message}
              required
            >
              <PasswordInput
                name="passwordConfirmation"
                placeholder="Password confirmation"
                autoComplete="new-password"
                required
                minLength={8}
              />
            </Field>
            <Stack gap={4}>
              <TermsAndConditionsToggle />
            </Stack>
            <Stack gap={4}>
              <Button form="sign-up-form" loading={loading} size="md" type="submit">
                Sign up
              </Button>
              {/* <AuthProviders inviteCode={inviteCode} text="Sign up with" /> */}
            </Stack>
          </Stack>
        </form>
      </Stack>
    </Stack>
  )
}

export default withAwait(SignUp, 'invitation')
