import { Card, Icon, LinkBox, LinkOverlay, Stack } from '@chakra-ui/react'
import type { FC } from 'react'
import { PiPlus } from 'react-icons/pi'
import { Link } from 'react-router-dom'

import { cardHeight } from '@app/pages/aiDashboard/components/constants'

const DashboardCardNew: FC = () => (
  <Card.Root
    h={cardHeight}
    variant="elevated"
    _hover={{ borderColor: 'blue.500', borderWidth: 1 }}
    transition="border-color 0.2s ease-in-out"
  >
    <Card.Body h="full">
      <LinkBox as="article" h="full" w="full" alignContent="center">
        <Stack gap={4} alignItems="center">
          <Icon boxSize={6}>
            <PiPlus />
          </Icon>
          <LinkOverlay asChild>
            <Link to="reports/recurring/create">Create a new AI report</Link>
          </LinkOverlay>
        </Stack>
      </LinkBox>
    </Card.Body>
  </Card.Root>
)

export default DashboardCardNew
