import { Box } from '@chakra-ui/react'
import { useParams, useRouteLoaderData } from 'react-router-dom'

import GoalForm from './goalForm'

import useFormSubmitAction from '@app/hooks/useFormSubmitAction'
import useGetObject from '@app/hooks/useGetObject'
import useRouteLoaderRouteId from '@app/hooks/useRouteLoaderRouteId'
import type { Goal } from '@graphql/queries'

const Edit = () => {
  const { nodeId } = useParams()
  const metric = useGetObject(nodeId, 'metric')

  const goalRouteId = useRouteLoaderRouteId('goal')
  const { goal } = useRouteLoaderData(goalRouteId) as { goal: Goal }
  const action = useFormSubmitAction('..')

  return (
    <Box p={6}>
      <GoalForm goal={goal} metric={metric} action={action} />
    </Box>
  )
}

export default Edit
