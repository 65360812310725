import { IconButton, Spinner } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'
import { useState } from 'react'

import type { ButtonProps } from '@app/components/ui/button'
import { toaster } from '@app/components/ui/toaster'
import { Tooltip } from '@app/components/ui/tooltip'
import { useStore } from '@app/store'
import type { DomainNode } from '@app/types'
import ICON_MAP from '@app/utils/iconMap'
import { MetricNodesGenerate } from '@graphql/documents/strategy.graphql'
import type { MetricNodesGenerateMutation, MetricNodesGenerateMutationVariables } from '@graphql/queries'

interface Props {
  node: Pick<DomainNode, 'nodeId'>
  children?: (props: Partial<ButtonProps>) => ReactNode
}

const buttonStyles = {
  borderRadius: 'sm'
}

const defaultChildren = ({ loading, onClick }) => (
  <IconButton {...buttonStyles} aria-label="Suggest downstream metrics" onClick={onClick} size="sm" variant="outline">
    {loading ? <Spinner size="xs" /> : <ICON_MAP.AiPrompt />}
  </IconButton>
)

const SuggestDownstreamMetricsButton: FC<Props> = ({ node, children = defaultChildren }) => {
  const [loading, setLoading] = useState(false)
  const actionMutation = useStore.use.actionMutation()

  const onClick = async () => {
    setLoading(true)

    try {
      const result = await actionMutation<MetricNodesGenerateMutation, MetricNodesGenerateMutationVariables>(
        MetricNodesGenerate,
        {
          nodeId: node.nodeId
        }
      )

      if (result.error) {
        if (result.error.networkError) {
          toaster.create({ title: 'Error', description: result.error.networkError.message, type: 'error' })
        } else {
          const errors = result.error.graphQLErrors.map((e) => e.message).join(', ')
          toaster.create({ title: 'Error', description: errors, type: 'error' })
        }
      }
    } catch (e) {
      toaster.create({ title: 'Error', description: e.message, type: 'error' })
    } finally {
      setLoading(false)
    }
  }

  return <Tooltip content="Suggest downstream metrics">{children({ loading, onClick })}</Tooltip>
}

export default SuggestDownstreamMetricsButton
