import { Outlet } from 'react-router-dom'

import Show from '@app/pages/correlations/show'

const routes = {
  path: 'correlations',
  element: <Outlet />,
  children: [
    {
      path: 'graph/:sourceId/:targetId',
      element: <Show />
    }
  ]
}

export default routes
