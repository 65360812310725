import type { User } from '@graphql/types'

type Track = (
  eventName: string,
  user?: Pick<User, 'optedInToAnalytics'>,
  eventProperties?: Record<string, unknown>
) => void

const track: Track = (eventName, user = null, eventProperties = {}) => {
  if (import.meta.env.MODE === 'development') {
    console.log('Segment Analytics (stub):', eventName, eventProperties) // eslint-disable-line no-console

    return
  }

  if (typeof analytics === 'undefined') {
    return
  }

  if (user && !user.optedInToAnalytics) {
    return
  }

  analytics.track(eventName, eventProperties)
}

export default track
