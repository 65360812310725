import type { StackProps } from '@chakra-ui/react'
import { Stack, StackSeparator } from '@chakra-ui/react'
import type { FC, PropsWithChildren } from 'react'
import { memo } from 'react'

type Props = PropsWithChildren<StackProps>

const MapDrawer: FC<Props> = (props) => (
  <Stack
    pos="absolute"
    top="24px"
    bottom={16}
    left="74px"
    w="385px"
    pt={2}
    borderRadius="sm"
    shadow="0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)"
    bgColor="bg"
    separator={<StackSeparator />}
    {...props}
  />
)

export default memo(MapDrawer)
