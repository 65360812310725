import type { FC, MouseEvent } from 'react'
import { useState } from 'react'
import { BiPlay } from 'react-icons/bi'
import { Link, useNavigate } from 'react-router-dom'

import { Button } from '@app/components/ui/button'
import type { ButtonProps } from '@app/components/ui/button'
import { toaster } from '@app/components/ui/toaster'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import Can from '@app/shared/authorization/can'
import { usePlaybookApplyMutation } from '@graphql/queries'
import type { Playbook } from '@graphql/queries'

interface Props extends ButtonProps {
  playbook: Playbook
}

const UsePlaybookButton: FC<Props> = ({ playbook, ...rest }) => {
  const { id: playbookId, aiBuilderSlug } = playbook
  const { user } = useStoreCurrentUser()
  const [, createStrategyFromPlaybook] = usePlaybookApplyMutation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    // Prevent the parent overlay (ImageWithOverlay) link from firing when this button is clicked.
    e.preventDefault()
    setLoading(true)

    createStrategyFromPlaybook({
      input: {
        playbookId
      }
    })
      .then((resp) => {
        if (resp.error) {
          throw resp
        }

        const { errors } = resp.data.playbookApply

        if (errors.length) {
          throw errors
        }

        toaster.create({
          title: 'Creating strategy',
          type: 'success'
        })

        const strategyId = resp.data.playbookApply.strategy.id
        let redirectUrl = `/strategy/${strategyId}`

        if (aiBuilderSlug) {
          redirectUrl = `${redirectUrl}/map/ai/${aiBuilderSlug}/new`
        }

        navigate(redirectUrl)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const buttonCTA = aiBuilderSlug ? 'Build with AI' : 'Use Playbook'
  const buttoncolorPalette = aiBuilderSlug ? 'green' : null
  if (!user) {
    return (
      <Button key="sign_up" aria-label="Sign up to use this playbook" {...rest} asChild>
        <Link to={`/sign_up?utm_source=playbooks&utm_medium=public&utm_content=use&utm_campaign=${playbookId}`}>
          <BiPlay /> {buttonCTA}
        </Link>
      </Button>
    )
  }

  return (
    <Can I="use" a="playbook">
      <Button {...rest} colorPalette={buttoncolorPalette} loading={loading} onClick={handleClick}>
        {buttonCTA}
      </Button>
    </Can>
  )
}

export default UsePlaybookButton
