import { Button } from '@chakra-ui/react'
import { useCallback } from 'react'
import type { OperationResult, UseMutationResponse } from 'urql'

import { Tooltip } from '@app/components/ui/tooltip'

// Base type for our mutation variables
interface MutationVariables {
  input: {
    chatId: string
  }
}

type Props<TMutation, TVariables extends MutationVariables> = {
  chatId: string
  buttonLabel: string
  onLabelChange: (label: string) => void
  disabled?: boolean
  mutation: UseMutationResponse<TMutation, TVariables>
  getLabelFromResponse: (response: OperationResult<TMutation, TVariables>) => string | undefined
  colorPalette?: string
}

const AssistantButton = <TMutation, TVariables extends MutationVariables>({
  chatId,
  buttonLabel,
  onLabelChange,
  disabled = false,
  mutation,
  getLabelFromResponse,
  colorPalette = 'green'
}: Props<TMutation, TVariables>) => {
  const [result, mutate] = mutation

  const onClick = useCallback(async () => {
    if (chatId) {
      const response = await mutate({
        input: {
          chatId
        }
      } as TVariables)
      const newLabel = getLabelFromResponse(response)
      if (newLabel) {
        onLabelChange(newLabel)
      }
    }
  }, [chatId, mutate, onLabelChange, getLabelFromResponse])

  return (
    <Tooltip aria-label={buttonLabel} content={buttonLabel}>
      <Button
        me={2}
        colorPalette={colorPalette}
        disabled={disabled || result.fetching}
        onClick={onClick}
        size="xs"
        type="button"
      >
        {buttonLabel}
      </Button>
    </Tooltip>
  )
}

export default AssistantButton
