import delay from 'lodash/delay'
import type { FC } from 'react'
import { useCallback, useMemo, useState } from 'react'

import MapSegmentFiltersModalContext from './mapSegmentFiltersModalContext'
import SegmentFilters from './segmentFilters'

import { Button } from '@app/components/ui/button'
import {
  DialogActionTrigger,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle
} from '@app/components/ui/dialog'
import { Tooltip } from '@app/components/ui/tooltip'
import type { SegmentFilter, SegmentName, SegmentValue } from '@graphql/types'

interface Props {
  strategyId: string
  segmentFilters?: (Pick<SegmentFilter, 'id'> & {
    segmentName: Pick<SegmentName, 'id' | 'name'>
    segmentValue: Pick<SegmentValue, 'id' | 'value'>
  })[]
  open?: boolean
  onOpenChange?: (_e) => void
  onFilter?: (arg0: string[], arg1: string[]) => void
}

const SegmentFiltersModal: FC<Props> = ({
  strategyId,
  segmentFilters = [],
  open,
  onOpenChange = () => {},
  onFilter = () => {}
}) => {
  const [activeSegmentName, setActiveSegmentName] = useState(null)
  const [uncommitedChanges, setUncommitedChanges] = useState({ addIds: [], removeIds: [] })

  const handleClose = useCallback(
    (e) => {
      onOpenChange(e)
      delay(() => {
        setActiveSegmentName(null)
        setUncommitedChanges({ addIds: [], removeIds: [] })
      }, 500)
    },
    [onOpenChange]
  )

  const handleApply = () => {
    onFilter(uncommitedChanges.addIds, uncommitedChanges.removeIds)

    onOpenChange({ open: false })

    delay(() => {
      setActiveSegmentName(null)
      setUncommitedChanges({ addIds: [], removeIds: [] })
    }, 500)
  }

  const providerValue = useMemo(
    () => ({
      segmentFilters,
      activeSegmentName,
      setActiveSegmentName,
      onFilter,
      uncommitedChanges,
      setUncommitedChanges
    }),
    [segmentFilters, activeSegmentName, onFilter, uncommitedChanges, setUncommitedChanges]
  )

  const hasSegmentChanges = !!(uncommitedChanges.addIds.length || uncommitedChanges.removeIds.length)

  return (
    <MapSegmentFiltersModalContext.Provider value={providerValue}>
      <DialogRoot onOpenChange={handleClose} open={open} closeOnInteractOutside closeOnEscape>
        <DialogContent shadow="dark-lg">
          <DialogHeader>
            <DialogTitle>Segments</DialogTitle>
          </DialogHeader>
          <DialogCloseTrigger />
          <DialogBody p={0}>
            <SegmentFilters strategyId={strategyId} />
          </DialogBody>
          <DialogFooter>
            <DialogActionTrigger>
              <Button variant="outline">Cancel</Button>
            </DialogActionTrigger>
            <Tooltip disabled={hasSegmentChanges} showArrow content="Make some segment changes to apply.">
              <Button disabled={!hasSegmentChanges} onClick={handleApply}>
                Apply
              </Button>
            </Tooltip>
          </DialogFooter>
        </DialogContent>
      </DialogRoot>
    </MapSegmentFiltersModalContext.Provider>
  )
}

export default SegmentFiltersModal
