import { Input } from '@chakra-ui/react'
import type { FC } from 'react'
import type { FormEventHandler } from 'react'
import { useCallback, useState } from 'react'

import { Button } from '@app/components/ui/button'
import {
  DialogActionTrigger,
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
  DialogTrigger
} from '@app/components/ui/dialog'
import { Field } from '@app/components/ui/field'
import { NativeSelectField, NativeSelectRoot } from '@app/components/ui/native-select'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import { METRIC_SOURCES } from '@app/lib/globals'
import { useLookerDashboardCreateMutation } from '@graphql/queries'
import type { Credential, LookerDashboardCreateInput } from '@graphql/types'

interface Props {
  credentials: Credential[]
}

const LookerDashboardCreateDialog: FC<Props> = ({ credentials }) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const { user } = useStoreCurrentUser()
  const [, createMetricSource] = useLookerDashboardCreateMutation()

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      e.preventDefault()
      setLoading(true)

      const formData = new FormData(e.currentTarget)
      const formObject = Object.fromEntries(formData.entries()) as LookerDashboardCreateInput

      await createMetricSource({ input: formObject })

      setLoading(false)

      setOpen(false)
    },
    [createMetricSource]
  )

  if (!user) {
    return null
  }

  return (
    <DialogRoot open={open} onOpenChange={(e) => setOpen(e.open)} placement="center">
      <DialogBackdrop />
      <DialogTrigger asChild>
        <Button size="sm">+ Add dashboard</Button>
      </DialogTrigger>
      <DialogContent>
        <form onSubmit={onSubmit}>
          <DialogHeader>
            <DialogTitle>Add a Looker dashboard</DialogTitle>
            <DialogCloseTrigger />
          </DialogHeader>
          <DialogBody>
            <Field label="Dashboard URL" required>
              <Input name="dashboardUrl" placeholder="https://looker.company.com/dashboards/123" />
            </Field>
            <Field label="Credential" required>
              <NativeSelectRoot>
                <NativeSelectField
                  name="credentialId"
                  placeholder="Select a credential"
                  defaultValue={credentials.length ? credentials[0].id : ''}
                >
                  {credentials.map((credential) => (
                    <option key={credential.id} value={credential.id}>
                      {METRIC_SOURCES[credential.sourceName].display} {credential.label ? ` - ${credential.label}` : ''}
                    </option>
                  ))}
                </NativeSelectField>
              </NativeSelectRoot>
            </Field>
          </DialogBody>
          <DialogFooter>
            <DialogActionTrigger>
              <Button variant="ghost">Cancel</Button>
            </DialogActionTrigger>
            <Button type="submit" disabled={loading}>
              Save
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </DialogRoot>
  )
}

export default LookerDashboardCreateDialog
