import type { IconButtonProps } from '@chakra-ui/react'
import { IconButton } from '@chakra-ui/react'
import type { FC } from 'react'

import { Tooltip } from '@app/components/ui/tooltip'
import KeyboardShortcutsTooltip from '@app/shared/keyboardShortcutsTooltip'
import { useStore } from '@app/store'
import type { DomainNode } from '@app/types'
import ICON_MAP from '@app/utils/iconMap'

const buttonStyles = {
  borderRadius: 'none'
}

interface Props extends IconButtonProps {
  node: DomainNode
}

const DeleteButton: FC<Props> = ({ node, ...rest }) => {
  const { strategyId } = node
  const onNodesDelete = useStore.use.onNodesDelete()

  const deleteCard = () => {
    onNodesDelete(strategyId, [node])
  }

  return (
    <Tooltip content={<KeyboardShortcutsTooltip title="Delete card" shortcut="del" />}>
      <IconButton {...buttonStyles} aria-label="Delete card" onClick={deleteCard} size="sm" variant="outline" {...rest}>
        <ICON_MAP.Delete />
      </IconButton>
    </Tooltip>
  )
}

export default DeleteButton
