import { TimeScale, LinearScale, PointElement, LineElement, Chart as ChartJS } from 'chart.js'
import type { FC } from 'react'
import { Line, type ChartProps as ChartJSProps } from 'react-chartjs-2'

import 'chartjs-adapter-date-fns'
import SuspenseLoader from '@app/shared/loaders/suspenseLoader'
import withSuspenseWrapper from '@app/shared/withSuspenseWrapper'
import type { IntervalEnum } from '@graphql/queries'
import { useCorrelationPairMetricsQuery } from '@graphql/queries'
// import { TypedChartComponent } from 'react-chartjs-2/dist/types'

ChartJS.register(TimeScale, LinearScale, PointElement, LineElement)

const X_CONFIG = {
  type: 'time',
  display: true,
  offset: true,
  grid: {
    drawBorder: true,
    drawTicks: true,
    drawOnChartArea: false,
    display: true
  },
  time: {
    unit: 'week',
    tooltipFormat: 'MMMM dd, yyyy'
  },
  ticks: {
    maxRotation: 0,
    major: {
      enabled: false
    }
  }
}

const Y_CONFIG = {
  type: 'linear',
  display: true,
  grid: {
    display: false
  }
}

type GraphPoint = { x: Date; y: number }

type ChartProps = ChartJSProps<'line', GraphPoint[]>

interface Props {
  endDate?: string
  interval: IntervalEnum
  range: string
  sourceId: string
  sourceLagDays: number
  startDate?: string
  targetId: string
  targetLagDays: number
}

const Graph: FC<Props> = ({
  endDate = null,
  interval,
  range,
  sourceId,
  sourceLagDays,
  startDate = null,
  targetId,
  targetLagDays
}) => {
  const [{ data: queryData }] = useCorrelationPairMetricsQuery({
    variables: {
      endDate,
      interval,
      range,
      sourceId,
      sourceLagDays,
      startDate,
      targetId,
      targetLagDays
    }
  })

  if (!queryData?.organization?.correlate) {
    return null
  }

  const { sourceMetric, targetMetric } = queryData.organization.correlate

  const isLagged = sourceLagDays !== 0 || targetLagDays !== 0

  const mapper = ({ date, value: y }): GraphPoint => ({
    x: new Date(`${date}T12:00:00`),
    y
  })

  const data: ChartProps['data'] = {
    labels: [],
    datasets: [
      {
        type: 'line',
        cubicInterpolationMode: 'monotone',
        tension: 0.8,
        xAxisID: 'xSource',
        yAxisID: 'ySource',
        label: sourceMetric.name,
        data: sourceMetric.metricDataPoints.map(mapper),
        borderColor: '#00bcd4',
        backgroundColor: '#00bcd4'
      },
      {
        type: 'line',
        cubicInterpolationMode: 'monotone',
        order: 1,
        tension: 0.8,
        xAxisID: isLagged ? 'xTarget' : 'xSource',
        yAxisID: 'yTarget',
        label: targetMetric.name,
        data: targetMetric.metricDataPoints.map(mapper),
        borderColor: '#ff9800',
        backgroundColor: '#ff9800'
      }
    ]
  }

  const scales: {
    [key: string]: object
  } = {
    xSource: {
      ...X_CONFIG,
      position: 'bottom'
    },
    ySource: {
      ...Y_CONFIG,
      position: 'left',
      title: {
        display: true,
        text: sourceMetric.name
      }
    },
    yTarget: {
      ...Y_CONFIG,
      position: 'right',
      title: {
        display: true,
        text: targetMetric.name
      }
    }
  }

  if (isLagged) {
    scales.xTarget = {
      ...X_CONFIG,
      position: 'top'
    }
  }

  const config: ChartProps = {
    type: 'line',
    data,
    options: {
      responsive: true,
      interaction: {
        mode: 'nearest',
        intersect: false
      },
      plugins: {
        legend: {
          position: 'bottom'
        }
      },
      scales
    }
  }

  return <Line {...config} data-testid="graph" />
}

export default withSuspenseWrapper(Graph, <SuspenseLoader />)
