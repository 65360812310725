import type { StackProps } from '@chakra-ui/react'
import { Stack } from '@chakra-ui/react'
import { useViewport } from '@xyflow/react'
import type { FC } from 'react'
import { memo } from 'react'

import { Card, CardRollupType, CardSource } from '../components'
import CardLabels from '../components/cardLabels'

import CardMetricGoals from './cardMetricGoals'
import CardStats from './cardStats'

import useGetObjectProperty from '@app/hooks/useGetObjectProperty'
import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import useIsAnonymousUser from '@app/hooks/useIsAnonymousUser'
import { aiInsightsShadow } from '@app/lib/globals'
import MetricTypePopover from '@app/next/metricTypePopover'
import CollapsedNodes from '@app/pages/maps/components/nodes/components/collapsedNodes'
import { scaleSizeToMapZoom } from '@app/pages/maps/components/nodes/helpers'
import cardMetadata from '@app/shared/cards/cardMetadata'
import CardDetailsLink from '@app/shared/cards/components/cardDetailsLink'
import CardSection from '@app/shared/cards/components/cardSection'
import CardSharedActions from '@app/shared/cards/components/cardSharedActions'
import CardTitle from '@app/shared/cards/components/cardTitle'
import CardTypeSection from '@app/shared/cards/components/cardTypeSection'
import MarkdownDisplay from '@app/shared/markdownDisplay'
import PulsingCircle from '@app/shared/pulsingCircle'
import type { DomainMetric, DomainNode } from '@app/types'
import ICON_MAP, { COLOR_MAP } from '@app/utils/iconMap'
import { GoalStatusEnum } from '@graphql/types'

interface Props extends StackProps {
  strategyId: string
  metric?: DomainMetric
  node?: DomainNode
}

const MetricCard: FC<Props> = ({ strategyId, metric, node, ...rest }) => {
  const isAnonymousUser = useIsAnonymousUser(strategyId)
  const { size, color } = cardMetadata(node)

  const hideMetricData = useGetObjectProperty(strategyId, 'strategy', 'hideMetricData')
  const showMetricData = !hideMetricData
  const goals = useGetObjectsByProperties('goal', { metricId: metric.id })

  const { zoom } = useViewport()

  const {
    aiGenerated,
    description,
    externalUrl,
    externalUrlTitle,
    id,
    labels,
    recentActivity,
    showDescription,
    showTechnicalDescription,
    sourceName,
    strategyNodeData,
    strategyStats: metricStats,
    technicalDescription
  } = metric

  const strategyStats = strategyNodeData?.strategyStats

  // combine the stats display better so these checks naturally cluster together
  const hasMetricStats = metricStats?.some((stat) => stat.total || stat.change) || false
  const hasStrategyStats = strategyStats?.some((stat) => stat.total || stat.change) || false
  const showStats = (hasMetricStats || hasStrategyStats) && showMetricData
  const goalIds = goals ? goals.filter((goal) => goal.status === GoalStatusEnum.Current).map((goal) => goal.id) : []
  const route = isAnonymousUser ? null : `/strategy/${strategyId}/map/metric/${metric.id}`

  const pulseSize = scaleSizeToMapZoom(24, zoom, 44)

  const boxProps: StackProps = {}
  if (aiGenerated) {
    boxProps.boxShadow = aiInsightsShadow
  }

  return (
    <Card color={color} {...boxProps} {...rest} data-cy="metric-card">
      <CardTypeSection title="Metric /" icon={ICON_MAP.Metric} color={COLOR_MAP.Metric} route={route} size={size}>
        <MetricTypePopover metric={metric} fontWeight="semibold" size={size} />
      </CardTypeSection>
      <CardSection size={size}>
        <PulsingCircle isActive={!!recentActivity} size={pulseSize} link={`metric/${id}/events`} />
        <Stack>
          <CardTitle domainObject={metric} name="name" size={size} />
          {showDescription && description && <MarkdownDisplay text={description} />}
          {showTechnicalDescription && technicalDescription && <MarkdownDisplay text={technicalDescription} />}
          {externalUrl && <CardDetailsLink url={externalUrl} text={externalUrlTitle} size={size} />}
          {showStats && <CardStats strategyId={strategyId} metric={metric} size={size} />}
        </Stack>
      </CardSection>
      {goalIds.length > 0 && showMetricData && (
        <CardSection size={size}>
          <CardMetricGoals metric={metric} goalIds={goalIds} size={size} />
        </CardSection>
      )}
      {labels?.length && <CardLabels fieldName="labels" domainObject={metric} size={size} />}
      <CardSharedActions domainObject={metric} size={size} route={route}>
        <CardSource sourceName={sourceName} size={size} color="fg.subtle" />
        {showStats && <CardRollupType metric={metric} size={size} />}
      </CardSharedActions>
      {node && <CollapsedNodes node={node} />}
    </Card>
  )
}

export default memo(MetricCard)
