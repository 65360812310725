import { Box, HStack, Spacer, Stack, Text } from '@chakra-ui/react'
import { formatDistanceToNow } from 'date-fns'
import type { FC } from 'react'

import CommentDeleteButton from './commentDeleteButton'

import { Avatar } from '@app/components/ui/avatar'
import useGetObject from '@app/hooks/useGetObject'
import type { DomainComment } from '@app/types'
import profileImage from '@app/utils/profileImage'

interface Props {
  comment: DomainComment
}

const Comment: FC<Props> = ({ comment }) => {
  const { creatorId, body } = comment
  const creator = useGetObject(creatorId, 'user')

  return (
    <HStack align="flex-start">
      <Box ms={2} pt="1">
        {creator && <Avatar name={creator?.name} size="2xs" src={profileImage(creator?.email)} />}
      </Box>
      <Stack flexGrow={1} gap="1">
        <HStack>
          <Text fontWeight="medium">{creator?.name}</Text>
          <Text color="fg.subtle" fontSize="xs">
            •
          </Text>
          <Text color="fg.subtle" fontSize="xs" title={new Date(comment.createdAt).toLocaleString()}>
            {formatDistanceToNow(new Date(comment.createdAt))} ago
          </Text>
          <Spacer />
          <CommentDeleteButton comment={comment} />
        </HStack>
        <Box color="fg.muted" lineHeight="tall" whiteSpace="pre-line">
          {body}
        </Box>
      </Stack>
    </HStack>
  )
}

export default Comment
