import type { Dispatch } from 'react'
import { createContext } from 'react'

const MapSegmentFiltersModalContext = createContext({
  segmentFilters: [],
  activeSegmentName: null,
  setActiveSegmentName: (() => {}) as Dispatch<unknown>,
  onFilter: (() => {}) as (addIds: string[], removeIds: string[]) => void,
  uncommitedChanges: { addIds: [] as string[], removeIds: [] as string[] },
  setUncommitedChanges: (() => {}) as Dispatch<unknown>
})

export default MapSegmentFiltersModalContext
