import { HStack } from '@chakra-ui/react'

import { ClipboardIconButton, ClipboardInput, ClipboardLabel, ClipboardRoot } from '@app/components/ui/clipboard'
import { InputGroup } from '@app/components/ui/input-group'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'

const APIToken = () => {
  const { user } = useStoreCurrentUser()
  const { organization } = user
  const { apiToken } = organization

  return (
    <ClipboardRoot value={apiToken}>
      <HStack>
        <ClipboardLabel minW="xs">API Token</ClipboardLabel>
        <InputGroup width="full" endElement={<ClipboardIconButton me="-2" />}>
          <ClipboardInput readOnly value={apiToken} bg="bg" />
        </InputGroup>
      </HStack>
    </ClipboardRoot>
  )
}

export default APIToken
