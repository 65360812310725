import { Box, Text } from '@chakra-ui/react'
import { type FC } from 'react'
import { useFetcher } from 'react-router-dom'

import ImportPropertyMetricsButton from './importPropertyMetricsButton'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import { CardHeader } from '@app/shared/layout'
import Table from '@app/shared/table'
import type { GoogleIntegration } from '@graphql/types'

type Props = {
  integration: Pick<GoogleIntegration, 'activitiesCount' | 'propertiesV4'>
}

const PropertyCell = ({ data: property }) => (
  <Text key={property.id}>
    {property.display_name} ({property.id})
  </Text>
)

const ActionCell = ({ integration, fetcher, data: property }) => {
  const { formatDateTimeInUserTimeZone } = useStoreCurrentUser()
  const propertyImports = integration?.propertyImports || {}
  const importedAt = propertyImports[property.id]
  let action

  if (importedAt) {
    action = <Text>Imported on {formatDateTimeInUserTimeZone(importedAt)}</Text>
  } else {
    action = <ImportPropertyMetricsButton fetcher={fetcher} integration={integration} property={property} />
  }

  return <Box textAlign="right">{action}</Box>
}

const columns = (integration, fetcher) => [
  {
    header: 'Property',
    cell: PropertyCell
  },
  {
    header: '',
    cell: ({ data }) => <ActionCell integration={integration} data={data} fetcher={fetcher} />
  }
]

const PropertyImports: FC<Props> = ({ integration }) => {
  const properties = integration?.propertiesV4 || []
  const fetcher = useFetcher()

  if (properties.length === 0) {
    return null
  }

  return (
    <Box p={4}>
      <CardHeader title="Google Analytics Properties" />
      <Table mt={4} columns={columns(integration, fetcher)} data={properties} />
    </Box>
  )
}

export default PropertyImports
