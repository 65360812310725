import { Box } from '@chakra-ui/react'

import Paywall from '@app/pages/settings/billing/components/paywall'
import { PageHeader, PageStack } from '@app/shared/layout'

const Plan = () => (
  <PageStack>
    <PageHeader title="Plan" />
    <Box p={4}>
      <Paywall />
    </Box>
  </PageStack>
)

export default Plan
