import type { IconButtonProps } from '@chakra-ui/react'
import { Button, Text, Link, Spinner, Stack } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'

import { toaster } from '@app/components/ui/toaster'
import ICON_MAP from '@app/utils/iconMap'
import { useMapCloneToOrganizationMutation } from '@graphql/queries'

type Props = Omit<IconButtonProps, 'aria-label'> & {
  strategyId: string
}

const CopyMapToDoubleLoopButton: FC<Props> = ({ strategyId, ...rest }) => {
  const [, copyMap] = useMapCloneToOrganizationMutation()
  const [loading, setLoading] = useState(false)

  const onClick = async () => {
    setLoading(true)

    try {
      const resp = await copyMap({
        input: {
          strategyId,
          organizationId: 'doubleloop'
        }
      })

      const { strategyId: newStrategyId, strategyName: newStrategyName } = resp.data.mapCloneToOrganization

      toaster.create({
        title: 'Map copied to DoubleLoop organization',
        type: 'success',
        duration: 5000,
        description: (
          <Stack gap={4}>
            <Text>The map {newStrategyName} has been created in the DoubleLoop organization.</Text>
            <Button asChild>
              <Link href={`/strategy/${newStrategyId}`} rel="onoopener noreferrer" target="_blank">
                View map
              </Link>
            </Button>
          </Stack>
        )
      })
    } catch (error) {
      toaster.create({
        title: 'Error copying map',
        type: 'error',
        description: error.message
      })
    } finally {
      setLoading(false)
    }
  }

  // If we use {...props} here, we will pass the strategyId to the IconButton component
  // const iconButtonProps = { ...props }
  // delete iconButtonProps.strategyId

  return (
    <Button
      aria-label="Copy map to DoubleLoop Organization"
      {...rest}
      colorPalette="gray"
      onClick={onClick}
      size="2xs"
      variant="ghost"
    >
      {loading ? <Spinner size="xs" /> : <ICON_MAP.Copy />}
      Copy to DL
    </Button>
  )
}

export default CopyMapToDoubleLoopButton
