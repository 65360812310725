import { HStack, Link as ChakraLink, Stack } from '@chakra-ui/react'
import capitalize from 'lodash/capitalize'
import type { FC } from 'react'
import { Link } from 'react-router-dom'

import { Tag } from '@app/components/ui/tag'
import ContainmentTag from '@app/shared/entity/containmentTag'
import type { DomainEntity } from '@app/types'

export interface Props {
  entity: Pick<DomainEntity, 'id' | 'name' | 'entityType' | 'containerEntityContainments'>
  entityPath: string
}

const MinorEntity: FC<Props> = ({ entity, entityPath, ...rest }) => (
  <Stack gap={1} {...rest}>
    <ChakraLink asChild>
      <Link to={entityPath}>
        {entity.name}
        {entity.entityType && <Tag ml={4}>{capitalize(entity.entityType)}</Tag>}
      </Link>
    </ChakraLink>
    <HStack wrap="wrap">
      {entity.containerEntityContainments?.map((containment) => (
        <ContainmentTag key={containment.id} mr={4} containment={containment} />
      ))}
    </HStack>
  </Stack>
)

export default MinorEntity
