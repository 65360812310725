import { Badge, Center, Flex, For, HStack, Separator, Spinner, Stack } from '@chakra-ui/react'
import type { FC } from 'react'
import { useEffect, useState } from 'react'

import { EmptyState } from '@app/components/ui/empty-state'
import { Tooltip } from '@app/components/ui/tooltip'
import useGetObjects from '@app/hooks/useGetObjects'
import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import LookerDashboardCard from '@app/pages/settings/integrations/components/lookerDashboard/lookerDashboardCard'
import LookerDashboardCreateDialog from '@app/pages/settings/integrations/components/metricIntegrations/lookerDashboardCreateDialog'
import Can from '@app/shared/authorization/can'
import { CardHeader } from '@app/shared/layout'
import { useStore } from '@app/store'
import { loaderQuery } from '@graphql/client'
import { LookerDashboards as LookerDashboardsQuery } from '@graphql/documents/looker_dashboard.graphql'

const LookerDashboards: FC = () => {
  const [loading, setLoading] = useState(false)
  const lookerApiCredentials = useGetObjectsByProperties('credential', { sourceName: 'MetricSources::LookerApi' })
  const lookerDashboards = useGetObjects('lookerDashboard')
  const bulkAdd = useStore.use.bulkAdd()

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      const { data } = await loaderQuery(LookerDashboardsQuery)

      bulkAdd(data?.lookerDashboards)
      setLoading(false)
    }

    fetchData().then()
  }, [bulkAdd])

  if (!lookerApiCredentials?.length) {
    return null
  }

  return (
    <Can I="view" a="beta">
      <>
        <Stack>
          <CardHeader
            title="Looker dashboards"
            badge={
              <Tooltip
                showArrow
                content="This integration is currently in Beta. If there is data not importing you are expecting please let us know."
              >
                <Badge colorPalette="green">Beta</Badge>
              </Tooltip>
            }
          >
            <LookerDashboardCreateDialog credentials={lookerApiCredentials} />
          </CardHeader>

          <HStack wrap="wrap">
            <For
              each={lookerDashboards}
              fallback={
                <Center w="full">
                  {loading ? (
                    <Spinner size="xl" />
                  ) : (
                    <EmptyState title="No Looker dashboards" description="Add a dashboard to get started" />
                  )}
                </Center>
              }
            >
              {(lookerDashboard) => (
                <Flex align="flex-start" key={lookerDashboard.id}>
                  <LookerDashboardCard dashboard={lookerDashboard} />
                </Flex>
              )}
            </For>
          </HStack>
        </Stack>
        <Separator />
      </>
    </Can>
  )
}

export default LookerDashboards
