import { Kbd, Text } from '@chakra-ui/react'
import type { FC, RefObject } from 'react'
import { useCallback } from 'react'
import { useEffect, useState } from 'react'

interface Props {
  inputRef: RefObject<HTMLTextAreaElement>
}

const ChatTextareaHelpText: FC<Props> = ({ inputRef, ...rest }) => {
  const [opacity, setOpacity] = useState(0)

  const eventListener = useCallback(() => {
    if (inputRef?.current?.value.length) {
      setOpacity(100)
    } else {
      setOpacity(0)
    }
  }, [inputRef])

  useEffect(() => {
    if (!inputRef?.current) {
      return () => {}
    }

    const currentRef = inputRef.current

    currentRef?.addEventListener('keyup', eventListener)
    currentRef?.addEventListener('blur', eventListener)

    return () => {
      currentRef?.removeEventListener('keyup', eventListener)
      currentRef?.removeEventListener('blur', eventListener)
    }
  }, [inputRef, eventListener])

  return (
    <Text color="fg.subtle" fontSize="xs" {...rest} opacity={opacity} transition="opacity 200ms">
      Use <Kbd size="sm">shift+return</Kbd> for a new line
    </Text>
  )
}

export default ChatTextareaHelpText
