import type { FC } from 'react'

import { Field } from '@app/components/ui/field'
import { Switch } from '@app/components/ui/switch'
import type { DomainMetric } from '@app/types'

interface Props {
  metric?: Pick<DomainMetric, 'id' | 'rollUp'>
  cumulative?: boolean
  setCumulative?: (cumulative: boolean) => void
}

const CumulativeToggle: FC<Props> = ({ metric = null, cumulative = false, setCumulative = () => {}, ...rest }) => {
  if (metric && metric.rollUp === 'sum') {
    return (
      <Field css={{ '--field-label-width': '150px' }} label="Show cumulative total" orientation="horizontal" {...rest}>
        <Switch
          id="cumulative-toggle"
          checked={cumulative}
          onCheckedChange={() => {
            setCumulative(!cumulative)
          }}
        />
      </Field>
    )
  }

  return null
}

export default CumulativeToggle
