import { HStack } from '@chakra-ui/react'
import isEmpty from 'lodash/isEmpty'
import type { FC } from 'react'

import useGetStrategyDateFilters from '@app/hooks/useGetStrategyDateFilters'
import { CardStat } from '@app/shared/cards/components'
import type { CardSize, DomainMetric } from '@app/types'
import { statLabel } from '@app/utils/metricHelpers'

interface Props {
  strategyId: string
  metric: Pick<
    DomainMetric,
    | 'id'
    | 'displayFormat'
    | 'rollUp'
    | 'periodToDate'
    | 'strategyStats'
    | 'strategyNodeData'
    | 'positiveDirection'
    | 'compactDisplay'
    | 'maximumDisplayPrecision'
    | 'minimumDisplayPrecision'
    | 'redecimalPercentages'
  >
  size?: CardSize
}

const CardStats: FC<Props> = ({ strategyId, metric, size }) => {
  const {
    displayFormat,
    strategyStats: metricStats,
    strategyNodeData: { strategyStats = [] }
  } = metric

  const hasMetricStats = metricStats?.some((stat) => stat.total || stat.change)
  const hasStrategyStats = strategyStats.length > 0
  const strategyDateFilters = useGetStrategyDateFilters(strategyId)

  const baseStats = hasStrategyStats ? strategyStats : metricStats
  const includeAllStats = isEmpty(strategyDateFilters) || strategyDateFilters.range === 'trends'
  const stats = includeAllStats ? baseStats : baseStats.slice(0, 1)

  const showStats = hasMetricStats || hasStrategyStats

  if (!showStats || stats.every((stat) => !stat.total && !stat.change)) {
    return null
  }

  const hideChange = stats.length < 3

  return (
    <HStack justify="space-between">
      {stats.map((stat) => (
        <CardStat
          key={`${metric?.id}-${stat.period}`}
          period={statLabel(stat, metric.rollUp, metric.periodToDate, strategyDateFilters)}
          total={stat.total}
          change={stat.change}
          displayFormat={displayFormat}
          metric={metric}
          size={size}
          hideChange={hideChange}
        />
      ))}
    </HStack>
  )
}

export default CardStats
