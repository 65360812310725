import { Stack, Separator, Text } from '@chakra-ui/react'
import type { FC } from 'react'

import useGetObjectsByIds from '@app/hooks/useGetObjectsByIds'
import GoalProgress from '@app/pages/goals/components/goalProgress'
import type { CardSize, DomainMetric } from '@app/types'
import type { Goal } from '@graphql/queries'
import { GoalStatusEnum } from '@graphql/types'

interface Props {
  metric: DomainMetric
  goalIds?: string[]
  size?: CardSize
  showTitle?: boolean
}

const CardMetricGoals: FC<Props> = ({ metric, goalIds = [], size = 'normal', showTitle = true }) => {
  const goals = useGetObjectsByIds('goal', goalIds) as unknown as Goal[]
  const currentGoals = goals ? goals.filter((goal) => goal.status === GoalStatusEnum.Current) : []

  if (!currentGoals.length) {
    return null
  }

  let fontSize: string

  switch (size) {
    case 'normal':
      fontSize = 'md'
      break
    case 'large':
      fontSize = 'lg'
      break
    case 'small':
      fontSize = 'sm'
      break
    default:
      fontSize = 'md'
      break
  }

  return (
    <>
      {showTitle && (
        <Text mb={2} fontSize={fontSize} fontWeight="semibold">
          Key results
        </Text>
      )}
      <Stack separator={<Separator />}>
        {currentGoals.map((goal) => (
          <GoalProgress goal={goal} metric={metric} fontSize={fontSize} key={goal.id} />
        ))}
      </Stack>
    </>
  )
}

export default CardMetricGoals
