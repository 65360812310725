import debounce from 'lodash/debounce'
import { useState, createContext, useCallback, useContext, useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

type ListFilterContextState = {
  listFilter: string
  setListFilter: (listFilter: string) => void
}

const ListFilterContext = createContext<ListFilterContextState>(undefined)

const ListFilterContextProvider = ({ children, searchField }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [listFilter, setListFilter] = useState(() => searchParams.get(searchField) || '')

  const value = useMemo(
    () => ({
      listFilter,
      setListFilter
    }),
    [listFilter]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetSearchParams = useCallback(
    debounce((newValue) => {
      setSearchParams((currentParams) => {
        if (newValue) {
          currentParams.set(searchField, newValue)
        } else {
          currentParams.delete(searchField)
        }

        currentParams.delete('page')

        return currentParams
      })
    }, 200),
    [searchField]
  )

  useEffect(() => debouncedSetSearchParams(listFilter), [debouncedSetSearchParams, listFilter])

  return <ListFilterContext.Provider value={value}>{children}</ListFilterContext.Provider>
}

const useListFilterContext = () => {
  const context = useContext(ListFilterContext)

  if (!context) {
    throw new Error('useListFilterContext must be used within a ListFilterContextProvider')
  }

  return context
}

export { ListFilterContextProvider, useListFilterContext }
