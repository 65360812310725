import { Link, SimpleGrid, Stack, Separator } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'
import { SiGoogleanalytics, SiJirasoftware, SiLooker } from 'react-icons/si'

import APIToken from './components/apiToken'
import IntegrationCard from './components/integrationCard'
import MetricIntegrationCreateModal from './components/metricIntegrations/metricIntegrationCreateModal'
import MetricIntegrationsSection from './components/metricIntegrations/metricIntegrationsSection'

import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import Slack from '@app/images/slack'
import LookerDashboards from '@app/pages/settings/integrations/components/lookerDashboards'
import Can from '@app/shared/authorization/can'
import { CardHeader, PageHeader, PageStack } from '@app/shared/layout'
import ICON_MAP from '@app/utils/iconMap'
import { IntegrationSourceNamesEnum } from '@graphql/types'

interface Props {
  title: string
  subtitle?: string
  children: ReactNode
}

const IntegrationSection: FC<Props> = ({ title, subtitle = '', children }) => (
  <Stack>
    <CardHeader title={title} subtitle={subtitle} />
    <SimpleGrid gap={12} columns={[1, 1, 2, 2]}>
      {children}
    </SimpleGrid>
  </Stack>
)

const List = () => {
  const integrations = useGetObjectsByProperties('integration', {})

  const apiDocsLink = (
    <span>
      Create your own integrations using our{' '}
      <Link color="link" href="/apidocs/1.0.html" rel="onoopener noreferrer" target="_blank">
        API
      </Link>
    </span>
  )

  const integrationNames = integrations?.map((integration) => integration.sourceName) || []

  return (
    <PageStack>
      <PageHeader
        title="Explore integrations"
        subtitle="Connect all the work and measurements you need to understand your business"
      />
      <Can I="create" a="credential">
        <Stack>
          <CardHeader
            title="Metrics"
            subtitle="Connect Amazon Redshift, Amplitude, Google BigQuery, Looker API, Mixpanel, MySQL, Postgres, SQL Server, Snowflake, and Stripe."
          >
            <MetricIntegrationCreateModal />
          </CardHeader>
          <MetricIntegrationsSection />
        </Stack>
      </Can>
      <IntegrationSection title="Other metrics integrations">
        <IntegrationCard
          name="Google Analytics"
          description="Import commonly used metrics from Google Analytics (including V4)."
          connected={integrationNames.includes(IntegrationSourceNamesEnum.Google)}
          icon={SiGoogleanalytics}
          path="google"
        />
        <IntegrationCard
          name="Looker Action Hub (deprecated)"
          description="Automatically pull your metrics from Looker into DoubleLoop."
          icon={SiLooker}
          path="looker"
        />
      </IntegrationSection>
      <Separator />
      <LookerDashboards />
      <IntegrationSection title="Work">
        <IntegrationCard
          name="Jira"
          description="Import issues from Jira to save a record of your completed features, bug fixes, epics, or versions."
          icon={SiJirasoftware}
          connected={integrationNames.includes(IntegrationSourceNamesEnum.Jira)}
          path="jira"
        />
        <IntegrationCard
          name="Linear"
          description="Import stories from Linear to save a record of your completed features, bug fixes, or epics."
          icon={ICON_MAP.Linear}
          connected={integrationNames.includes(IntegrationSourceNamesEnum.Linear)}
          path="linear"
        />
        <IntegrationCard
          name="Shortcut"
          description="Import stories from Shortcut to save a record of your completed features, bug fixes, or epics."
          icon={ICON_MAP.Shortcut}
          connected={integrationNames.includes(IntegrationSourceNamesEnum.Shortcut)}
          path="shortcut"
        />
        <IntegrationCard
          name="Asana"
          description="Import issues from Asana to save a record of your completed projects and tasks."
          icon={ICON_MAP.Asana}
          connected={integrationNames.includes(IntegrationSourceNamesEnum.Asana)}
          path="asana"
        />
        <IntegrationCard
          name="Productboard"
          description="Import products, components, and features from Productboard."
          icon={ICON_MAP.Productboard}
          connected={integrationNames.includes(IntegrationSourceNamesEnum.Productboard)}
          path="productboard"
        />
      </IntegrationSection>
      <Separator />
      <IntegrationSection title="Reporting">
        <IntegrationCard
          name="Slack"
          description="Efficiently communicate product changes to stakeholders. Setup real-time deployment notifications or curated launch announcements."
          icon={Slack}
          connected={integrationNames.includes(IntegrationSourceNamesEnum.Slack)}
          path="slack"
        />
      </IntegrationSection>
      <Separator />
      <Stack mb={16}>
        <CardHeader title="API Token" subtitle={apiDocsLink} />
        <APIToken />
      </Stack>
    </PageStack>
  )
}

export default List
