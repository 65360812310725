import type { FC, ReactNode } from 'react'
import { useEffect } from 'react'
import type { UseQueryArgs, UseQueryResponse } from 'urql'

import { usePage } from '@app/shared/pagination'
import withSuspenseWrapper from '@app/shared/withSuspenseWrapper'
import { useStore } from '@app/store'
import type { MetricEntitiesQueryVariables, StrategyMetricEntitiesQueryVariables } from '@graphql/queries'
import { useMetricEntitiesQuery, useStrategyMetricEntitiesQuery } from '@graphql/queries'
import type { CollectionMetadata, Entity, EntityCollection } from '@graphql/types'

type Variables = StrategyMetricEntitiesQueryVariables | MetricEntitiesQueryVariables

type Props = Variables & {
  id: string
  strategyId: string | null
  children: (collection: Entity[], metadata: CollectionMetadata | Record<string, never>) => ReactNode
}

const MetricEntitiesFetcher: FC<Props> = ({ id, children, strategyId, ...rest }) => {
  const page = usePage()
  const updateObject = useStore.use.updateObject()

  let query: (options: Omit<UseQueryArgs, 'query'>) => UseQueryResponse
  let variables: Variables

  if (strategyId) {
    query = useStrategyMetricEntitiesQuery
    variables = { id: strategyId, metricId: id, page, ...rest }
  } else {
    query = useMetricEntitiesQuery
    variables = { id, page, ...rest }
  }

  const [{ data }] = query({ variables })

  const { collection, metadata } = (data?.strategy?.metricEntities || data?.metricEntities || {}) as EntityCollection

  useEffect(() => {
    // @ts-expect-error containee_ids is not a valid field for updateObject, so skip the mutation
    updateObject({ metric: { id, containeeIds: collection?.map((containment) => containment.id) } }, true)
  }, [collection, id, updateObject])

  return children(collection, metadata)
}

export default withSuspenseWrapper(MetricEntitiesFetcher)
