import { getIncomers } from '@xyflow/react'
import type { Dictionary } from 'lodash' // eslint-disable-line lodash/import-scope
import compact from 'lodash/compact'
import filter from 'lodash/filter'
import groupBy from 'lodash/groupBy'
import isEqual from 'lodash/isEqual'
import sortBy from 'lodash/sortBy'
import { createSelector, createSelectorCreator, lruMemoize } from 'reselect'

import { CARD_FILTER_SIGIL } from '@app/lib/globals'
import { displayNameAndType } from '@app/pages/maps/components/nodes/helpers'
import type {
  BoundedState,
  PageMetadata,
  StoreDomainObject,
  StoreDomainObjectCollection,
  StoreDomainObjectKeys,
  StoreDomainObjects
} from '@app/store/types'
import type { DomainNode, DomainObject } from '@app/types'

const createSizedSelector = (maxSize: number) =>
  createSelectorCreator(lruMemoize, {
    maxSize
  })

const createDeepEqualSizedSelector = (maxSize: number) =>
  createSelectorCreator(lruMemoize, {
    equalityCheck: isEqual,
    resultEqualityCheck: isEqual,
    maxSize
  })

export const getObjectByIdSelector = <T extends keyof BoundedState>(
  state: Pick<BoundedState, T>,
  type: string,
  id: string
) => state[type]?.[id]

export const getObjectById = <T extends keyof BoundedState>(
  state: Pick<BoundedState, T>,
  type: T,
  id: string
): StoreDomainObject<T> | undefined => getObjectByIdSelector<T>(state, type, id) as StoreDomainObject<T>

export const getNodeWithDataSelector = createSizedSelector(50)(
  [(_state, node, _data) => node, (_state, _node, data) => data],
  (node, data) => ({
    ...node,
    data
  })
)

const getObjectsByIdsSelector = createDeepEqualSizedSelector(50)(
  [(state: BoundedState, type: string, _ids) => state[type], (_state, _type, ids: (string | number)[]) => ids],
  (objects: StoreDomainObjectCollection<DomainObject>, ids: (string | number)[]) =>
    ids.map((id) => objects[id]).filter((o) => o)
)
export const getObjectsByIds = <T extends keyof BoundedState>(state: BoundedState, type: T, ids: (string | number)[]) =>
  getObjectsByIdsSelector(state, type, ids) as StoreDomainObject<T>[]

type PropertiesSelectorProperties<T extends StoreDomainObjectKeys> =
  | Partial<StoreDomainObject<T>>
  | ((o: StoreDomainObject<T>, index: number, collection: StoreDomainObjectCollection<T>) => boolean)

export const getObjectsByPropertiesSelector = createDeepEqualSizedSelector(50)(
  [(state: BoundedState, type: string, _properties) => state[type], (_state, _type, properties) => properties],
  (objects: StoreDomainObjects, properties: PropertiesSelectorProperties<StoreDomainObjectKeys>) =>
    filter(objects, properties)
)

export const getObjectsByProperties = <T extends StoreDomainObjectKeys>(
  state: Pick<BoundedState, T>,
  type: T,
  properties: PropertiesSelectorProperties<T>
) => getObjectsByPropertiesSelector(state, type, properties) as StoreDomainObject<T>[]

type GetObjectPageSelector<T extends keyof BoundedState['page'] = keyof BoundedState['page']> = (
  state: BoundedState,
  type: T
) => { collection: StoreDomainObject<T>[]; metadata: PageMetadata }

const getObjectPageSelector: GetObjectPageSelector = createSizedSelector(10)(
  [
    (state: BoundedState, type: keyof BoundedState['page']) => state[type],
    (state: BoundedState, type: keyof BoundedState['page']) => state.page[type]?.order,
    (state: BoundedState, type: keyof BoundedState['page']) => state.page[type]?.metadata
  ],
  (objects, order, metadata) => {
    const collection = (order || []).map((id) => objects[id]).filter((o) => o)
    return { collection, metadata }
  }
)

// This wrapper is because we can't access T in the createSizedSelector call above, so type inference
// ends up assuming a union of potential types, instead of the exact type of `store[type]`.
export const getObjectPage = <T extends keyof BoundedState['page']>(
  state: BoundedState,
  type: T
): ReturnType<GetObjectPageSelector<T>> => getObjectPageSelector(state, type) as ReturnType<GetObjectPageSelector<T>>

type GetNodesSelectorState = Pick<BoundedState, 'strategy' | 'node' | 'filteredNodeIds'>
type GetNodesSelector = (
  state: GetNodesSelectorState,
  strategyId: string,
  filters?: URLSearchParams
) => StoreDomainObject<'node'>[]

const anyParamStartsWith = (prefix: string, params: URLSearchParams = null): boolean => {
  if (!params) {
    return false
  }

  return Array.from(params.keys()).some((key) => key.startsWith(prefix))
}

export const getNodesSelector: GetNodesSelector = createDeepEqualSizedSelector(5)(
  [
    (_state, strategyId: string) => strategyId,
    (state: GetNodesSelectorState, _strategyId) => state.node,
    (state: GetNodesSelectorState, _strategyId) => state.filteredNodeIds,
    (_state, _strategyId, params: URLSearchParams) => params
  ],
  (strategyId, nodes, filteredNodeIds, params) => {
    // don't try to filter nodes if no filters are set by the user
    const nodeSet = anyParamStartsWith(CARD_FILTER_SIGIL, params) ? new Set(filteredNodeIds) : null

    const strategyNodes = Object.values(nodes).filter((n) => {
      const nodeSetContainsId = nodeSet ? nodeSet.has(n.id) : true

      return n.strategyId === strategyId && nodeSetContainsId
    })

    const sortedNodes = sortBy(strategyNodes, (obj) => (obj.type === 'section' ? 0 : 1))

    return sortedNodes.map((node) => {
      // All this munging needs to be somewhere more resilient
      if (node.type === 'note' || node.type === 'mapImage') {
        return node
      }

      if (node.type === 'section') {
        const draggable = !!node.selected
        return { ...node, draggable }
      }

      // This hack prevents the card height/width from being wrong
      const nodeWithoutWidthAndHeight = { ...node }
      delete nodeWithoutWidthAndHeight.width
      delete nodeWithoutWidthAndHeight.height

      return nodeWithoutWidthAndHeight
    })
  }
)

type GetSelectedNodesSelector = (state: Pick<BoundedState, 'node'>, strategyId: string) => StoreDomainObject<'node'>[]
export const getSelectedNodesSelector: GetSelectedNodesSelector = createSelector(
  [(state) => state.node, (_state, strategyId) => strategyId],
  (nodes: BoundedState['node'], strategyId: string) =>
    Object.values(nodes).filter((n) => n.strategyId === strategyId && n.selected === true)
)

type GetEdgesSelector = (state: Pick<BoundedState, 'edge'>, strategyId: string) => StoreDomainObject<'edge'>[]
export const getEdgesSelector: GetEdgesSelector = (state, strategyId) =>
  Object.values(state.edge).filter((n) => n.strategyId === strategyId)

type GetSelectedDomainObjectsSelector = (state: BoundedState, strategyId: string) => DomainObject[]
export const getSelectedDomainObjectsSelector: GetSelectedDomainObjectsSelector = createSelector(
  [(state: BoundedState) => state, getSelectedNodesSelector],
  (state, selectedNodes) =>
    selectedNodes.map((node) => {
      const { type } = node
      return Object.values(state[type as keyof BoundedState]).find((o) => o.rfId === node.id)
    })
)

export type GetNodesObjectsSelector = (state: BoundedState, nodes: Pick<DomainNode, 'id'>[]) => DomainObject[]
export const getNodesObjectsSelector: GetNodesObjectsSelector = createSelector(
  [(state: BoundedState) => state, (_state, nodes) => nodes],
  (state, nodes) =>
    nodes.map((node) => {
      const { type } = node
      return Object.values(state[type as keyof BoundedState]).find((o) => o.rfId === node.id)
    })
)

type GroupObject = { rfId?: string } & DomainObject

export type GetIncomingCollapsedNodesSelector = (
  state: BoundedState,
  node: DomainNode
) => {
  nodes: DomainNode[]
  groups: Dictionary<GroupObject[]>
  confidenceScore?: number | string
}

const confidenceScore = (nodeObjects: DomainObject[]) => {
  if (nodeObjects.length === 0) {
    return null
  }

  // @ts-expect-error -- this is a hack to get the confidence rating while we fix our types
  let scores = nodeObjects.map((nodeObject) => nodeObject?.confidenceRating || null)

  scores = compact(scores)

  if (scores.length === 0) {
    return null
  }

  const onTrack = scores.filter((score) => score === 'on_track').length
  const offTrack = scores.filter((score) => score === 'off_track').length

  const score = Math.round((onTrack / scores.length) * 100 - (offTrack / scores.length) * 100)

  return score
}

export const getIncomingCollapsedNodesSelector: GetIncomingCollapsedNodesSelector = (state, node) => {
  const incomers = getIncomers(node, Object.values(state.node), Object.values(state.edge)) as DomainNode[]
  const collapsedIncomers = incomers.filter((n) => !!n?.metadata?.collapsed)

  // we filter out undefined values which happens when maps are collapsed that do not have public permissions.
  const nodeObjects = collapsedIncomers
    .map((incomer) => Object.values(state[incomer.type as keyof BoundedState]).find((i) => i.rfId === incomer.id))
    .filter(Boolean)

  const groups = groupBy<GroupObject>(nodeObjects, (o) => displayNameAndType(o).type)
  const confidence = confidenceScore(nodeObjects)

  return { nodes: collapsedIncomers, groups, confidenceScore: confidence }
}

export const getSelectedAndCollapsedNodesSelector = (state, strategyId) => {
  const selectedNodes = getSelectedNodesSelector(state, strategyId)

  return selectedNodes.reduce(
    (acc, node) => {
      const { nodes } = getIncomingCollapsedNodesSelector(state, node)

      return acc.concat(nodes)
    },
    [...selectedNodes]
  )
}
