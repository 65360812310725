import type { FC } from 'react'
import { memo } from 'react'

import CommonButtons from './components/commonButtons'
import Toolbar from './components/toolbar'

import type { DomainNode } from '@app/types'

interface Props {
  strategyId: string
  node: DomainNode
}

const SubmapToolbar: FC<Props> = ({ strategyId, node }) => (
  <Toolbar strategyId={strategyId}>
    <CommonButtons node={node} />
  </Toolbar>
)

export default memo(SubmapToolbar)
