import { IconButton } from '@chakra-ui/react'
import type { FC } from 'react'
import { useRef, useState } from 'react'
import { FiTrash2 } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

import { Button } from '@app/components/ui/button'
import {
  DialogActionTrigger,
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle
} from '@app/components/ui/dialog'
import { useStore } from '@app/store'

type Props = {
  reportId: string
}

const ReportDeleteButton: FC<Props> = ({ reportId }) => {
  const [open, setOpen] = useState(false)
  const cancelRef = useRef()
  const navigate = useNavigate()
  const deleteObject = useStore.use.deleteObject()

  const deleteReport = () => {
    setOpen(false)
    navigate('../..')
    return deleteObject({ id: reportId, classType: 'report' }, { updatePageCounts: true })
  }

  return (
    <>
      <IconButton variant="ghost" size="2xs" colorPalette="red" onClick={() => setOpen(true)}>
        <FiTrash2 color="red.500" />
      </IconButton>

      <DialogRoot
        initialFocusEl={cancelRef?.current}
        onOpenChange={(e) => setOpen(e.open)}
        open={open}
        placement="center"
        role="alertdialog"
      >
        <DialogBackdrop />
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete report</DialogTitle>
          </DialogHeader>

          <DialogBody>Are you sure? You can&#39;t undo this action afterwards.</DialogBody>

          <DialogFooter>
            <DialogActionTrigger asChild>
              <Button ref={cancelRef} variant="outline">
                Cancel
              </Button>
            </DialogActionTrigger>
            <Button ml={3} bgColor="error" onClick={deleteReport}>
              Delete
            </Button>
          </DialogFooter>
          <DialogCloseTrigger />
        </DialogContent>
      </DialogRoot>
    </>
  )
}

export default ReportDeleteButton
