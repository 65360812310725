import { Stack, Spacer, IconButton } from '@chakra-ui/react'
import type { FC } from 'react'
import { FiArrowLeft } from 'react-icons/fi'
import { Link, useParams } from 'react-router-dom'

import ReportContent from './components/reportContent'

import useGetObject from '@app/hooks/useGetObject'
import DrawerHeader from '@app/pages/reports/components/drawerHeader'
import ReportDeleteButton from '@app/pages/reports/components/reportDeleteButton'
import SendReportButton from '@app/pages/reports/components/shared/sendReportButton'
import Can from '@app/shared/authorization/can'
import DrawerCloseButton from '@app/shared/drawer/drawerCloseButton'

const Show: FC = () => {
  const { reportId } = useParams()

  const report = useGetObject(reportId, 'report')

  if (!report) {
    return null
  }

  return (
    <Stack gap={4} w="3xl" maxW="100%" mx="auto">
      <DrawerHeader px={2} py={1}>
        <IconButton aria-label="go back" asChild variant="ghost" size="xs">
          <Link to="..">
            <FiArrowLeft />
          </Link>
        </IconButton>

        <Spacer />
        <Can I="update" an="strategy">
          <ReportDeleteButton reportId={report.id} />
          <SendReportButton report={report} />
        </Can>
        <DrawerCloseButton variant="ghost" />
      </DrawerHeader>
      <ReportContent report={report} />
    </Stack>
  )
}

export default Show
