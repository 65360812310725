'use client'

import { ChakraProvider, createSystem, defaultConfig } from '@chakra-ui/react'
import type { PropsWithChildren } from 'react'

import { ColorModeProvider } from './color-mode'
import system from '@app/theme'

export const Provider = (props: PropsWithChildren) => (
  <ChakraProvider value={system}>
    <ColorModeProvider>{props.children}</ColorModeProvider>
  </ChakraProvider>
)
