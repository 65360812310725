import type { FC } from 'react'

import type { ButtonProps } from '@app/components/ui/button'
import { MenuItem } from '@app/components/ui/menu'
import { toaster } from '@app/components/ui/toaster'
import { useRecurringReportConfigurationRunMutation } from '@graphql/queries'

type Props = ButtonProps & {
  recurringReportConfigurationId: string
}

const RecurringReportConfigurationRunButton: FC<Props> = ({ recurringReportConfigurationId }) => {
  const [, run] = useRecurringReportConfigurationRunMutation()

  const onClick = () => {
    toaster.create({
      type: 'info',
      title: 'Report',
      description: "We've started running your report and will update it when finished."
    })

    run({
      input: {
        recurringReportConfigurationId
      }
    }).then(() => {
      toaster.create({
        type: 'success',
        title: 'Report',
        description: 'Your report has been successfully updated.'
      })
    })
  }

  return (
    <MenuItem value="run_now" onClick={onClick}>
      Run now
    </MenuItem>
  )
}

export default RecurringReportConfigurationRunButton
