import type { FC } from 'react'
import { useState } from 'react'
import { useRevalidator } from 'react-router-dom'

import useGetObject from '@app/hooks/useGetObject'
import RangeSelect from '@app/pages/correlations/components/rangeSelect'
import { useStore } from '@app/store'

const NOOP = () => {}

interface Props {
  strategyId: string
  canEdit?: boolean
}

const MapDateFilters: FC<Props> = ({ strategyId, canEdit = false, ...rest }) => {
  const strategy = useGetObject(strategyId, 'strategy')
  const updateObject = useStore.use.updateObject()
  const revalidator = useRevalidator()

  const [startDate, setStartDate] = useState(strategy?.startDate)
  const [endDate, setEndDate] = useState(strategy?.endDate)
  const [range, setRange] = useState(strategy?.range || 'trends')

  if (!canEdit) {
    return null
  }

  const onChange = (values) => {
    const strategyParams = { strategy: { ...values, id: strategyId } }

    // Looks like the revalidator is needed only for the stats data to repopulate after the date filters have been changed.
    updateObject(strategyParams).then(() => revalidator.revalidate())
  }

  return (
    <RangeSelect
      labelText=""
      setStartDate={setStartDate}
      setRange={setRange}
      range={range}
      startDate={startDate}
      endDate={endDate}
      setEndDate={setEndDate}
      onChange={onChange}
      onCustomRangeCancel={NOOP}
      inline
      disabled={!canEdit}
      selectProps={{ size: 'xs' }}
      border={0}
      {...rest}
    />
  )
}

export default MapDateFilters
