import { Box, Group, HStack, Input, Spacer, Stack, Separator, Tabs, Text, Textarea } from '@chakra-ui/react'
import type { FocusEventHandler, FC, FormEventHandler } from 'react'
import { useCallback } from 'react'
import { useNavigate, useParams, Form } from 'react-router-dom'

import { Button } from '@app/components/ui/button'
import {
  DialogBody,
  DialogBackdrop,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle
} from '@app/components/ui/dialog'
import { Field } from '@app/components/ui/field'
import { toaster } from '@app/components/ui/toaster'
import useGetObject from '@app/hooks/useGetObject'
import LabelAutocompleteInput from '@app/next/forms/autocomplete/labelAutocompleteInput'
import FormAlert from '@app/next/forms/formAlert'
import SlackChannelInput from '@app/next/forms/slackChannelInput'
import ToggleInput from '@app/next/forms/toggleInput'
import useForm from '@app/next/forms/useForm'
import AiAssistantFiles from '@app/pages/maps/components/settings/components/aiAssistantFiles'
import CopyEmbedCodeButton from '@app/pages/maps/components/settings/components/copyEmbedCodeButton'
import DownloadDataButtons from '@app/pages/maps/components/settings/components/downloadDataButtons'
import PopulateMetricsWithDummyData from '@app/pages/maps/components/settings/components/populateMetricsWithDummyData'
import SendStrategyReviewButton from '@app/pages/maps/components/settings/components/sendStrategyReviewButton'
import StrategyFormAccess from '@app/pages/maps/components/settings/components/strategyFormAccess'
import StrategyDeleteButton from '@app/pages/maps/components/strategyDeleteButton'
import Can from '@app/shared/authorization/can'
import { useStore } from '@app/store'
import type { DomainStrategy } from '@app/types'

interface Props {
  open?: boolean
  onOpenChange?: (e) => void
}

const StrategySettings: FC<Props> = ({ open = true, onOpenChange = () => {} }) => {
  const { strategyId } = useParams()
  const strategy = useGetObject(strategyId, 'strategy')
  const updateObject = useStore.use.updateObject()
  const navigate = useNavigate()
  const { errors } = useForm({})

  const navigateClose = useCallback(() => {
    navigate('..')
  }, [navigate])

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      e.preventDefault()

      const formData = new FormData(e.currentTarget)
      const formValues = Object.fromEntries(formData.entries())
      const values: { id: string; labels?: string[] } = { ...formValues, id: strategyId }
      values.labels &&= JSON.parse(values.labels as unknown as string)

      try {
        await updateObject({ strategy: values }).then((response) => {
          const { errors: updateErrors, nodeObjects } = response?.data?.domainObjectUpdate || {}

          if (updateErrors.length) {
            throw new Error(updateErrors[0].message)
          }

          if (nodeObjects[0]) {
            const { labels } = nodeObjects[0] as DomainStrategy

            // @ts-expect-error labels are a massive time suck and should become real objects
            updateObject({ strategy: { id: strategyId, labels } }, true)
          }
        })

        toaster.create({
          title: 'Updated Strategy',
          description: 'Successfully updated the strategy.',
          type: 'success'
        })

        onOpenChange({ open: false })

        navigateClose()
      } catch (error) {
        toaster.create({
          type: 'error',
          title: 'Error',
          description: error.message
        })
      }
    },
    [navigateClose, onOpenChange, strategyId, updateObject]
  )

  const updateField = useCallback<FocusEventHandler<HTMLInputElement>>(
    (e) => {
      const updateValue = {
        strategy: { id: strategyId, [e.target.name]: e.target.value }
      }

      return updateObject(updateValue)
    },
    [strategyId, updateObject]
  )

  return (
    <DialogRoot
      onOpenChange={onOpenChange}
      onEscapeKeyDown={navigateClose}
      onPointerDownOutside={navigateClose}
      open={open}
      size="xl"
    >
      <DialogBackdrop />
      <DialogContent>
        <Form id="strategy-settings-form" onSubmit={onSubmit}>
          <DialogCloseTrigger onClick={() => navigate('..')} />
          <DialogHeader>
            <DialogTitle>Map Settings</DialogTitle>
          </DialogHeader>
          <DialogBody px={6} bg="bg">
            <FormAlert description={errors?.global?.message} title="Failed to update the strategy!" />
            <Tabs.Root fitted defaultValue="general">
              <Tabs.List>
                <Tabs.Trigger value="general">General</Tabs.Trigger>
                <Tabs.Trigger value="sharing">Sharing</Tabs.Trigger>
                <Tabs.Trigger value="downloads">Downloads</Tabs.Trigger>
                <Tabs.Trigger value="ai">AI</Tabs.Trigger>
              </Tabs.List>

              <Tabs.Content value="general">
                <Stack gap={4} separator={<Separator />}>
                  <Stack gap={4}>
                    <Field label="Name" invalid={!!errors?.name} errorText={errors?.name?.message}>
                      <Input defaultValue={strategy.name} name="name" onBlur={updateField} placeholder="Map name" />
                    </Field>
                    <Field label="Description" invalid={!!errors?.description} errorText={errors?.description?.message}>
                      <Textarea
                        defaultValue={strategy.description}
                        name="description"
                        placeholder="Describe the map."
                      />
                    </Field>
                    <LabelAutocompleteInput
                      name="labels"
                      label="Labels"
                      defaultValue={strategy?.labels || []}
                      menuPortalTarget={document.body}
                    />
                    <Stack gap={4}>
                      <Field
                        label="Slack channel"
                        helperText="Choose a Slack channel below to have a weekly Slack digest delivered each Friday at 9 a.m. PT."
                        invalid={!!errors?.slackChannelId}
                        errorText={errors?.slackChannelId?.message}
                      >
                        <SlackChannelInput
                          name="slackChannelId"
                          defaultValue={strategy.slackChannelId ? [strategy.slackChannelId] : []}
                          clearable
                        />
                      </Field>
                      {strategy.slackChannelId && <SendStrategyReviewButton strategy={strategy} />}
                    </Stack>
                  </Stack>
                  <Box css={{ '--field-label-width': '220px' }}>
                    <Field
                      label="Show correlation scores"
                      orientation="horizontal"
                      invalid={!!errors?.showCorrelations}
                      errorText={errors?.showCorrelations?.message}
                    >
                      <ToggleInput name="showCorrelations" defaultValue={strategy.showCorrelations} />
                    </Field>
                  </Box>
                  <Can I="update" a="metricSource">
                    <PopulateMetricsWithDummyData strategy={strategy} />
                  </Can>
                </Stack>
              </Tabs.Content>
              <Tabs.Content value="sharing">
                <Stack gap={4} separator={<Separator />}>
                  <StrategyFormAccess strategy={strategy} />
                  <HStack>
                    <Text fontSize="sm" fontWeight="medium">
                      Embed code
                    </Text>
                    <Spacer />
                    <CopyEmbedCodeButton strategy={strategy} />
                  </HStack>
                </Stack>
              </Tabs.Content>
              <Tabs.Content value="downloads">
                <DownloadDataButtons strategy={strategy} />
              </Tabs.Content>
              <Tabs.Content value="ai">
                <Stack gap={4} separator={<Separator />}>
                  <Field label="Context">
                    <Textarea
                      defaultValue={strategy.aiContext}
                      name="aiContext"
                      placeholder="Provide information that would be useful for our AI assistants to know."
                    />
                  </Field>
                  <AiAssistantFiles strategy={strategy} />
                </Stack>
              </Tabs.Content>
            </Tabs.Root>
          </DialogBody>
          <DialogFooter>
            <StrategyDeleteButton strategyId={strategy.id} />
            <Spacer />
            <Group>
              <Button onClick={navigateClose} variant="outline">
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </Group>
          </DialogFooter>
        </Form>
      </DialogContent>
    </DialogRoot>
  )
}

export default StrategySettings
