import type { BoxProps } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'
import type { FC, MutableRefObject, ReactNode } from 'react'
import { useRef, useState } from 'react'

import {
  DialogActionTrigger,
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
  DialogTrigger
} from '@app/components/ui/dialog'
import type { Props as TwoButtonProps } from '@app/shared/confirmPopoverFooter2Button'
import ConfirmPopoverFooter2Button from '@app/shared/confirmPopoverFooter2Button'

export type FooterProps = {
  cancelRef: MutableRefObject<null>
  loading?: boolean
  onClose: () => void
}

type Props = BoxProps &
  Partial<Pick<TwoButtonProps, 'onConfirm'>> & {
    header: ReactNode
    body?: ReactNode
    Footer?: FC<FooterProps>
    confirmButtonText?: ReactNode
    loading?: boolean
    onConfirm?: () => Promise<any> // eslint-disable-line @typescript-eslint/no-explicit-any
    onOpen?: () => void
    children: ReactNode
  }

const ConfirmPopover: FC<Props> = ({
  header,
  body = 'Are you sure?',
  Footer = null,
  confirmButtonText = 'Confirm',
  loading = false,
  onConfirm = async () => null,
  onOpen: propOnOpen = () => {},
  children,
  ...boxProps
}) => {
  const [open, setOpen] = useState(false)
  const cancelRef = useRef()
  let foot: ReactNode
  if (Footer) {
    foot = (
      <DialogActionTrigger asChild>
        <Footer cancelRef={cancelRef} loading={loading} onClose={() => setOpen(false)} />
      </DialogActionTrigger>
    )
  } else {
    foot = (
      <ConfirmPopoverFooter2Button
        loading={loading}
        onConfirm={onConfirm}
        onClose={() => setOpen(false)}
        cancelRef={cancelRef}
      >
        {confirmButtonText}
      </ConfirmPopoverFooter2Button>
    )
  }

  return (
    <DialogRoot
      initialFocusEl={cancelRef?.current}
      onOpenChange={(e) => {
        setOpen(e.open)
        propOnOpen()
      }}
      open={open}
      placement="center"
      role="alertdialog"
    >
      <DialogBackdrop />
      <DialogTrigger asChild>
        <Box {...boxProps}>{children}</Box>
      </DialogTrigger>
      <DialogContent>
        <DialogCloseTrigger />
        <DialogHeader>
          <DialogTitle>{header}</DialogTitle>
        </DialogHeader>

        <DialogBody>{body}</DialogBody>

        <DialogFooter>{foot}</DialogFooter>
      </DialogContent>
    </DialogRoot>
  )
}
export default ConfirmPopover
