import type { FC } from 'react'

import CollapseNodeButton from './collapseNodeButton'
import DeleteButton from './deleteButton'
import DuplicateButton from './duplicateButton'
import ExpandCollapsedIncomersButton from './expandCollapsedIncomersButton'

import CardSettingsButton from '@app/pages/maps/components/nodes/components/toolbar/components/cardSettingsButton'
import type { DomainNode } from '@app/types'

interface Props {
  node: DomainNode
}

const CommonButtons: FC<Props> = ({ node }) => (
  <>
    <DuplicateButton nodes={node} />
    <CollapseNodeButton node={node} />
    <ExpandCollapsedIncomersButton node={node} />
    <CardSettingsButton node={node} />
    <DeleteButton node={node} />
  </>
)

export default CommonButtons
