import type { FC, ReactNode } from 'react'

import ConfirmPopover from '@app/shared/confirmPopover'
import { useLookerDashboardDeleteMutation } from '@graphql/queries'

interface Props {
  lookerDashboardId: string
  children: ReactNode
}

const LookerDashboardDeleteDialog: FC<Props> = ({ lookerDashboardId, children }) => {
  const [, deleteLookerDashboard] = useLookerDashboardDeleteMutation()

  const deleteDashboard = async () => {
    await deleteLookerDashboard({ input: { lookerDashboardId } })
  }

  return (
    <ConfirmPopover
      onConfirm={deleteDashboard}
      header="Delete dashboard"
      body="Are you sure? You can&#39;t undo this action afterwards."
      confirmButtonText="Delete"
    >
      {children}
    </ConfirmPopover>
  )
}

export default LookerDashboardDeleteDialog
