import type { FC } from 'react'
import { memo } from 'react'

import CommentsButton from './components/commentsButton'
import CommonButtons from './components/commonButtons'
import DetailsButton from './components/detailsButton'
import EventsButton from './components/eventsButton'
import ImpactButton from './components/impactButton'
import SettingsButton from './components/settingsButton'
import SuggestDownstreamMetricsButton from './components/suggestDownstreamMetricsButton'
import Toolbar from './components/toolbar'

import type { DomainNode } from '@app/types'

interface Props {
  node: DomainNode
}

const EntityToolbar: FC<Props> = ({ node }) => {
  const { strategyId } = node
  const route = `/strategy/${strategyId}/map/entity/${node.data.id}`

  return (
    <Toolbar strategyId={strategyId}>
      <DetailsButton path={route} />
      <SettingsButton path={`${route}/settings`} />
      <EventsButton path={`${route}/events`} />
      <ImpactButton path={`${route}/impact`} />
      <CommentsButton path={`${route}/comments`} />
      <SuggestDownstreamMetricsButton node={node} />
      <CommonButtons node={node} />
    </Toolbar>
  )
}

export default memo(EntityToolbar)
