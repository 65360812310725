import { HStack, Icon, Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { NavLink } from 'react-router-dom'

import ReportPeriod from './reportPeriod'

import ICON_MAP from '@app/utils/iconMap'
import type { Report } from '@graphql/types'

type CReport = Pick<Report, 'id' | 'name' | 'startDate' | 'endDate'>

type ListItemProps = {
  report: CReport
}

type ListProps = {
  reports: CReport[]
}

const DrawerListItem: FC<ListItemProps> = ({ report }) => (
  <Stack gap={1} px={4} py={3} _hover={{ bgColor: 'bg.subtle' }} _currentPage={{ bgColor: 'bg.muted' }} asChild>
    <NavLink to={report.id}>
      <HStack>
        <Icon>
          <ICON_MAP.StrategyReport />
        </Icon>
        <Stack gap={0}>
          <Text fontSize="sm">{report.name}</Text>
          <ReportPeriod report={report} />
        </Stack>
      </HStack>
    </NavLink>
  </Stack>
)

const DrawerList: FC<ListProps> = ({ reports }) => (
  <Stack overflowY="auto">
    {reports.map((report) => (
      <DrawerListItem key={report.id} report={report} />
    ))}
  </Stack>
)

export default DrawerList
