import type { BoxProps, ConditionalValue } from '@chakra-ui/react'
import { Box, Flex, Heading, HStack, IconButton, Image, Spacer, Stack, Text } from '@chakra-ui/react'
import type { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { Tooltip } from '@app/components/ui/tooltip'
import SubmitForReviewButton from '@app/pages/playbooks/components/submitForReviewButton'
import type { Playbook } from '@graphql/types'

type ImageWithOverlayProps = BoxProps & {
  playbook?: Playbook
  title: string
  description?: string
  url?: string
  alt?: string
  src?: string
  spacing?: string
  headingSize?: ConditionalValue<'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'xs' | '3xl' | '4xl' | '5xl' | '6xl' | '7xl'>
  cta?: string | ReactNode
  wrapWithLink?: boolean
}

const sample = (arr) => arr[Math.floor(Math.random() * arr.length)]

const ImageWithOverlay = (props: ImageWithOverlayProps) => {
  const {
    title,
    description,
    url,
    src = null,
    alt,
    spacing = '8',
    objectPosition = 'cover',
    headingSize = 'lg',
    cta = null,
    wrapWithLink = true,
    playbook = null,
    ...rest
  } = props

  const colors = ['accent', 'green', 'orange', 'purple', 'teal', 'cyan', 'yellow']

  const headingPadding = headingSize === 'sm' ? { base: '6', md: '10' } : { base: '2', md: '4' }
  const stackSpacing = headingSize === 'sm' ? 4 : 2

  const stack = (
    <Stack gap={stackSpacing}>
      <Flex>
        <Box flexGrow={1}>
          <Heading color="white" _hover={{ color: 'link' }} size={headingSize}>
            {title}
          </Heading>
        </Box>
        <Box flexShrink={1}>
          {playbook && (
            <Tooltip aria-label="Submit for review" content="Submit for review" showArrow>
              <span>
                <SubmitForReviewButton size="xs" as={IconButton} aria-label="Submit for review" playbook={playbook} />
              </span>
            </Tooltip>
          )}
        </Box>
      </Flex>
      {description && (
        <Text maxW="sm" color="white" fontSize="lg">
          {description}
        </Text>
      )}
    </Stack>
  )

  return (
    <Box pos="relative" overflow="hidden" w="100%" minW="sm" h="100%" minH="10rem" borderRadius="sm" {...rest}>
      {src && (
        <Image
          boxSize="full"
          maxH={{ base: '240px', md: '100%' }}
          objectFit="cover"
          objectPosition={objectPosition}
          alt={alt}
          src={src}
        />
      )}
      <Box
        pos="absolute"
        w="100%"
        h="100%"
        px={headingPadding}
        pt={headingPadding}
        bgGradient={src ? null : 'to-t'}
        bgColor={src ? null : sample(colors)}
        gradientFrom="cyan"
        gradientTo="teal"
        inset="0"
      >
        <Stack gap={spacing} w="100%" h="100%" pb={6}>
          {wrapWithLink ? (
            <Link color="link" to={url}>
              {stack}
            </Link>
          ) : (
            stack
          )}
          <Spacer />
          <HStack>{url && <Box className="light">{cta}</Box>}</HStack>
        </Stack>
      </Box>
    </Box>
  )
}

export default ImageWithOverlay
