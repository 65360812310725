import { Box, Group, HStack, Input, Spacer, Stack, Text } from '@chakra-ui/react'
import sortBy from 'lodash/sortBy'
import type { FC } from 'react'
import { forwardRef, useRef } from 'react'
import { Form } from 'react-router-dom'

import MetricDisplayFormatSection from './metricDisplayFormatSection'

import { AccordionItem, AccordionItemContent, AccordionItemTrigger, AccordionRoot } from '@app/components/ui/accordion'
import { Button } from '@app/components/ui/button'
import { Field } from '@app/components/ui/field'
import { NativeSelectRoot, NativeSelectField } from '@app/components/ui/native-select'
import { Tooltip } from '@app/components/ui/tooltip'
import useCookie from '@app/hooks/useCookie'
import { METRIC_CARD_TYPE_COOKIE } from '@app/lib/globals'
import LabelAutocompleteInput from '@app/next/forms/autocomplete/labelAutocompleteInput'
import FormAlert from '@app/next/forms/formAlert'
import type { MarkdownEditorRef } from '@app/next/forms/markdownEditor/markdownEditor'
import RichInput from '@app/next/forms/richInput'
import ToggleInput from '@app/next/forms/toggleInput'
import UrlInput from '@app/next/forms/urlInput'
import useForm from '@app/next/forms/useForm'
import { strategyTypeOptionsFor } from '@app/pages/maps/components/helpers'
import AiPopulateDescriptionButton from '@app/pages/metrics/components/aiPopulateDescriptionButton'
import AiPopulateTechnicalDescriptionButton from '@app/pages/metrics/components/aiPopulateTechnicalDescriptionButton'
import MetricDeleteButton from '@app/pages/metrics/components/metricDeleteButton'
import MetricDisplay from '@app/pages/metrics/components/metricDisplay'
import MetricRollUpSelect from '@app/pages/metrics/components/metricRollUpSelect'
import MetricSegmentedDataTypeSelect from '@app/pages/metrics/components/metricSegmentedDataTypeSelect'
import StatIntervals from '@app/pages/metrics/components/statIntervals'
import SubscriptionSection from '@app/pages/metrics/components/subscriptionSection'
import type { DomainMetric } from '@app/types'
import { useAccountsQuery } from '@graphql/queries'
import { MetricPositiveDirectionsEnum } from '@graphql/types'

interface Props {
  metric?: DomainMetric
  onSuccess?: (metric: unknown) => void
  onCancel?: () => void
  onDelete?: () => void
}

type MetricDescriptionListProps = { metricId: string | null }

const MetricDescriptionLabel = forwardRef<MarkdownEditorRef, MetricDescriptionListProps>(({ metricId }, ref) => {
  if (!metricId) {
    return <Text>Description</Text>
  }

  return (
    <HStack>
      <Text>Description</Text>
      <Spacer />
      <AiPopulateDescriptionButton data-cy="metric-description-suggest" metricId={metricId} ref={ref} />
    </HStack>
  )
})

const MetricTechnicalDescriptionLabel = forwardRef<MarkdownEditorRef, MetricDescriptionListProps>(
  ({ metricId }, ref) => {
    if (!metricId) {
      return <Text>Technical description</Text>
    }

    return (
      <HStack>
        <Text>Technical description</Text>
        <Spacer />
        <AiPopulateTechnicalDescriptionButton
          data-cy="metric-technical-description-suggest"
          metricId={metricId}
          ref={ref}
        />
      </HStack>
    )
  }
)

const MetricForm: FC<Props> = ({
  metric = null,
  onCancel = () => {},
  onDelete = () => {},
  onSuccess = () => {},
  ...rest
}) => {
  const { errors } = useForm({ onSuccess })
  const descriptionRef = useRef<MarkdownEditorRef>(null)
  const technicalDescriptionRef = useRef<MarkdownEditorRef>(null)
  const [, setStrategyType] = useCookie(METRIC_CARD_TYPE_COOKIE)
  const [
    {
      data: { accounts }
    }
  ] = useAccountsQuery()

  const rollUpSelect = (
    <Field
      label="Roll Up Calculation"
      disabled={metric?.calculated}
      required={!metric?.calculated}
      invalid={!!errors?.rollUp}
      errorText={errors?.rollUp?.message}
    >
      <MetricRollUpSelect name="rollUp" placeholder={null} defaultValue={metric?.rollUp} />
    </Field>
  )

  return (
    <Form method="post" id="metric-form" {...rest}>
      <FormAlert
        description={errors?.global?.message}
        title={`Failed to ${metric ? 'update' : 'create'} the metric!`}
      />
      <AccordionRoot collapsible defaultValue={['general']}>
        <AccordionItem value="general">
          <AccordionItemTrigger bg="bg.muted" px={2}>
            <Box as="span" flex="1" textAlign="left">
              General
            </Box>
          </AccordionItemTrigger>
          <AccordionItemContent p={4}>
            <Stack gap="5">
              <Field label="Name" required invalid={!!errors?.name} errorText={errors?.name?.message}>
                <Input defaultValue={metric?.name} name="name" placeholder="Untitled metric" />
              </Field>
              <RichInput
                name="description"
                label={<MetricDescriptionLabel metricId={metric?.id} ref={descriptionRef} />}
                placeholder="Description (optional)"
                errors={errors}
                defaultValue={metric?.description || ''}
                ref={descriptionRef}
              />
              <RichInput
                name="technicalDescription"
                label={<MetricTechnicalDescriptionLabel metricId={metric?.id} ref={technicalDescriptionRef} />}
                placeholder="Technical description (optional)"
                defaultValue={metric?.technicalDescription || ''}
                errors={errors}
                ref={technicalDescriptionRef}
              />
              <Field
                label="URL"
                invalid={!!errors?.externalUrl}
                errorText={errors?.externalUrl?.message}
                helperText="If this data already lives in another tool, spreadsheet, report, or dashboard, you can link to it here."
              >
                <UrlInput
                  maxW="100%"
                  defaultValue={metric?.externalUrl}
                  name="externalUrl"
                  placeholder="Where can you see this data outside DoubleLoop?"
                />
              </Field>
              <Field
                label="URL title"
                helperText="If you want to display different text for the URL, you can enter it here."
                invalid={!!errors?.externalUrlTitle}
                errorText={errors?.externalUrlTitle?.message}
              >
                <Input
                  maxW="100%"
                  defaultValue={metric?.externalUrlTitle}
                  name="externalUrlTitle"
                  placeholder="See more..."
                />
              </Field>
              <HStack>
                <Field invalid={!!errors?.ownerId} errorText={errors?.ownerId?.message} label="Owner">
                  <NativeSelectRoot>
                    <NativeSelectField name="ownerId" placeholder="None" defaultValue={metric?.ownerId}>
                      {sortBy(accounts, 'user.name').map((account) => (
                        <option key={account.id} value={account.user.id}>
                          {account.user.name} ({account.user.email})
                        </option>
                      ))}
                    </NativeSelectField>
                  </NativeSelectRoot>
                </Field>
                <Field label="Strategy Type" invalid={!!errors?.strategyType} errorText={errors?.strategyType?.message}>
                  <NativeSelectRoot>
                    <NativeSelectField
                      name="strategyType"
                      placeholder=""
                      defaultValue={metric?.strategyType || 'input'}
                      onChange={(e) => setStrategyType(e.target.value)}
                    >
                      {strategyTypeOptionsFor('metric')}
                    </NativeSelectField>
                  </NativeSelectRoot>
                </Field>
              </HStack>
              <LabelAutocompleteInput
                name="labels"
                label="Labels"
                defaultValue={metric?.labels || []}
                menuPortalTarget={document.body}
              />
            </Stack>
          </AccordionItemContent>
        </AccordionItem>
        <AccordionItem value="statistics">
          <AccordionItemTrigger bg="bg.muted" px={2}>
            <Box as="span" flex="1" textAlign="left">
              Statistics
            </Box>
          </AccordionItemTrigger>
          <AccordionItemContent p={4}>
            <Stack gap="5">
              <Stack>
                {metric?.calculated ? (
                  <Tooltip content="For calculated metrics, the roll up calculation is determined by its component metrics.">
                    {rollUpSelect}
                  </Tooltip>
                ) : (
                  rollUpSelect
                )}
                <MetricDisplayFormatSection metric={metric} errors={errors} />
              </Stack>
              <Field label="Exclude weekends?">
                <ToggleInput name="excludeWeekends" defaultValue={metric?.excludeWeekends} />
              </Field>
              <Field
                label="Positive direction"
                helperText="Examples: Up for active user count, Down for page load times"
                required
                invalid={!!errors?.positiveDirection}
                errorText={errors?.positiveDirection?.message}
              >
                <NativeSelectRoot>
                  <NativeSelectField name="positiveDirection" placeholder="" defaultValue={metric?.positiveDirection}>
                    <option value={MetricPositiveDirectionsEnum.Up}>Up</option>
                    <option value={MetricPositiveDirectionsEnum.Down}>Down</option>
                  </NativeSelectField>
                </NativeSelectRoot>
              </Field>

              <MetricDisplay metric={metric} errors={errors} />
              <Field label="Placeholder data?">
                <ToggleInput name="containsPlaceholderData" defaultValue={metric?.containsPlaceholderData} />
              </Field>
            </Stack>
          </AccordionItemContent>
        </AccordionItem>
        {metric && (
          <AccordionItem value="statisticIntervals">
            <AccordionItemTrigger bg="bg.muted" px={2}>
              <Box as="span" flex="1" textAlign="left">
                Statistic Intervals
              </Box>
            </AccordionItemTrigger>
            <AccordionItemContent p={4}>
              <StatIntervals metric={metric} errors={errors} />
            </AccordionItemContent>
          </AccordionItem>
        )}
        <AccordionItem value="segments">
          <AccordionItemTrigger bg="bg.muted" px={2}>
            <Box as="span" flex="1" textAlign="left">
              Segments
            </Box>
          </AccordionItemTrigger>
          <AccordionItemContent p={4}>
            <Field
              label="Segmented Data Type"
              required
              invalid={!!errors?.segmentedDataType}
              errorText={errors?.segmentedDataType?.message}
            >
              <MetricSegmentedDataTypeSelect
                name="segmentedDataType"
                placeholder={null}
                defaultValue={metric?.segmentedDataType}
              />
            </Field>
          </AccordionItemContent>
        </AccordionItem>
        <AccordionItem value="subscriptions">
          <AccordionItemTrigger bg="bg.muted" px={2}>
            <Box as="span" flex="1" textAlign="left">
              Subscriptions
            </Box>
          </AccordionItemTrigger>
          <AccordionItemContent p={4}>
            <SubscriptionSection metric={metric} errors={errors} defaultValue={metric?.subscriptionSchedule} />
          </AccordionItemContent>
        </AccordionItem>
      </AccordionRoot>
      <Group justifyContent="end" w="full" p={4}>
        {metric && <MetricDeleteButton metricId={metric.id} onDelete={onDelete} />}
        <Button onClick={onCancel} variant="outline">
          Cancel
        </Button>

        <Button type="submit">Save</Button>
      </Group>
    </Form>
  )
}

export default MetricForm
