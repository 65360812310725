import type { BoxProps } from '@chakra-ui/react'
import { IconButton } from '@chakra-ui/react'
import type { FC } from 'react'

import ConfirmPopover from '@app/shared/confirmPopover'
import { useStore } from '@app/store'
import type { DomainChat } from '@app/types'
import ICON_MAP from '@app/utils/iconMap'

type Props = Partial<BoxProps> & {
  chat: DomainChat
}

const ChatDeleteButton: FC<Props> = ({ chat, ...boxProps }) => {
  const deleteObject = useStore.use.deleteObject()

  const onConfirm = () => deleteObject(chat)

  return (
    <ConfirmPopover
      onConfirm={onConfirm}
      header="Delete chat"
      body="Are you sure? You can&#39;t undo this action afterwards."
      confirmButtonText="Delete"
      {...boxProps}
    >
      <IconButton aria-label="Delete chat" variant="ghost">
        <ICON_MAP.Delete />
      </IconButton>
    </ConfirmPopover>
  )
}

export default ChatDeleteButton
