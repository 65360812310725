import { Box, Center, HStack, Icon, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { useContext, useState } from 'react'
import { HiOutlineSearch } from 'react-icons/hi'

import { Checkbox } from '@app/components/ui/checkbox'
import MapSegmentFiltersModalContext from '@app/pages/maps/components/map/filters/segmentFilters/mapSegmentFiltersModalContext'

const OptionIcon = () => (
  <Box w={4}>
    <Center>
      <Icon boxSize="4" color="gray.500">
        <HiOutlineSearch />
      </Icon>
    </Center>
  </Box>
)

interface Props {
  option: {
    id: string
    value: string
  }
}

const FilterSegmentValueOption: FC<Props> = ({ option }) => {
  const { segmentFilters, uncommitedChanges, setUncommitedChanges } = useContext(MapSegmentFiltersModalContext)
  const { id, value } = option
  const defaultChecked =
    segmentFilters.some((filter) => filter.segmentValue.id === id) || uncommitedChanges.addIds.includes(id)
  const [checked, setChecked] = useState(defaultChecked)

  const handleClick = (_e, active = true) => {
    let addArray = uncommitedChanges.addIds
    let removeArray = uncommitedChanges.removeIds

    if (segmentFilters.some((filter) => filter.segmentValue.id === id)) {
      if (!active) {
        removeArray.push(id)
      } else {
        removeArray = removeArray.filter((filter) => filter !== id)
      }
    } else if (active) {
      addArray.push(id)
    } else {
      addArray = addArray.filter((filter) => filter !== id)
    }

    setUncommitedChanges({ addIds: addArray, removeIds: removeArray })
  }

  const handleChecked = (e) => {
    const isChecked = e.checked
    setChecked(isChecked)
    handleClick(e, isChecked)
  }

  return (
    <Box
      h="45px"
      borderColor="transparent"
      borderLeftWidth="2px"
      _hover={{ borderColor: 'blue.600', bgColor: 'gray.50' }}
    >
      <Checkbox w="100%" px={4} py={3} checked={checked} onCheckedChange={handleChecked} size="sm">
        <HStack gap={3}>
          <OptionIcon />
          <Text
            overflow="hidden"
            maxW="325px"
            color="gray.500"
            fontSize="sm"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            title={value}
          >
            {value}
          </Text>
        </HStack>
      </Checkbox>
    </Box>
  )
}

export default FilterSegmentValueOption
