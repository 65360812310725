import isNull from 'lodash/isNull'
import pluralize from 'pluralize'
import type { IconType } from 'react-icons'
import { FiArrowDownRight, FiArrowUpRight } from 'react-icons/fi'

import type { DomainMetric } from '@app/types'
import type {
  DisplayFormatEnum,
  StrategyStat,
  RollUpEnum,
  StrategyTypeEnum,
  MetricPositiveDirectionsEnum
} from '@graphql/queries'
import type { Strategy } from '@graphql/types'

type FormatOptions = {
  compactDisplay?: boolean
  maximumDisplayPrecision?: number
  minimumDisplayPrecision?: number
  style?: 'currency' | 'percent'
  currency?: string | undefined
  redecimalPercentages?: boolean | null
}

export const formatStat = (number: number, metric: FormatOptions, locale = undefined) => {
  const options: Intl.NumberFormatOptions = {
    notation: metric?.compactDisplay ? 'compact' : 'standard',
    maximumFractionDigits: typeof metric?.maximumDisplayPrecision === 'number' ? metric.maximumDisplayPrecision : 2,
    minimumFractionDigits: metric?.minimumDisplayPrecision || 0
  }

  if (metric?.style === 'currency') {
    options.style = 'currency'
    options.currency = metric.currency
  } else if (metric?.style === 'percent') {
    options.style = 'percent'
  }

  return Intl.NumberFormat(locale, options).format(number)
}

export const formatTotal = (number: number, format: DisplayFormatEnum, metric: FormatOptions = {}): number | string => {
  if (!number) {
    return 0
  }

  if (format === 'number') {
    return formatStat(number, metric)
  }

  if (format === 'percent') {
    // The division by 100 is required based upon how Intl.numberFormat works.
    const n = metric.redecimalPercentages ? number : number / 100
    return formatStat(n, { style: 'percent', ...metric })
  }

  if (['usd', 'gbp', 'eur'].includes(format)) {
    return formatStat(number, { style: 'currency', currency: format, ...metric })
  }

  if (['hours', 'minutes', 'seconds'].includes(format)) {
    const formattedNumber = formatStat(number, metric)

    switch (format) {
      case 'hours':
        return `${formattedNumber} ${pluralize('hr', number)}`
      case 'minutes':
        return `${formattedNumber} ${pluralize('min', number)}`
      case 'seconds':
        return `${formattedNumber} ${pluralize('sec', number)}`
      default:
        return formattedNumber
    }
  }

  return number
}

export const statChangeColor = (
  change: number | string | null,
  positiveDirection: MetricPositiveDirectionsEnum
): string => {
  if (!change || change === '0.0') {
    return 'gray'
  }

  const changeNum = Number(change)

  const comparison = positiveDirection === 'down' ? changeNum < 0 : changeNum > 0

  return comparison ? 'fg.success' : 'fg.error'
}

export const statChangeIcon = (change: number | string | null): IconType | null => {
  if (change === '0.0') {
    return null
  }

  const changeNum = Number(change)

  const comparison = changeNum >= 0

  return comparison ? FiArrowUpRight : FiArrowDownRight
}

export const statChangeText = (change: number | null): string =>
  !isNull(change) && change !== 0 ? `${Intl.NumberFormat(undefined).format(change)}%` : 'No change'

const PERIODS = {
  d: 'day',
  w: 'week',
  m: 'month',
  q: 'quarter',
  y: 'year'
}

const strategyFilteredStatLabel = (startDate?: string, endDate?: string, range?: string) => {
  if (startDate || endDate) {
    const start = startDate || 'Beginning of time'
    const end = endDate || 'End of time'

    return `${start} - ${end}`
  }

  // assumes range is defined if we get this far
  if (range === 'all') {
    return 'All Time'
  }

  const match = range.match(/^(\d+)(\w)$/)
  const [, num, period] = match || []
  const count = parseInt(num, 10)

  const periodName = PERIODS[period] || 'period'

  if (count === 1) {
    if (periodName === 'day') {
      return 'Yesterday'
    }

    return `Last ${periodName}`
  }

  const periodString = pluralize(periodName, count, true)

  return `Last ${periodString}`
}

// TODO: This function is fairly gross now. It handles changing the card stat labeling and is based on
// one of three scenarios. 1) Normal, which shows the user defined periods. 2) Last month available which
// will show MoM, 3 Month Avg., YoY, or 3) Period to Date which shows MTD, QTD, YTD. Currently, because
// the LMA hides the period to date option, LMA is the winner on what's shown on the card between
// scenarios 2 and 3, which could rarely happen at the same time.
export const statLabel = (
  stat: StrategyStat,
  rollUp: RollUpEnum,
  periodToDate: boolean,
  strategyDateFilters?: Pick<Strategy, 'startDate' | 'endDate' | 'range'>
): string => {
  if (Object.values(strategyDateFilters || {}).some((e) => e) && strategyDateFilters.range !== 'trends') {
    return strategyFilteredStatLabel(
      strategyDateFilters.startDate,
      strategyDateFilters.endDate,
      strategyDateFilters.range
    )
  }

  if (rollUp === 'last_month_available') {
    switch (stat.period) {
      case 'P7D':
        return 'MoM'
      case 'P6W':
        return '3 Month Avg.'
      case 'P12M':
      default:
        return 'YoY'
    }
  }

  if (!periodToDate) {
    return `Past ${stat.displayPeriod}`
  }

  switch (stat.period) {
    case 'P7D':
      return 'MTD'
    case 'P6W':
      return 'QTD'
    case 'P12M':
    default:
      return 'YTD'
  }
}

export const COLORS = {
  positive: {
    color: '#48BB78',
    selectedColor: '#2F855A'
  },
  slightlyPositive: {
    color: '#68D391',
    selectedColor: '#2F855A'
  },
  neutral: {
    color: '#999999',
    selectedColor: '#6bc5f7'
  },
  slightlyNegative: {
    color: '#d76868',
    selectedColor: '#FC8181'
  },
  negative: {
    color: '#E53E3E',
    selectedColor: '#9B2C2C'
  }
}

type ScoreColor = {
  color: string
  selectedColor: string
}

export const correlationScoreColor = (rawScore: string | number, flipColors: boolean): ScoreColor => {
  const score = typeof rawScore === 'string' ? parseFloat(rawScore) : rawScore

  if (Number.isNaN(score) || score === null || score === undefined) {
    return COLORS.neutral
  }

  if (score < -0.5) {
    return flipColors ? COLORS.positive : COLORS.negative
  }

  if (score < 0) {
    return flipColors ? COLORS.slightlyPositive : COLORS.slightlyNegative
  }

  if (score === 0) {
    return COLORS.neutral
  }

  if (score <= 0.5) {
    return flipColors ? COLORS.slightlyNegative : COLORS.slightlyPositive
  }

  return flipColors ? COLORS.negative : COLORS.positive
}

export const metricDisplayName = (type: StrategyTypeEnum) => {
  let displayType

  switch (type) {
    case 'input':
      displayType = 'Input'
      break
    case 'kpi':
      displayType = 'KPI'
      break
    case 'north_star':
      displayType = 'North Star'
      break
    case 'diagnostic':
      displayType = 'Diagnostic'
      break
    default:
      displayType = ''
  }

  return displayType
}

export const flipCorrelationColors = (sourceObject: DomainMetric, targetObject: DomainMetric) => {
  let flipColors = false
  if (sourceObject?.positiveDirection !== targetObject?.positiveDirection) {
    flipColors = true
  }
  return flipColors
}

export const correlationScoreColorNew = (
  rawScore: string | number,
  sourceObject: DomainMetric,
  targetObject: DomainMetric
): ScoreColor => {
  const flipColors = flipCorrelationColors(sourceObject, targetObject)
  const score = typeof rawScore === 'string' ? parseFloat(rawScore) : rawScore

  if (Number.isNaN(score) || score === null || score === undefined) {
    return COLORS.neutral
  }

  if (score < -0.5) {
    return flipColors ? COLORS.positive : COLORS.negative
  }

  if (score < 0) {
    return flipColors ? COLORS.slightlyPositive : COLORS.slightlyNegative
  }

  if (score === 0) {
    return COLORS.neutral
  }

  if (score <= 0.5) {
    return flipColors ? COLORS.slightlyNegative : COLORS.slightlyPositive
  }

  return flipColors ? COLORS.negative : COLORS.positive
}
