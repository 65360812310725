import { Box, Flex } from '@chakra-ui/react'
import type { FC } from 'react'
import { useEffect } from 'react'

import AssistantButton from '@app/pages/maps/components/aiChat/components/assistantButton'
import type { DomainChat } from '@app/types'
import type { ChatNextStepButtonPressedMutation, ChatPreviousStepButtonPressedMutation } from '@graphql/queries'
import {
  useChatNextStepButtonPressedMutation,
  useChatPreviousStepButtonPressedMutation,
  useChatNextAssistantQuery,
  useChatPreviousAssistantQuery
} from '@graphql/queries'

type ChatAssistantButtonsProps = {
  chatId: string
  shouldShowButtons: boolean
  nextStepLabel: string | null
  previousStepLabel: string | null
  setNextStepLabel: (label: string | null) => void
  setPreviousStepLabel: (label: string | null) => void
  storeChat: DomainChat
}

const ChatAssistantButtons: FC<ChatAssistantButtonsProps> = ({
  chatId,
  shouldShowButtons,
  nextStepLabel,
  previousStepLabel,
  setNextStepLabel,
  setPreviousStepLabel,
  storeChat
}) => {
  const nextStepMutation = useChatNextStepButtonPressedMutation()
  const previousStepMutation = useChatPreviousStepButtonPressedMutation()

  const [{ data: nextAssistantData }] = useChatNextAssistantQuery({
    variables: { chatId: chatId! },
    pause: !chatId || !storeChat?.processedState || storeChat?.processedState !== 'ready_for_next'
  })
  const [{ data: previousAssistantData }] = useChatPreviousAssistantQuery({
    variables: { chatId: chatId! },
    pause: !chatId || !storeChat?.processedState || storeChat?.processedState !== 'ready_for_next'
  })

  useEffect(() => {
    if (
      storeChat?.processedState === 'ready_for_next' &&
      nextAssistantData?.chat?.nextAssistantButtonLabel &&
      nextAssistantData.chat.nextAssistantButtonLabel !== nextStepLabel
    ) {
      setNextStepLabel(nextAssistantData.chat.nextAssistantButtonLabel)
    }
  }, [storeChat?.processedState, nextStepLabel, setNextStepLabel, nextAssistantData?.chat?.nextAssistantButtonLabel])

  useEffect(() => {
    if (
      storeChat?.processedState === 'ready_for_next' &&
      previousAssistantData?.chat?.previousAssistantButtonLabel &&
      previousAssistantData.chat.previousAssistantButtonLabel !== previousStepLabel
    ) {
      setPreviousStepLabel(previousAssistantData.chat.previousAssistantButtonLabel)
    }
  }, [
    storeChat?.processedState,
    previousStepLabel,
    setPreviousStepLabel,
    previousAssistantData?.chat?.previousAssistantButtonLabel
  ])

  return (
    <Flex justify="space-between" w="100%">
      <Box>
        {shouldShowButtons && previousStepLabel && previousAssistantData?.chat?.previousAssistantButtonLabel && (
          <AssistantButton<ChatPreviousStepButtonPressedMutation, { input: { chatId: string } }>
            chatId={chatId}
            buttonLabel={previousStepLabel}
            onLabelChange={setPreviousStepLabel}
            disabled={!chatId}
            mutation={previousStepMutation}
            getLabelFromResponse={(response) =>
              response.data?.chatPreviousStepButtonPressed?.previousAssistantButtonLabel ?? undefined
            }
            colorPalette="orange"
          />
        )}
      </Box>
      <Box>
        {shouldShowButtons && nextStepLabel && nextAssistantData?.chat?.nextAssistantButtonLabel && (
          <AssistantButton<ChatNextStepButtonPressedMutation, { input: { chatId: string } }>
            chatId={chatId}
            buttonLabel={nextStepLabel}
            onLabelChange={setNextStepLabel}
            disabled={!chatId}
            mutation={nextStepMutation}
            getLabelFromResponse={(response) =>
              response.data?.chatNextStepButtonPressed?.nextAssistantButtonLabel ?? undefined
            }
            colorPalette="green"
          />
        )}
      </Box>
    </Flex>
  )
}

export default ChatAssistantButtons
