import type { Dispatch, FC, PropsWithChildren } from 'react'
import { useMemo, createContext, useContext } from 'react'

import useMapPresences, {
  type UserPresence,
  type Payload as PresencesPayload,
  type MapPresenceChannel
} from '@app/pages/maps/components/map/useMapPresences'

type UserPresencesContextType = {
  presences: UserPresence[]
  dispatch: Dispatch<PresencesPayload>
  channel: MapPresenceChannel
}
const UserPresencesContext = createContext<UserPresencesContextType | undefined>(undefined)

type Props = {
  strategyId: string
}

const UserPresencesProvider: FC<PropsWithChildren<Props>> = ({ strategyId, children }) => {
  const [state, dispatch, channel] = useMapPresences(strategyId)

  const value = useMemo(() => {
    const presences = Object.values(state)
    return { presences, dispatch, channel }
  }, [state, dispatch, channel])

  return <UserPresencesContext.Provider value={value}>{children}</UserPresencesContext.Provider>
}

const useUserPresencesContext = () => {
  const context = useContext(UserPresencesContext)

  if (context === undefined) {
    throw new Error('useUserPresencesContext must be used within UserPresencesProvider')
  }

  return context
}

export { UserPresencesProvider, useUserPresencesContext }
