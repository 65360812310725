import { subject } from '@casl/ability'
import { Text } from '@chakra-ui/react'
import type { ChangeEvent, FC } from 'react'
import { useCallback } from 'react'

import { Field } from '@app/components/ui/field'
import { NativeSelectField, NativeSelectRoot } from '@app/components/ui/native-select'
import { toaster } from '@app/components/ui/toaster'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import Can from '@app/shared/authorization/can'
import { RoleEnum, useAccountUpdateMutation } from '@graphql/queries'
import type { Account } from '@graphql/types'

interface Props {
  data: Account
}

const RoleCell: FC<Props> = ({ data: account }) => {
  const { user } = useStoreCurrentUser()
  const { id, role, disabledAt } = account
  const [, updateRole] = useAccountUpdateMutation()

  const onChange = useCallback(
    async (e: ChangeEvent<HTMLSelectElement>) => {
      await updateRole({
        input: { accountId: id, role: e.target.value as RoleEnum }
      })
      toaster.create({ title: 'Updated account', type: 'success' })
    },
    [id, updateRole]
  )

  if (disabledAt) {
    return <Text color={disabledAt ? 'fg.subtle' : 'fg.muted'}>{role === RoleEnum.Viewer ? 'Viewer' : 'Editor'}</Text>
  }

  return (
    <Can I="update" this={subject('account', { id, organizationId: user?.organization?.id, role })} passThrough>
      {(allowed) => (
        <Field disabled={!allowed}>
          <NativeSelectRoot size="sm">
            <NativeSelectField defaultValue={role} onChange={onChange} name="role" placeholder="">
              <option value={RoleEnum.Viewer}>Viewer</option>
              <option value={RoleEnum.Editor}>Editor</option>
              {(!allowed || user.role === RoleEnum.Admin) && <option value={RoleEnum.Admin}>Admin</option>}
            </NativeSelectField>
          </NativeSelectRoot>
        </Field>
      )}
    </Can>
  )
}

export default RoleCell
