import { Container } from '@chakra-ui/react'
import type { FC } from 'react'
import { memo } from 'react'
import { useParams } from 'react-router-dom'

import EntityForm from './components/entityForm'

import type { Nodes } from '@app/types'

type Props = {
  containerType: Nodes | null
}

const Create: FC<Props> = ({ containerType }) => {
  const { nodeId } = useParams()

  return (
    <Container py={8}>
      <EntityForm containerType={containerType} nodeId={nodeId} />
    </Container>
  )
}

export default memo(Create)
