import { Input, Stack } from '@chakra-ui/react'
import type { FC, FormEventHandler } from 'react'
import { useState } from 'react'

import { Button } from '@app/components/ui/button'
import { Field } from '@app/components/ui/field'
import { toaster } from '@app/components/ui/toaster'
import FormAlert from '@app/next/forms/formAlert'
import SessionHeader from '@app/pages/sessions/components/sessionHeader'
import unpackErrors from '@app/utils/unpackErrors'
import { useUserResetPasswordRequestMutation } from '@graphql/queries'

const ForgotPassword: FC = () => {
  const [emailSent, setEmailSent] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<ReturnType<typeof unpackErrors>>({})
  const [, mutation] = useUserResetPasswordRequestMutation()

  const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    setLoading(true)

    const formData = new FormData(e.currentTarget)

    try {
      const response = await mutation({ input: { email: formData.get('email') as string } })

      if (response.error) {
        const errs = unpackErrors([response.error])
        setErrors(errs)

        return
      }

      toaster.create({
        title: 'Sending password reset email',
        placement: 'bottom-end',
        type: 'success'
      })

      setEmailSent(true)
    } catch (resp) {
      setErrors(unpackErrors([resp]))
    } finally {
      setLoading(false)
    }
  }

  return (
    <Stack gap="8">
      <SessionHeader
        title="Forgot your password?"
        subtitle="Remembered your password?"
        linkText="Sign in"
        linkUrl="/sign_in"
      />

      <Stack gap="6">
        <FormAlert description={errors?.global?.message} status="success" />
        <form id="forgot-password-form" onSubmit={onSubmit}>
          <Stack gap="5">
            <Field label="Email" errorText={errors?.email?.message} id="email" invalid={!!errors?.email} required>
              <Input
                autoComplete="email"
                id="email"
                name="email"
                placeholder="Enter the email you used to sign up with"
                type="email"
              />
            </Field>
            <Button disabled={emailSent} form="forgot-password-form" loading={loading} size="md" type="submit">
              {emailSent ? 'Email sent, check your inbox' : 'Send reset email'}
            </Button>
          </Stack>
        </form>
      </Stack>
    </Stack>
  )
}

export default ForgotPassword
