import { HStack, Spacer, Stack, useBreakpointValue, Separator } from '@chakra-ui/react'
import type { FC } from 'react'

import { useColorModeValue } from '@app/components/ui/color-mode'
import RichTextInput from '@app/next/forms/inline/richTextInput'
import RichTextInputWithLabel from '@app/next/forms/inline/richTextInputWithLabel'
import ViewerFieldWrapper from '@app/next/forms/inline/support/viewerFieldWrapper'
import MetricTypePopover from '@app/next/metricTypePopover'
import DrawerHeaderActions from '@app/pages/maps/components/drawer/drawerHeaderActions'
import { CardTitle } from '@app/shared/cards/components'
import { DomainObjectType } from '@app/shared/cards/components/cardTypeSection'
import FavoriteToggleButton from '@app/shared/favorites/favoriteToggleButton'
import { PageHeaderSection } from '@app/shared/layout'
import type { DomainMetric } from '@app/types'
import ICON_MAP, { COLOR_MAP } from '@app/utils/iconMap'

interface Props {
  metric: DomainMetric
  strategyId: string
}

const Header: FC<Props> = ({ metric, strategyId }) => {
  const { id, favoriteId } = metric
  const cardTypeColor = useColorModeValue(`${COLOR_MAP.Metric}.600`, `${COLOR_MAP.Metric}.300`)

  return (
    <PageHeaderSection>
      <HStack pl={4}>
        <DomainObjectType type="Metric" title="Metric /" icon={ICON_MAP.Metric} color={COLOR_MAP.Metric}>
          <MetricTypePopover metric={metric} fontWeight="semibold" color={cardTypeColor} />
        </DomainObjectType>
        <Spacer />
        <FavoriteToggleButton favoritableType="Metric" favoritableId={id} favoriteId={favoriteId} />
        <DrawerHeaderActions objId={metric.id} objType={metric.classType} strategyId={strategyId} />
      </HStack>
      <Separator />
      <Stack gap={4} px={4}>
        <CardTitle domainObject={metric} name="name" fontSize={useBreakpointValue({ base: 'lg', md: 'xl' })} />
        <ViewerFieldWrapper value={metric.description}>
          <RichTextInput domainObject={metric} name="description" placeholder="Description..." />
        </ViewerFieldWrapper>
        <ViewerFieldWrapper value={metric.technicalDescription}>
          <RichTextInputWithLabel
            domainObject={metric}
            name="technicalDescription"
            placeholder="Technical description..."
            label="Technical description"
          />
        </ViewerFieldWrapper>
      </Stack>
    </PageHeaderSection>
  )
}

export default Header
