import type { FC } from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import withSuspenseWrapper from '@app/shared/withSuspenseWrapper'
import { useStore } from '@app/store'
import { useMetricDataPointsQuery } from '@graphql/queries'

interface Props {
  setLoading?: (loading: boolean) => void
}

const MetricDataPointsFetcher: FC<Props> = ({ setLoading = (_loading) => {} }) => {
  const { nodeId, strategyId } = useParams()

  const url = new URL(window.location.href)
  const page = parseInt(url.searchParams.get('page'), 10) || 1
  const limit = parseInt(url.searchParams.get('limit'), 10) || null

  const { addObjectPage } = useStore.getState()

  const [{ data }] = useMetricDataPointsQuery({
    variables: {
      metricId: nodeId,
      strategyId,
      page,
      limit
    }
  })

  useEffect(() => {
    if (!data) {
      return
    }

    const { collection, metadata } = data?.metricDataPoints || {}
    addObjectPage('metricDataPoint', collection, metadata)
    setLoading(false)
  }, [page, data, addObjectPage, setLoading])

  return null
}

export default withSuspenseWrapper(MetricDataPointsFetcher)
