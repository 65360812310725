import { Stack } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'

import { Field } from '@app/components/ui/field'
import DurationInput from '@app/next/forms/durationInput'
import ToggleInput from '@app/next/forms/toggleInput'
import TextDivider from '@app/shared/textDivider'
import type { FormErrors, DomainMetric } from '@app/types'

interface Props {
  metric?: DomainMetric
  errors?: FormErrors
}

const StatIntervals: FC<Props> = ({ metric = null, errors = null }) => {
  const [checked, setChecked] = useState(metric?.periodToDate)

  if (metric?.rollUp === 'last_month_available') {
    return null
  }

  return (
    <Stack gap={4}>
      <Field label="Month to date (MTD), Quarter to date (QTD), Year to date (YTD)">
        <ToggleInput
          checked={checked}
          name="periodToDate"
          onChange={() => setChecked(!checked)}
          defaultValue={checked}
        />
      </Field>
      {!checked && (
        <>
          <TextDivider>or</TextDivider>
          <Field
            label="Interval Period 1"
            invalid={!!errors?.strategyStatPeriod0}
            errorText={errors?.strategyStatPeriod0?.message}
          >
            <DurationInput
              name="strategyStatPeriod0"
              defaultValue={metric?.strategyStats?.[0]?.period || 'P7D'}
              bg="bg"
            />
          </Field>
          <Field
            label="Interval Period 2"
            invalid={!!errors?.strategyStatPeriod1}
            errorText={errors?.strategyStatPeriod1?.message}
          >
            <DurationInput
              name="strategyStatPeriod1"
              defaultValue={metric?.strategyStats?.[1]?.period || 'P6W'}
              bg="bg"
            />
          </Field>
          <Field
            label="Interval Period 3"
            invalid={!!errors?.strategyStatPeriod2}
            errorText={errors?.strategyStatPeriod2?.message}
          >
            <DurationInput
              name="strategyStatPeriod2"
              defaultValue={metric?.strategyStats?.[2]?.period || 'P12M'}
              bg="bg"
            />
          </Field>
        </>
      )}
    </Stack>
  )
}

export default StatIntervals
