import { Stack, Text, Image, Box, Card } from '@chakra-ui/react'
import type { FC } from 'react'
import { memo } from 'react'

import useInteraction from '@app/pages/maps/useInteraction'
import type { Playbook } from '@graphql/types'

type Props = {
  strategyId: string
  playbooks: Playbook[]
}

const PlaybooksList: FC<Props> = ({ strategyId, playbooks }) => {
  const { onDragStart } = useInteraction(strategyId)
  const resultsCount = playbooks?.length || 0
  const noResults = resultsCount === 0

  // remove from the playbooks array the playbook that has a name matching "Blank Canvas"
  const filteredPlaybooks = playbooks.filter((playbook) => playbook.name !== 'Blank canvas')

  return (
    <Stack pb={4} data-testid="playbooks-list">
      <Text color="muted" fontSize="sm" data-testid="number-results">
        {resultsCount} found
      </Text>
      {resultsCount &&
        filteredPlaybooks.map((playbook) => (
          <Card.Root
            className={`react-flow__placed-card drag-drop-card-${playbook.id}`}
            key={playbook.id}
            zIndex="modal" // This ensures that the element being draggable is above everything else so the browser can create a proper preview
            _hover={{ cursor: 'grab' }}
            _grabbed={{ cursor: 'grabbing' }}
            draggable
            onDragStart={(e) => onDragStart(e, playbook)}
          >
            <Card.Header>
              <Text>{playbook.name}</Text>
            </Card.Header>
            <Card.Body>{playbook?.hero && <Image maxW="100%" src={playbook.hero} />}</Card.Body>
          </Card.Root>
        ))}
      {noResults && <Box data-testid="no-results-text">No results found</Box>}
    </Stack>
  )
}

export default memo(PlaybooksList)
