import type { DomainNode } from '@app/types'

const cardMetadata = (node: DomainNode) => {
  const size = node?.metadata?.size || 'normal'
  const color = node?.metadata?.color

  return { size, color }
}

export default cardMetadata
