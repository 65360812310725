import { Group } from '@chakra-ui/react'
import type { FC } from 'react'
import { memo, useEffect, useState } from 'react'
import { useRevalidator } from 'react-router-dom'

import SegmentFiltersModal from './segmentFiltersModal'

import { Button } from '@app/components/ui/button'
import useGetObject from '@app/hooks/useGetObject'
import MapSegmentFilter from '@app/pages/maps/components/map/filters/segmentFilters/mapSegmentFilter'
import type { StrategyQueryStrategy } from '@app/types'
import ICON_MAP from '@app/utils/iconMap'
import { useSegmentFiltersUpdateMutation } from '@graphql/queries'

interface Props {
  strategyId: string
  canEdit?: boolean
}

const MapSegmentFilters: FC<Props> = ({ strategyId, canEdit = false }) => {
  const strategy = useGetObject(strategyId, 'strategy') as StrategyQueryStrategy

  const [open, setOpen] = useState(false)
  const [isFiltering, setIsFiltering] = useState(false)
  const [isApplying, setIsApplying] = useState(false)
  const [, updateSegmentFilters] = useSegmentFiltersUpdateMutation()
  const revalidator = useRevalidator()

  useEffect(() => {
    if (revalidator.state === 'idle' && isApplying) {
      setIsApplying(false)
    }
  }, [revalidator.state, isApplying])

  if (!canEdit) {
    return null
  }

  const { showSegmentCreate } = strategy

  const onNewSegmentClick = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const onFilter = (addIds: string[], removeIds: string[]) => {
    setIsFiltering(true)

    const removeSegmentIds = removeIds
      .map((id) => strategy.segmentFilters.find((filter) => filter.segmentValue.id === id)?.id)
      .filter((id) => id)

    const resp = updateSegmentFilters({
      input: {
        filterableType: 'Strategy',
        filterableId: strategy.id,
        segmentValueIds: addIds,
        segmentFilterIds: removeSegmentIds
      }
    })

    return resp
      .then(() => {
        setIsApplying(true)
        revalidator.revalidate()
      })
      .finally(() => {
        setIsFiltering(false)
      })
  }

  const onRemoveClick = (segmentFilter) => {
    const resp = updateSegmentFilters({
      input: { filterableType: 'Strategy', filterableId: strategy?.id, segmentFilterIds: [segmentFilter.id] }
    })

    return resp.then((r) => {
      setIsApplying(true)

      revalidator.revalidate()

      return r
    })
  }

  const filters = (strategy?.segmentFilters || []).map((filter) => ({
    id: filter.id,
    name: filter.segmentName.name,
    value: filter.segmentValue.value
  }))

  return (
    <Group>
      {showSegmentCreate && (
        <>
          <Button
            aria-label="Segments"
            colorPalette="gray"
            disabled={isApplying || isFiltering}
            loading={isApplying || isFiltering}
            loadingText={isApplying ? 'Applying' : 'Loading'}
            onClick={onNewSegmentClick}
            variant="ghost"
            size="2xs"
          >
            <ICON_MAP.Segment />
            Segment
          </Button>
          <SegmentFiltersModal
            strategyId={strategyId}
            open={open}
            onOpenChange={onClose}
            onFilter={onFilter}
            segmentFilters={strategy?.segmentFilters || []}
          />
        </>
      )}

      {filters.map((filter) => (
        <MapSegmentFilter
          key={filter.id}
          canEdit={canEdit}
          filter={filter}
          onRemoveClick={onRemoveClick}
          isApplying={isApplying}
        />
      ))}
    </Group>
  )
}

MapSegmentFilters.displayName = 'MapSegmentFilters'

export default memo(MapSegmentFilters)
