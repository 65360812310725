import { Flex } from '@chakra-ui/react'
import { ReactFlowProvider } from '@xyflow/react'
import type { FC } from 'react'
import { memo, useRef, Suspense } from 'react'
import { Outlet, useLoaderData } from 'react-router-dom'

import PermissionsContextProvider from './permissionsContext'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import BackgroundChangeLoader from '@app/pages/maps/components/backgroundChangeLoader'
import LiveCursors from '@app/pages/maps/components/map/liveCursors/liveCursors'
import Nav from '@app/pages/maps/components/map/nav'
import DrawerContextProvider from '@app/pages/maps/drawerContext'
import { UserPresencesProvider } from '@app/pages/maps/userPresencesContext'
import { MapShell } from '@app/shared/layout'
import MultiAccountBanner from '@app/shared/multiAccountBanner'
import type { StrategyQueryStrategy } from '@app/types'

interface Props {
  embedded?: boolean
}

const Show: FC<Props> = ({ embedded = false }) => {
  const { user } = useStoreCurrentUser()

  // check if parameter is set for metricData
  // remove from the URL
  // update strategy in zustand to track the flag

  const { strategy } = useLoaderData() as {
    strategy: StrategyQueryStrategy
  }

  const cursorPanel = useRef(null)

  return (
    <ReactFlowProvider>
      <UserPresencesProvider strategyId={strategy.id}>
        <DrawerContextProvider strategyId={strategy.id}>
          <PermissionsContextProvider strategy={strategy} user={user}>
            <MapShell strategyId={strategy.id}>
              <MultiAccountBanner />
              <Nav embedded={embedded} strategyId={strategy.id} />
              <Flex ref={cursorPanel} pos="relative" direction="column" flex="1" overflowX="hidden" overflowY="auto">
                <Suspense fallback={<BackgroundChangeLoader />}>
                  <LiveCursors cursorPanel={cursorPanel} />
                  <Outlet />
                </Suspense>
              </Flex>
            </MapShell>
          </PermissionsContextProvider>
        </DrawerContextProvider>
      </UserPresencesProvider>
    </ReactFlowProvider>
  )
}

Show.displayName = 'Show'

export default memo(Show)
