import type { StackProps } from '@chakra-ui/react'
import { Icon, IconButton } from '@chakra-ui/react'
import type { FC } from 'react'
import { memo } from 'react'
import { PiLockKeyFill } from 'react-icons/pi'

import { Card, CardTitle } from '../components'

import { Tooltip } from '@app/components/ui/tooltip'
import DomainObjectStatusPopover from '@app/next/domainObjectStatusPopover'
import CollapsedNodes from '@app/pages/maps/components/nodes/components/collapsedNodes'
import cardFontSize from '@app/shared/cards/cardFontSize'
import cardMetadata from '@app/shared/cards/cardMetadata'
import CardLabels from '@app/shared/cards/components/cardLabels'
import CardSection from '@app/shared/cards/components/cardSection'
import CardSharedActions from '@app/shared/cards/components/cardSharedActions'
import CardTypeSection from '@app/shared/cards/components/cardTypeSection'
import MarkdownDisplay from '@app/shared/markdownDisplay'
import type { DomainNode, DomainStrategy } from '@app/types'
import ICON_MAP from '@app/utils/iconMap'
import { SharingTypesEnum } from '@graphql/types'

interface Props extends StackProps {
  strategy?: DomainStrategy
  node?: DomainNode
}

const SubMapCard: FC<Props> = ({ strategy, node, ...rest }) => {
  const { size, color } = cardMetadata(node)

  const { id, sharing, description } = strategy
  const fontSize = cardFontSize('sm', size)

  const lockedDisplay = sharing !== SharingTypesEnum.PublicAccess && (
    <Tooltip content="This card will not display to logged out users unless you make it public." showArrow>
      <IconButton cursor="default" aria-label="Sharing" variant="ghost">
        <Icon boxSize={size === 'normal' ? 5 : 6} color="fg.subtle">
          <PiLockKeyFill />
        </Icon>
      </IconButton>
    </Tooltip>
  )

  return (
    <Card color={color} {...rest}>
      <CardTypeSection title="Map" icon={ICON_MAP.StrategyMap} route={`preview/${id}`} size={size} />
      <CardSection size={size}>
        <CardTitle domainObject={strategy} name="name" size={size} />
        {description && <MarkdownDisplay text={description} fontSize={fontSize} />}
      </CardSection>
      <CardLabels fieldName="labels" domainObject={strategy} size={size} />
      <CardSharedActions domainObject={strategy} size={size}>
        <DomainObjectStatusPopover domainObject={strategy} mx={2} />
        {lockedDisplay}
      </CardSharedActions>
      {node && <CollapsedNodes node={node} />}
    </Card>
  )
}

export default memo(SubMapCard)
