import { Container } from '@chakra-ui/react'
import type { FC } from 'react'
import { useParams } from 'react-router-dom'

import EntityForm from './components/entityForm'

import useGetObject from '@app/hooks/useGetObject'
import withAwait from '@app/shared/withAwait'
import type { Nodes } from '@app/types'

type Props = {
  containerType: Nodes | null
}

const Edit: FC<Props> = ({ containerType }) => {
  const { entityId, nodeId } = useParams()

  const entity = useGetObject(entityId, 'entity')

  return (
    <Container py={8}>
      <EntityForm entity={entity} nodeId={nodeId} containerType={containerType} />
    </Container>
  )
}

export default withAwait(Edit, 'entity')
