import type { TextProps } from '@chakra-ui/react'
import { Text } from '@chakra-ui/react'
import type { FC } from 'react'

import { formatPeriodDate } from '@app/utils/dateTimeHelpers'
import type { Report } from '@graphql/types'

interface Props extends TextProps {
  report: Pick<Report, 'startDate' | 'endDate'>
}

const ReportPeriod: FC<Props> = ({ report, ...textProps }) => (
  <Text color="subtle" fontSize="xs" {...textProps}>
    {formatPeriodDate(report.startDate)} {report?.endDate ? '->' : ''}{' '}
    {report?.endDate && formatPeriodDate(report.endDate)}
  </Text>
)

export default ReportPeriod
