import { Box, Text } from '@chakra-ui/react'

import APIToken from '@app/pages/settings/integrations/components/apiToken'
import IntegrationInstallation from '@app/pages/settings/integrations/components/integrationInstallation'
import CardHeader from '@app/shared/layout/cardHeader'
import PageHeader from '@app/shared/layout/pageHeader'
import PageStack from '@app/shared/layout/pageStack'

const Looker = () => {
  const installationSteps = [
    {
      title: 'Open your Looker instance',
      description: <Text>Make sure the person adding the integration has access to add Looker Actions.</Text>
    },
    {
      title: 'Add the action hub',
      description: (
        <Text>
          Click &quot;Add Action Hub&quot; and add &quot;https://app.doubleloop.app/looker&quot;. Click the enable
          button to bring up the settings page.
        </Text>
      )
    },
    {
      title: 'Add the API Key',
      description: <APIToken />
    },
    {
      title: 'Choose your metric',
      description: (
        <Text>
          Open the Looker Look associated with your metric, it must have at least one data and metric value. From the
          configuration menu, choose the &quot;Schedule option&quot;. Give it a name, pick how often, and hit send test
          followed by Save All
        </Text>
      )
    },
    {
      title: 'Check for your metrics',
      description: (
        <Text>Please wait until after your schedule has run and check for your metrics on the all events page.</Text>
      )
    }
  ]

  return (
    <PageStack>
      <PageHeader title="Looker" subtitle="Send metrics from Looker to DoubleLoop" />
      <Box p={4}>
        <CardHeader title="Installation instructions" />
        <IntegrationInstallation integration={null} steps={installationSteps} skipSpinner skipFinish />
      </Box>
    </PageStack>
  )
}

export default Looker
