import useGetObject from '@app/hooks/useGetObject'
import type { DomainStrategy } from '@app/types'

type UseGetStrategyDateFilters = (strategyId: string) => Pick<DomainStrategy, 'startDate' | 'endDate' | 'range'>
const useGetStrategyDateFilters: UseGetStrategyDateFilters = (strategyId) => {
  const strategy = useGetObject(strategyId, 'strategy')

  return {
    startDate: strategy?.startDate,
    endDate: strategy?.endDate,
    range: strategy?.range
  }
}

export default useGetStrategyDateFilters
