import { Box, Flex } from '@chakra-ui/react'
import { useState } from 'react'

import DrawerSidebar from './drawerSidebar'
import { ToggleButton } from './toggleButton'

import { useColorModeValue } from '@app/components/ui/color-mode'
import Wordmark from '@app/images/wordmark'

const Navbar = () => {
  const [open, setOpen] = useState(false)

  return (
    <Box w="full" px="4" py="2" bg="bg" shadow={useColorModeValue('sm', 'sm-dark')}>
      <Flex justify="space-between">
        <Wordmark w="100px" h="auto" color={useColorModeValue('blue.600', 'blue.200')} />
        <ToggleButton variant="ghost" open={open} aria-label="Open Menu" onClick={() => setOpen(!open)} />
        <DrawerSidebar open={open} onOpenChange={() => setOpen(false)} />
      </Flex>
    </Box>
  )
}

export default Navbar
