import { HStack, Spacer } from '@chakra-ui/react'
import type { Uppy, UppyFile } from '@uppy/core'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/file-input/dist/style.css'
import '@uppy/drop-target/dist/style.css'
import DropTarget from '@uppy/drop-target'
import uniqueId from 'lodash/uniqueId'
import type { FC } from 'react'
import { memo, useEffect, useRef, useState } from 'react'
import { FiUpload } from 'react-icons/fi'

import { Button } from '@app/components/ui/button'
import File from '@app/shared/uploader/components/file'
import type { FileTicketMeta, RemoteFile } from '@app/shared/uploader/types'
import { Sentry } from '@app/utils/sentry'

type Props = {
  disabled?: boolean
  uppyInstance: Uppy<FileTicketMeta, RemoteFile>
  dropTarget?: HTMLElement | null
}

const MAX_FILES = 20 // OpenAI limit

const UppyFiles = ({ files }) => (
  <HStack wrap="wrap">
    {files.map((file: UppyFile<FileTicketMeta, RemoteFile>) => (
      <File key={file.id} file={file} />
    ))}
  </HStack>
)

const DoubleloopUploader: FC<Props> = ({ disabled = false, uppyInstance, dropTarget = null }) => {
  const inputRef = useRef<HTMLInputElement>()
  const [, setRefreshCounter] = useState(0)

  const maxRemainingFiles = MAX_FILES - uppyInstance.getFiles().length

  uppyInstance.on('file-added', () => {
    setRefreshCounter((prev) => prev + 1)
  })

  const accept = uppyInstance?.opts.restrictions ? uppyInstance?.opts.restrictions.allowedFileTypes?.join(',') : '*/*'

  useEffect(() => {
    if (dropTarget) {
      uppyInstance.use(DropTarget, {
        id: `${uniqueId('drop-target')}`,
        target: dropTarget,
        onDragOver: (e: DragEvent) => {
          const t = e.target as HTMLElement
          t.classList.add('uppy-drag-over')
        },
        onDragLeave: (e: DragEvent) => {
          const t = e.target as HTMLElement
          t?.classList.remove('uppy-drag-over')
        },
        onDrop: (e: DragEvent) => {
          const t = e.target as HTMLElement
          t?.classList.remove('uppy-drag-over')
        }
      })
    }
  }, [dropTarget, uppyInstance])

  return (
    <>
      <HStack alignItems="flex-start">
        <UppyFiles files={uppyInstance.getFiles()} />
        <Spacer />
        <Button
          colorPalette="gray"
          disabled={disabled || maxRemainingFiles === 0}
          onClick={() => {
            if (inputRef?.current) {
              inputRef.current.click()
            }
          }}
          size="xs"
          type="button"
          variant="outline"
        >
          <FiUpload /> Upload files
        </Button>
      </HStack>
      <input
        id="uppy-input"
        type="file"
        multiple
        accept={accept}
        ref={inputRef}
        style={{ display: 'none' }}
        onChange={(event) => {
          const uploadedFiles = Array.from(event.target.files)

          uploadedFiles.forEach((file) => {
            try {
              uppyInstance.addFile({
                source: 'file input',
                name: file.name,
                type: file.type,
                data: file
              })
            } catch (err) {
              Sentry.captureException(err)
            }
          })
        }}
      />
    </>
  )
}

export default memo(DoubleloopUploader)
