import type { OperationResult } from 'urql'

import type { BoundedState } from '@app/store/types'
import type { DomainObject } from '@app/types'
import Normalizer from '@graphql/normalizer'
import type { CrudOperationEnum } from '@graphql/types'

export type StoreOperations = Pick<BoundedState, 'addObject' | 'deleteObject'>

const normalizer = new Normalizer({
  skipTypenames: [
    'Attachment',
    'CalculatedMetricInput',
    'Classification',
    'CustomerSubscription',
    'EntityAttachment',
    'EntityContainment',
    'EntityContribution',
    'FileAttachment',
    'Label',
    'MetricSourceActivity',
    'Organization',
    'SlackChannel',
    'SourceIdentity',
    'StrategyStat',
    'SegmentName',
    'SegmentValue',
    'SegmentFilter'
  ]
})

const crudOperation = (result: OperationResult): CrudOperationEnum => {
  // We allow both the operation result itself, or an immediate child subfield to act as the operation.
  // This allows us to both send the result directly for subscriptions, as well as add additional fields in mutations.
  // Note that `crudOperation` naming is NOT optional. Map fields in your queries as needed.
  const baseCrudOp = result?.data?.crudOperation
  return baseCrudOp?.operation || baseCrudOp?.crudOperation?.operation
}

const handleGraphqlResult = (result: OperationResult, storeOperations: StoreOperations) => {
  const addObject = storeOperations?.addObject
  const deleteObject = storeOperations?.deleteObject

  if (!addObject || !deleteObject) {
    return
  }

  try {
    const normalized: Partial<Record<keyof BoundedState, Record<string, DomainObject>>> = normalizer.normalize(result)

    const crudOp = crudOperation(result)

    Object.values(normalized).forEach((objects) => {
      Object.values(objects).forEach((object) => {
        if (object.classType) {
          try {
            if (crudOp === 'delete') {
              deleteObject(object, { skipMutation: true })
            } else {
              addObject(object)
            }
          } catch (_e) {
            // console.error('Error handling individual graphql object', e, object)
          }
        }
      })
    })
  } catch (_e) {
    // console.error('normalizer error', e)
  }
}

export default handleGraphqlResult
