import { IconButton } from '@chakra-ui/react'
import type { FC } from 'react'
import { FiTrash2 } from 'react-icons/fi'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import ConfirmPopover from '@app/shared/confirmPopover'
import { useStore } from '@app/store'
import type { DomainComment } from '@app/types'

interface CommentDeleteButtonProps {
  comment: Pick<DomainComment, 'id' | 'classType' | 'creatorId' | 'commentableType' | 'commentableId'>
}

const CommentDeleteButton: FC<CommentDeleteButtonProps> = ({ comment }) => {
  const deleteComment = useStore.use.deleteComment()
  const { user } = useStoreCurrentUser()

  if (user?.id !== comment.creatorId) {
    return null
  }

  const handleDeleteComment = () => deleteComment(comment)

  return (
    <ConfirmPopover
      onConfirm={handleDeleteComment}
      header="Delete comment"
      body="Are you sure? You can&#39;t undo this action afterwards."
      confirmButtonText="Delete"
    >
      <IconButton aria-label="Delete comment" size="xs" variant="ghost">
        <FiTrash2 />
      </IconButton>
    </ConfirmPopover>
  )
}

export default CommentDeleteButton
