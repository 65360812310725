import { Textarea } from '@chakra-ui/react'
import { forwardRef } from 'react'
import type { RefObject } from 'react'

import { Field } from '@app/components/ui/field'
import ChatTextareaHelpText from '@app/pages/maps/components/aiChat/components/chatTextareaHelpText'

const ChatTextarea = ({ disabled, ...rest }, ref: RefObject<HTMLTextAreaElement>) => (
  <Field disabled={disabled} helperText={<ChatTextareaHelpText inputRef={ref} />}>
    <Textarea
      ref={ref}
      maxH="200px"
      pe="9"
      _placeholder={{ color: 'fg.subtle' }}
      resize="none"
      autoFocus
      name="prompt"
      autoresize
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
          e.preventDefault()
          e.currentTarget.form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
        }
      }}
      placeholder="Ask me anything..."
      rows={3}
      {...rest}
    />
  </Field>
)

export default forwardRef(ChatTextarea)
