import { HStack, Input, Text } from '@chakra-ui/react'
import type { FC } from 'react'

import InlineUpdaterRoot from '@app/next/forms/inlineUpdaterRoot'
import cardFontSize from '@app/shared/cards/cardFontSize'
import type { CardSize, DomainObject } from '@app/types'

interface Props {
  domainObject: DomainObject
  fieldName?: string
  size?: CardSize
  showLabel?: boolean
}

const CardDueDate: FC<Props> = ({ domainObject, fieldName, size = 'normal', showLabel = true }) => {
  const fontSize = cardFontSize('md', size)

  return (
    <HStack>
      {showLabel && (
        <Text color="fg.muted" fontSize={fontSize} fontWeight="bold" whiteSpace="nowrap">
          Due date
        </Text>
      )}
      <InlineUpdaterRoot domainObject={domainObject}>
        <Input fontSize={fontSize} defaultValue={domainObject?.[fieldName] || ''} name={fieldName} type="date" />
      </InlineUpdaterRoot>
    </HStack>
  )
}

export default CardDueDate
