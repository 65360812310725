import { useCallback } from 'react'
import type { OperationResult } from 'urql'

import { toaster } from '@app/components/ui/toaster'
import { useStore } from '@app/store'
import { MetricDeleteAllMetricDataPoints } from '@graphql/documents/metric.graphql'
import { MetricSourceRun } from '@graphql/documents/metric_source.graphql'
import type {
  MetricDeleteAllMetricDataPointsMutation,
  MetricDeleteAllMetricDataPointsMutationVariables,
  MetricSourceRunMutation,
  MetricSourceRunMutationVariables
} from '@graphql/queries'

type UseRunMetricSource = () => (args: {
  metricId: string
  metricSourceId: string
  clearDataPoints: boolean
}) => Promise<OperationResult<MetricSourceRunMutation>>

const useRunMetricSource: UseRunMetricSource = () => {
  const actionMutation = useStore.use.actionMutation()

  return useCallback(
    async ({ metricId, metricSourceId, clearDataPoints }) => {
      if (clearDataPoints) {
        await actionMutation<MetricDeleteAllMetricDataPointsMutation, MetricDeleteAllMetricDataPointsMutationVariables>(
          MetricDeleteAllMetricDataPoints,
          { metricId }
        )
      }

      return actionMutation<MetricSourceRunMutation, MetricSourceRunMutationVariables>(MetricSourceRun, {
        metricSourceId
      }).then((resp) => {
        if (resp.error) {
          throw resp
        }

        const { errors } = resp.data.metricSourceRun

        if (errors.length) {
          throw errors
        }

        toaster.create({
          title: 'Requesting source data',
          type: 'success'
        })

        return resp
      })
    },
    [actionMutation]
  )
}

export default useRunMetricSource
