import { Box, Stack } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'
import { RRule } from 'rrule'

import { Field } from '@app/components/ui/field'
import RRuleInput from '@app/next/forms/rruleInput'
import SlackChannelInput from '@app/next/forms/slackChannelInput'
import ToggleInput from '@app/next/forms/toggleInput'
import type { FormErrors, DomainMetric } from '@app/types'

interface Props {
  defaultValue?: string
  metric?: DomainMetric
  errors?: FormErrors
}

const SubscriptionSection: FC<Props> = ({ metric = null, errors = null, defaultValue = null }) => {
  const [checked, setChecked] = useState(metric?.sendsSubscriptions)

  return (
    <Stack gap={4}>
      <Box css={{ '--field-label-width': '140px' }}>
        <Field label="Enable Slack digest" orientation="horizontal">
          <ToggleInput
            name="sendsSubscriptions"
            defaultValue={checked}
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
        </Field>
      </Box>
      {checked && (
        <Field
          label="Slack channel"
          invalid={!!errors?.slackChannelId}
          errorText={errors?.slackChannelId?.message}
          required={checked}
          disabled={!checked}
          helperText="Choose a Slack channel above to have a Slack digest delivered on the below schedule."
        >
          <SlackChannelInput
            name="slackChannelId"
            defaultValue={metric?.slackChannelId ? [metric.slackChannelId] : []}
          />
        </Field>
      )}
      <RRuleInput
        name="subscriptionSchedule"
        disabled={!checked}
        availableFrequencies={[RRule.MONTHLY, RRule.WEEKLY, RRule.DAILY]}
        defaultValue={defaultValue}
      />
    </Stack>
  )
}

export default SubscriptionSection
