import { useCallback, useEffect, useRef } from 'react'

const useMousePosition = () => {
  const mousePositionRef = useRef({ x: 0, y: 0 })

  useEffect(() => {
    const updateMousePosition = (event: MouseEvent) => {
      mousePositionRef.current = { x: event.clientX, y: event.clientY }
    }

    window.addEventListener('mousemove', updateMousePosition)

    return () => {
      window.removeEventListener('mousemove', updateMousePosition)
    }
  }, [])

  const getLatestMousePosition = useCallback(() => mousePositionRef.current, [])

  return getLatestMousePosition
}

export default useMousePosition
