import { Link as ChakraLink, HStack, Input, Spacer, Stack } from '@chakra-ui/react'
import type { FC, FormEventHandler } from 'react'
import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { Button } from '@app/components/ui/button'
import { Field } from '@app/components/ui/field'
import { PasswordInput } from '@app/components/ui/password-input'
import { toaster } from '@app/components/ui/toaster'
import FormAlert from '@app/next/forms/formAlert'
import AuthProviders from '@app/pages/sessions/components/authProviders'
import SessionHeader from '@app/pages/sessions/components/sessionHeader'
import unpackErrors from '@app/utils/unpackErrors'
import { useUserAuthenticateMutation } from '@graphql/queries'

const SignIn: FC = () => {
  const location = useLocation()
  const from = location.state?.from?.pathname || '/'
  const [, mutation] = useUserAuthenticateMutation()
  const [errors, setErrors] = useState<ReturnType<typeof unpackErrors>>({})
  const [loading, setLoading] = useState(false)

  const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    const input = {
      email: formData.get('email') as string,
      password: formData.get('password') as string
    }

    try {
      setLoading(true)
      const response = await mutation({ input })

      if (response.error) {
        setErrors(unpackErrors([response.error]))
        return
      }

      const { redirect, errors: authErrors } = response.data.userAuthenticate

      if (authErrors?.length) {
        setErrors(unpackErrors(authErrors))
        return
      }

      toaster.create({
        title: 'Signing in',
        placement: 'bottom-end',
        type: 'success'
      })

      // don't use navigate() for this.
      window.location.href = redirect !== '/' ? redirect : from
    } catch (resp) {
      setErrors(unpackErrors([resp]))
    } finally {
      setLoading(false)
    }
  }

  return (
    <Stack gap="8">
      <SessionHeader
        title="Sign in to your account"
        // subtitle="Don't have an account?"
        // linkText="Sign up"
        // linkUrl="/sign_up"
      />

      <Stack gap="6">
        <FormAlert description={errors?.global?.message} />
        <form id="sign-in-form" onSubmit={onSubmit}>
          <Stack gap="5">
            <Field label="Email" id="email" invalid={!!errors?.email} required errorText={errors?.email?.message}>
              <Input autoComplete="email" id="email" name="email" placeholder="Enter your email" type="email" />
            </Field>
            <Field
              label="Password"
              id="password"
              invalid={!!errors?.password}
              required
              errorText={errors?.password?.message}
            >
              <PasswordInput name="password" placeholder="" autoComplete="current-password" />
            </Field>
            <HStack textStyle="sm" justify="space-between" fontWeight="medium">
              <Spacer />
              <ChakraLink asChild variant="plain">
                <Link to="/forgot_password">Forgot your password?</Link>
              </ChakraLink>
            </HStack>
            <Stack gap="4">
              <Button form="sign-in-form" loading={loading} size="md" type="submit">
                Sign in
              </Button>
              <AuthProviders text="Sign in with" />
            </Stack>
          </Stack>
        </form>
      </Stack>
    </Stack>
  )
}

export default SignIn
