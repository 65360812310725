import { Input, Stack, VisuallyHidden } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'
import { useEffect, useState } from 'react'

import { Field } from '@app/components/ui/field'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import type {
  ChakraAsyncCreatableSelectProps,
  ReactSelectAutocompleteValue
} from '@app/shared/autocomplete/chakraAutocompletes'
import LabelsAutocomplete from '@app/shared/autocomplete/labels/labelsAutocomplete'
import { useStore } from '@app/store'
import type { FormErrors, DomainObject } from '@app/types'

const valueTransformer = (labels) => JSON.stringify(labels.map((lbl) => lbl.name))

export type AutocompleteLabel = { id: string | number; name: string }

export interface Props
  extends Partial<Omit<ChakraAsyncCreatableSelectProps<ReactSelectAutocompleteValue[]>, 'defaultValue'>> {
  label?: ReactNode | string
  domainObject: DomainObject
  errors?: FormErrors
  helperText?: ReactNode
}

const LabelsInput: FC<Props> = ({ domainObject, name, label, helperText, errors = {}, ...rest }) => {
  const [labels, setLabels] = useState(domainObject?.[name] || [])
  const { user } = useStoreCurrentUser()
  const updateLabels = useStore.use.updateLabels()

  useEffect(() => {
    if (domainObject?.[name] !== labels) {
      setLabels(domainObject?.[name])
    }
  }, [domainObject, labels, name])

  const handleChange = (value) => {
    const newLabels = value.map((newLabel) => ({ id: newLabel.value, name: newLabel.label }))

    setLabels(newLabels)
    updateLabels(domainObject, newLabels)

    return null
  }

  return (
    <Field label={label} errorText={errors[name]?.message} helperText={helperText} id={name} invalid={!!errors[name]}>
      <VisuallyHidden asChild>
        <Input id={name} name={name} readOnly value={valueTransformer(labels)} />
      </VisuallyHidden>
      <Stack w="100%">
        <LabelsAutocomplete
          key={valueTransformer(labels)}
          className="nodrag"
          id={`${name}-autocomplete`}
          onChange={handleChange}
          defaultValue={labels}
          readOnly={!['admin', 'editor'].includes(user?.role)}
          chakraStyles={{
            inputContainer: (provided, _state) => ({
              ...provided,
              cursor: 'pointer'
            })
          }}
          components={{
            DropdownIndicator: () => null
          }}
          {...rest}
        />
      </Stack>
    </Field>
  )
}

export default LabelsInput
