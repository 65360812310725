import { Box } from '@chakra-ui/react'
import type { FC } from 'react'
import { useParams } from 'react-router-dom'

import useGetObject from '@app/hooks/useGetObject'
import EntityForm from '@app/pages/shared/entities/components/entityForm'

const EntityFormTab: FC = () => {
  const { nodeId } = useParams()

  const entity = useGetObject(nodeId, 'entity')

  return (
    <Box p={4} data-testid="entity-form-tab">
      <EntityForm entity={entity} />
    </Box>
  )
}

export default EntityFormTab
