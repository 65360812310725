import { useCallback } from 'react'
import { FiX } from 'react-icons/fi'

import { Button } from '@app/components/ui/button'
import { Tooltip } from '@app/components/ui/tooltip'
import { useChatCancelMutation } from '@graphql/queries'

const CancelButton = ({ chatId, ...rest }) => {
  const [, cancelChat] = useChatCancelMutation()

  const onCancel = useCallback(() => {
    if (chatId) {
      cancelChat({ input: { chatId } })
    }
  }, [chatId, cancelChat])

  return (
    <Tooltip aria-label="Cancel" content="Cancel">
      <Button me={2} colorPalette="red" onClick={onCancel} size="xs" type="button" {...rest}>
        <FiX />
      </Button>
    </Tooltip>
  )
}

export default CancelButton
