import { HStack, Input, Spacer, Stack, Separator, Text, useToken } from '@chakra-ui/react'
import type { FC } from 'react'
import { memo } from 'react'
import { useParams } from 'react-router-dom'

import { Field } from '@app/components/ui/field'
import useGetObject from '@app/hooks/useGetObject'
import ViewerFieldWrapper from '@app/next/forms/inline/support/viewerFieldWrapper'
import InlineUpdaterRoot from '@app/next/forms/inlineUpdaterRoot'
import CorrelationDetails from '@app/pages/maps/components/edgeDrawer/components/correlationDetails'
import InsightsDetails from '@app/pages/maps/components/edgeDrawer/components/insightsDetails'
import { displayNameAndType } from '@app/pages/maps/components/nodes/helpers'
import { useDrawerContext } from '@app/pages/maps/drawerContext'
import Drawer from '@app/shared/drawer/drawer'
import DrawerCloseButton from '@app/shared/drawer/drawerCloseButton'
import { useStore } from '@app/store'
import type { DomainEdge } from '@app/types'
import { COLORS } from '@app/utils/metricHelpers'

const EdgeDrawer: FC = () => {
  const { edgeId } = useParams()
  const { onDrawerClose } = useDrawerContext()
  const getNodeById = useStore.use.getNodeById()
  const [red, green, yellow] = useToken('colors', ['red.600', 'green.600', 'yellow.600'])

  const edge = useGetObject(edgeId, 'edge') satisfies DomainEdge
  const { insights, color } = edge

  const source = getNodeById(edge?.source)
  const target = getNodeById(edge?.target)

  const sourceDisplay = displayNameAndType(source.data)
  const targetDisplay = displayNameAndType(target.data)

  return (
    <Drawer onOpenChange={onDrawerClose} open>
      <Stack as="section" gap={0} overflow="auto" h="100%" id="lazyload-container">
        <Stack gap={8} p={4} separator={<Separator />}>
          <Stack>
            <HStack>
              <Text fontWeight="semibold">
                {sourceDisplay.name} -- {targetDisplay.name}
              </Text>
              <Spacer />
              <DrawerCloseButton variant="ghost" />
            </HStack>
            <ViewerFieldWrapper value={edge.insights}>
              <InlineUpdaterRoot domainObject={edge} domainObjectUpdateId="edgeId">
                <Input
                  fontWeight="bold"
                  defaultValue={insights || ''}
                  name="insights"
                  placeholder="Insight title (e.g., high confidence, low leverage)"
                />
              </InlineUpdaterRoot>
            </ViewerFieldWrapper>
            <InsightsDetails edge={edge} />
            <ViewerFieldWrapper value={edge.color}>
              <Field label="Color" orientation="horizontal">
                <InlineUpdaterRoot domainObject={edge} domainObjectUpdateId="edgeId">
                  <Input
                    w={6}
                    h={6}
                    m={0}
                    p={0}
                    defaultValue={color || COLORS.neutral.color}
                    list="presetColors"
                    name="color"
                    placeholder="Color"
                    type="color"
                  />
                </InlineUpdaterRoot>
                <datalist id="presetColors">
                  <option>{green}</option>
                  <option>{yellow}</option>
                  <option>{red}</option>
                </datalist>
              </Field>
            </ViewerFieldWrapper>
          </Stack>
          <CorrelationDetails edge={edge} />
        </Stack>
      </Stack>
    </Drawer>
  )
}

export default memo(EdgeDrawer)
