import type { FC } from 'react'
import { Children, useMemo } from 'react'

import StepConnector from './stepConnector'
import StepContext from './stepContext'

type Props = {
  activeStep: number
  children: React.ReactNode
}

const StepProvider = ({ step, activeStep, arr, i }) => {
  const value = useMemo(
    () => ({
      isActive: activeStep === i,
      isCompleted: activeStep > i,
      isLastStep: arr.length !== i + 1,
      step: i + 1
    }),
    [activeStep, i, arr]
  )

  return (
    <StepContext.Provider value={value}>
      {step}
      {arr.length !== i + 1 && <StepConnector />}
    </StepContext.Provider>
  )
}

const Steps: FC<Props> = ({ activeStep, children }) =>
  useMemo(
    () => (
      <>
        {Children.toArray(children).map((step, i, arr) => (
          <StepProvider key={(step as { key: string }).key} step={step} activeStep={activeStep} arr={arr} i={i} />
        ))}
      </>
    ),
    [activeStep, children]
  )

export default Steps
