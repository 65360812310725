import type { NativeSelect } from '@chakra-ui/react'
import type { FC } from 'react'

import { NativeSelectRoot, NativeSelectField } from '@app/components/ui/native-select'
import { IntervalEnum } from '@graphql/types'

interface Props extends NativeSelect.FieldProps {
  interval: IntervalEnum
  setInterval: (interval: IntervalEnum) => void
}

const IntervalSelect: FC<Props> = ({ interval, setInterval, ...rest }) => (
  <NativeSelectRoot data-testid="interval-select">
    <NativeSelectField
      key="interval"
      name="interval_select"
      onChange={(e) => setInterval(e.target.value as IntervalEnum)}
      value={interval}
      {...rest}
    >
      <option value={IntervalEnum.Day}>Day</option>
      <option value={IntervalEnum.Week}>Week</option>
      <option value={IntervalEnum.Month}>Month</option>
      <option value={IntervalEnum.Quarter}>Quarter</option>
      <option value={IntervalEnum.Year}>Year</option>
    </NativeSelectField>
  </NativeSelectRoot>
)

export default IntervalSelect
