import type { DomainNode } from '@app/types'

const cardMetadata = (node: DomainNode) => {
  const size = node?.metadata?.size || 'normal'
  const color = node?.metadata?.color
  const regressionScore = node?.metadata?.regression_score
  const regressionScoreType = node?.metadata?.regression_score_type

  return { size, color, regressionScore, regressionScoreType }
}

export default cardMetadata
