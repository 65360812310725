import { defer } from 'react-router-dom'

import ForgotPassword from '@app/pages/sessions/forgotPassword'
import ResetPassword from '@app/pages/sessions/resetPassword'
import SamlOrganization from '@app/pages/sessions/samlOrganization'
import SignIn from '@app/pages/sessions/signIn'
import SignUp from '@app/pages/sessions/signUp'
import SessionShell from '@app/shared/layout/shells/sessionShell'
import { loaderQuery } from '@graphql/client'
import { InvitationDocument } from '@graphql/queries'

const loadInvitationCode = async ({ params }) => {
  const invitationPromise = loaderQuery(InvitationDocument, {
    inviteCode: params.inviteCode
  })

  return defer({ invitation: invitationPromise })
}

const routes = {
  element: <SessionShell />,
  children: [
    {
      path: 'sign_in',
      element: <SignIn />
    },
    {
      path: 'sign_up',
      element: <SignUp />,
      loader: () => ({
        invitation: Promise.resolve({ data: { invitation: null } })
      })
    },
    {
      path: 'sign_up/:inviteCode',
      loader: loadInvitationCode,
      element: <SignUp />
    },
    {
      path: 'forgot_password',
      element: <ForgotPassword />
    },
    {
      path: 'reset_password/:passwordResetToken',
      element: <ResetPassword />
    },
    {
      path: 'sso',
      element: <SamlOrganization />
    },
    {
      path: 'sso/:ssoIdentifier',
      element: <SamlOrganization />
    }
  ]
}

export default routes
