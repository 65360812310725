import { createSystem, defaultConfig } from '@chakra-ui/react'

const system = createSystem(defaultConfig, {
  globalCss: {
    body: {
      colorPalette: 'gray'
    },
    option: {
      _dark: {
        bgColor: 'gray.950 !important' // these are using `inherit` by default, which causes problems in dark mode.
      }
    }
  },
  theme: {
    tokens: {
      colors: {
        gray: {
          25: { value: '#FCFBFC' },
          50: { value: '#f4f8fa' },
          950: { value: '#14151e' }
        }
      },
      fonts: {
        heading: { value: "'Inter Variable', sans-serif" },
        body: { value: "'Inter Variable', sans-serif" }
      }
    },

    semanticTokens: {
      radii: {
        l1: { value: '0.125rem' },
        l2: { value: '0.25rem' },
        l3: { value: '0.375rem' }
      },
      colors: {
        'bg.canvas': {
          value: {
            base: '{colors.gray.25}',
            _light: '{colors.gray.25}',
            _dark: '{colors.gray.950}'
          }
        }
      }
    }
  }
})

export default system
