import { Box, Flex } from '@chakra-ui/react'
import { LinkNode } from '@lexical/link'
import { ListItemNode, ListNode } from '@lexical/list'
import { $convertFromMarkdownString, $convertToMarkdownString, TRANSFORMERS } from '@lexical/markdown'
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin'
import type { InitialConfigType } from '@lexical/react/LexicalComposer'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { EditorRefPlugin } from '@lexical/react/LexicalEditorRefPlugin'
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { TablePlugin } from '@lexical/react/LexicalTablePlugin'
import { QuoteNode, HeadingNode } from '@lexical/rich-text'
import { TableCellNode, TableRowNode, TableNode } from '@lexical/table'
import type { LexicalEditor } from 'lexical'
import debounce from 'lodash/debounce'
import { forwardRef, useCallback, useRef, useState } from 'react'

import FloatingTextFormatToolbarPlugin from './floatingTextFormatToolbarPlugin'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import Placeholder from '@app/next/forms/inline/placeholder'
import { useStore } from '@app/store'
import type { DomainObject } from '@app/types'
import { Sentry } from '@app/utils/sentry'
import type { NodeObjectInput } from '@graphql/types'

const theme = {}

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error: Error) {
  Sentry.captureException(error)

  // errors here are insidious enough that we want to be proactively warned
  if (import.meta.env.PUBLIC_ENV === 'development') {
    console?.error(error) // eslint-disable-line no-console
  }
}

// const OnDomainObjectChangePlugin = ({ domainObjectValue }) => {
//   const [editor] = useLexicalComposerContext()
//
//   useEffect(() => {
//     if (!domainObjectValue) {
//       editor.dispatchCommand(CLEAR_EDITOR_COMMAND, null)
//     } else {
//       console.log('domainObjectValue - ', domainObjectValue)
//
//       editor.update(() => {
//         $convertFromMarkdownString(domainObjectValue, TEXT_MATCH_TRANSFORMERS)
//       })
//     }
//   }, [domainObjectValue, editor])
//
//   return null
// }

interface Props {
  domainObject: DomainObject
  name: string
  placeholder?: string
  domainObjectUpdateId?: string
}

const RichTextInput: ReturnType<typeof forwardRef<LexicalEditor, Props>> = forwardRef(
  ({ domainObject, name, placeholder = '', domainObjectUpdateId = 'id' }, editorRef) => {
    const domainObjectValue = domainObject[name]
    const updateObject = useStore.use.updateObject()
    const { user } = useStoreCurrentUser()
    const editor = ['admin', 'editor'].includes(user?.role)
    const defaultRef = useRef<LexicalEditor>(null)
    const ref = editorRef || defaultRef

    const initialConfig: InitialConfigType = {
      editorState: () => $convertFromMarkdownString(domainObjectValue || '', TRANSFORMERS),
      namespace: `DoubleLoop-${domainObject.classType}-${name}-${Math.random()}`,
      editable: editor,
      theme,
      onError,
      nodes: [HeadingNode, LinkNode, ListNode, ListItemNode, TableNode, TableCellNode, TableRowNode, QuoteNode]
    }

    const placeholderComponent = <Placeholder>{placeholder}</Placeholder>
    const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null)

    const onRef = (newFloatingAnchorElem: HTMLDivElement) => {
      if (newFloatingAnchorElem !== null) {
        setFloatingAnchorElem(newFloatingAnchorElem)
      }
    }

    const { classType } = domainObject
    const idVal = domainObject[domainObjectUpdateId]

    const onChange = useCallback(
      (editorState) => {
        editorState.read(() => {
          const markdown = $convertToMarkdownString(TRANSFORMERS)

          const updatedObject = {
            [classType]: {
              id: idVal,
              [name]: markdown
            }
          }

          updateObject(updatedObject as NodeObjectInput)
        })
      },
      [classType, idVal, name, updateObject]
    )

    const debounced = useCallback(debounce(onChange, 500), [onChange]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Box pos="relative">
        <LexicalComposer initialConfig={initialConfig} key={domainObject.id}>
          <RichTextPlugin
            contentEditable={
              <Flex className="editor-scroller nodrag" ref={onRef} pos="relative">
                <Box className="editor markdown-body" pos="relative" flex="auto">
                  <ContentEditable style={{ outline: 'none' }} />
                </Box>
              </Flex>
            }
            placeholder={placeholderComponent}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <OnChangePlugin onChange={debounced} />
          <ListPlugin />
          <LinkPlugin />
          <CheckListPlugin />
          <TablePlugin />
          <FloatingTextFormatToolbarPlugin anchorElem={floatingAnchorElem} />
          <EditorRefPlugin editorRef={ref} />
        </LexicalComposer>
      </Box>
    )
  }
)

RichTextInput.displayName = 'RichTextInput'

export default RichTextInput
