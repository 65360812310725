import { Group, Separator, Input, type Dialog, VisuallyHidden } from '@chakra-ui/react'
import debounce from 'lodash/debounce'
import omitBy from 'lodash/omitBy'
import { useCallback, useRef, useState } from 'react'
import type { FormEventHandler, ChangeEventHandler, FC } from 'react'

import SourceConfiguration from './sourceConfiguration'
import SourceNameSelect from './sourceNameSelect'

import { Button } from '@app/components/ui/button'
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle
} from '@app/components/ui/dialog'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import { useStore } from '@app/store'
import type { JSONValue } from '@app/types'
import { CredentialCreate } from '@graphql/documents/credential.graphql'
import type { Credential } from '@graphql/types'

type Props = Partial<Dialog.RootProps> & {
  credential?: Credential | null
  open: boolean
}

const MetricIntegrationModal: FC<Props> = ({ credential, onOpenChange, ...props }) => {
  const [step, setStep] = useState(credential ? 1 : 0)
  const [sourceName, setSourceName] = useState(credential?.sourceName || '')
  const formRef = useRef<HTMLFormElement>(null)
  const [isValid, setIsValid] = useState(!!credential)
  const [loading, setLoading] = useState(false)
  const actionMutation = useStore.use.actionMutation()
  const updateObject = useStore.use.updateObject()

  const { user } = useStoreCurrentUser()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(
    debounce<ChangeEventHandler<HTMLFormElement>>(() => {
      setIsValid(formRef.current?.checkValidity())
    }, 50),
    []
  )

  if (!user) {
    return null
  }

  const nextStep = () => {
    setStep(step + 1)
  }

  const prevStep = () => {
    setStep(step - 1)
  }

  const onClose = () => {
    onOpenChange({ open: false })

    if (!credential) {
      setIsValid(false)
      setStep(0)
      setSourceName('')
    }
  }

  const closeAndReset = () => {
    onClose()
    formRef.current?.reset()
  }

  const stepParts = () => {
    switch (step) {
      case 0:
        return {
          body: <SourceNameSelect value={sourceName} onClick={setSourceName} />,
          footer: (
            <Group>
              <Button disabled variant="outline">
                Prev
              </Button>
              <Button disabled={!sourceName} onClick={nextStep}>
                Next
              </Button>
            </Group>
          )
        }
      case 1:
        return {
          body: (
            <SourceConfiguration
              credential={credential}
              sourceName={sourceName}
              formRef={formRef}
              isValid={isValid}
              onChange={onChange}
            />
          ),
          footer: (
            <Group>
              {!credential && (
                <Button onClick={prevStep} type="button" variant="outline">
                  Prev
                </Button>
              )}
              <Button disabled={!isValid} loading={loading} type="submit">
                Save
              </Button>
            </Group>
          )
        }
      default:
        return {
          body: null,
          footer: (
            <Button onClick={closeAndReset} type="button">
              Close
            </Button>
          )
        }
    }
  }

  const { body, footer } = stepParts()
  const header = credential ? 'Edit metric integration' : 'Add metric integration'

  const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    // This stopPropagation is necessary to prevent the other form on the page, MetricSourceCreate from submitting.
    e.stopPropagation()

    setLoading(true)

    const formData = new FormData(e.currentTarget)
    let input: Record<string, JSONValue> = {}

    // Fix a persnickety type issue so TS doesn't complain about `File`s
    for (const entry of formData.entries()) {
      const [key, value] = entry
      if (!(value instanceof File)) {
        input[key] = value
      }
    }

    // avoid overwriting any secrets (which we don't send to the frontend) with blank values
    const secretFields = input.secretFields as string
    delete input.secretFields
    if (secretFields) {
      const secrets = secretFields.split(',')
      input = omitBy(input, (value, key) => !value && secrets.includes(key))
    }

    try {
      if (credential) {
        await updateObject({ credential: { id: credential.id, ...input } })
      } else {
        await actionMutation(CredentialCreate, input)
      }
    } finally {
      setLoading(false)
      onClose()
    }
  }

  return (
    <DialogRoot onOpenChange={closeAndReset} placement="center" {...props}>
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{header}</DialogTitle>
        </DialogHeader>
        <DialogCloseTrigger />
        <Separator />
        <form id="metric-integration-form" onSubmit={onSubmit} onChange={onChange} ref={formRef}>
          <VisuallyHidden asChild>
            <Input defaultValue={sourceName} name="sourceName" />
          </VisuallyHidden>
          {credential && (
            <VisuallyHidden asChild>
              <Input defaultValue={credential.id} name="id" />
            </VisuallyHidden>
          )}
          <DialogBody>{body}</DialogBody>
          <DialogFooter>{footer}</DialogFooter>
        </form>
      </DialogContent>
    </DialogRoot>
  )
}

export default MetricIntegrationModal
