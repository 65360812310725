import { useCallback, useMemo } from 'react'

import useGetNodeDomainObjects from '@app/hooks/useGetNodeDomainObjects'
import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import useGetCardTypeOptions from '@app/next/changelog/useGetCardTypeOptions'
import type { DomainNode } from '@app/types'

const allowedNodeTypes = new Set(['metric', 'basicCard', 'entity'])

const useGetContainersSelectOptions = (strategyId) => {
  const propertiesFilter = useCallback(
    (n: DomainNode) => {
      const equalStrategyId = strategyId ? n.strategyId === strategyId : true
      return equalStrategyId && allowedNodeTypes.has(n.type)
    },
    [strategyId]
  )

  const nodes = useGetObjectsByProperties('node', propertiesFilter)
  const domainObjects = useGetNodeDomainObjects(nodes)
  const unsortedCardTypeOptions = useGetCardTypeOptions(domainObjects)
  const options = useMemo(
    () => unsortedCardTypeOptions.map((o, idx) => ({ ...o, id: nodes[idx].nodeId })),
    [unsortedCardTypeOptions, nodes]
  )

  return options
}

export default useGetContainersSelectOptions
