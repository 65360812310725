import type { FC } from 'react'
import { useCallback } from 'react'

import type { Props as ToggleInputProps } from '@app/next/forms/toggleInput'
import ToggleInput from '@app/next/forms/toggleInput'
import { useStore } from '@app/store'
import type { DomainObject } from '@app/types'
import type { NodeObjectInput } from '@graphql/types'

type Props = ToggleInputProps & {
  domainObject: Pick<DomainObject, 'id' | 'classType'> | null
}

const ShowFieldToggle: FC<Props> = ({ domainObject, ...props }) => {
  const { name } = props
  const updateObject = useStore.use.updateObject()

  const onChange: ToggleInputProps['onChange'] = useCallback(
    (newValue) => {
      if (!domainObject) {
        return Promise.resolve()
      }

      const updatedObject = {
        [domainObject.classType]: {
          id: domainObject.id,
          [name]: newValue
        }
      }

      return updateObject(updatedObject as NodeObjectInput)
    },
    [domainObject, name, updateObject]
  )

  return <ToggleInput onChange={onChange} {...props} />
}

export default ShowFieldToggle
