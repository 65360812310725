import { Box, HStack, Center, Stack, Text, useCheckboxGroup } from '@chakra-ui/react'
import truncate from 'lodash/truncate'
import type { FC } from 'react'
import { memo, useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Checkbox } from '@app/components/ui/checkbox'
import { EmptyState } from '@app/components/ui/empty-state'
import useGetObjectPage from '@app/hooks/useGetObjectPage'
import MapFilterSelectInput from '@app/next/forms/mapFilterSelectInput'
import WorkListActions from '@app/pages/entities/components/workListActions'
import { ListFilterContextProvider } from '@app/pages/shared/listFilterContext'
import ListLabels from '@app/pages/shared/listLabels'
import MapsCell from '@app/pages/shared/mapsCell'
import MultiOps from '@app/pages/shared/multiOps'
import SearchRow from '@app/pages/shared/searchRow'
import SelectAllCheckboxHeader from '@app/pages/shared/selectAllCheckboxHeader'
import useGenerateSelectableTableRowFormatter from '@app/pages/shared/useGenerateSelectableTableRowFormatter'
import { PageHeader, PageStack } from '@app/shared/layout'
import Pagination from '@app/shared/pagination'
import Table from '@app/shared/table'
import SortHeader from '@app/shared/table/components/sortHeader'
import { SortHeaderContextProvider } from '@app/shared/table/contexts/sortHeaderContext'
import type { Column } from '@app/shared/table/types'
import withAwait from '@app/shared/withAwait'
import type { DomainEntity } from '@app/types'
import { toSentenceCase } from '@app/utils/stringHelpers'
import type { Entity } from '@graphql/types'

type MakeColumns = (args: {
  getCheckboxProps: ReturnType<typeof useCheckboxGroup>['getItemProps']
  selected: ReturnType<typeof useCheckboxGroup>['value']
  setSelected: ReturnType<typeof useCheckboxGroup>['setValue']
}) => Column<Entity>[]

const WorkName: FC<{ data: Entity }> = ({ data }) => (
  <Stack>
    <Text minW="xs" fontSize="md" whiteSpace="normal" wordBreak="break-all" title={data.name}>
      {truncate(data.name, { length: 50 })}
    </Text>
    <HStack>{data?.containeeCount ? <Text>{data.containeeCount} work items</Text> : null}</HStack>
    <MapsCell data={data} />
    <ListLabels labels={data?.labels} />
  </Stack>
)

const WorkType: FC<{ data: Entity }> = ({ data }) => <Text>{toSentenceCase(data?.foreignType || '') || 'none'}</Text>

const WorkSourceName: FC<{ data: Entity }> = ({ data }) => <Text>{data.sourceName}</Text>

const makeColumns: MakeColumns = ({ getCheckboxProps, selected, setSelected }) => [
  {
    header: <SelectAllCheckboxHeader collectionType="entity" selected={selected} setSelected={setSelected} />,
    width: '5%',
    key: 'checkbox',
    cell: ({ data: work }) => (
      <Center>
        <Checkbox {...getCheckboxProps({ value: work.id })} />
      </Center>
    )
  },
  {
    header: <SortHeader field="name" title="Name" emptyState />,
    key: 'name',
    width: '40%',
    cell: WorkName
  },
  {
    header: <SortHeader field="foreignType" title="Type" emptyState />,
    key: 'foreignType',
    cell: WorkType
  },
  {
    header: <SortHeader field="sourceName" title="Source" emptyState />,
    key: 'sourceName',
    cell: WorkSourceName
  },
  {
    header: '',
    cell: WorkListActions
  }
]

const awaited = (Component: FC) => withAwait(Component, 'entities')

const Empty = ({ strategyId }) => (
  <Box p={2}>
    <SearchRow
      leftChild={<MapFilterSelectInput field="strategyId" defaultValue={strategyId} />}
      page={0}
      limitValue={0}
      totalCount={0}
    />
    <EmptyState title="No work items" description="Add some work items on your maps to see them here." />
  </Box>
)

const AwaitedEmpty = awaited(Empty)

const Populated = ({ strategyId, collection, metadata }) => {
  const { page, totalCount } = metadata
  const {
    value: selected,
    getItemProps: getCheckboxProps,
    setValue: setSelected
  } = useCheckboxGroup({
    defaultValue: []
  })
  const columns = useMemo(
    () => makeColumns({ getCheckboxProps, selected, setSelected }),
    [getCheckboxProps, selected, setSelected]
  )
  const onBulkDelete = useCallback(() => {
    setSelected([])
  }, [setSelected])
  const baseRowProps = useGenerateSelectableTableRowFormatter<DomainEntity>('entity', selected, setSelected)

  const rowFormatter = useCallback((entity: Entity) => baseRowProps(entity), [baseRowProps])

  return (
    <Stack gap="0">
      <SearchRow
        leftChild={<MapFilterSelectInput field="strategyId" defaultValue={strategyId} />}
        leftSummarySibling={<MultiOps type="entity" selected={selected} fontSize="sm" onDelete={onBulkDelete} />}
        page={page}
        limitValue={collection.length}
        totalCount={totalCount}
      />
      <Box overflow="auto">
        <SortHeaderContextProvider defaultState={{ name: 'asc' }}>
          <Table<(typeof collection)[0]> columns={columns} data={collection} rowFormatter={rowFormatter} />
        </SortHeaderContextProvider>
        <Pagination px={6} py={4} {...{ ...metadata }} scrollContainer="main" />
      </Box>
    </Stack>
  )
}

const AwaitedPopulated = awaited(Populated)

const List: FC = () => {
  const { collection, metadata } = useGetObjectPage('entity')
  const [searchParams] = useSearchParams()
  const strategyId = searchParams.get('strategyId')
  const subtitle =
    'Your work includes stories, issues, tasks, or projects that your import from your project management tools and other work that you add manually in DoubleLoop.'

  return (
    <ListFilterContextProvider searchField="filter">
      <PageStack>
        <PageHeader title="Work" subtitle={subtitle} />
        <Box>
          {collection.length ? (
            <AwaitedPopulated strategyId={strategyId} collection={collection} metadata={metadata} />
          ) : (
            <AwaitedEmpty strategyId={strategyId} />
          )}
        </Box>
      </PageStack>
    </ListFilterContextProvider>
  )
}

export default memo(List)
