import { Clipboard as ChakraClipboard, IconButton, Text } from '@chakra-ui/react'
import type { FC } from 'react'

import { ClipboardRoot } from '@app/components/ui/clipboard'
import { Tooltip } from '@app/components/ui/tooltip'
import type { DomainNode } from '@app/types'

interface Props {
  node: DomainNode
}

const IDButton: FC<Props> = ({ node }) => (
  <Tooltip content="Copy metric id">
    <span>
      <ClipboardRoot value={node?.data?.slug || ''}>
        <ChakraClipboard.Trigger asChild>
          <IconButton borderRadius="none" aria-label="Copy metric id" colorPalette="gray" size="sm" variant="outline">
            <Text pl="2px" fontSize="16px" fontWeight="300">
              ID
            </Text>
          </IconButton>
        </ChakraClipboard.Trigger>
      </ClipboardRoot>
    </span>
  </Tooltip>
)

export default IDButton
