import { Box } from '@chakra-ui/react'

import MetricForm from './components/metricForm'

import { PageHeader, PageStack } from '@app/shared/layout'

const Create = () => (
  <PageStack>
    <PageHeader title="Create a metric" />
    <Box p={4}>
      <MetricForm />
    </Box>
  </PageStack>
)

export default Create
