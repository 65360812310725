import { useReactFlow } from '@xyflow/react'
import type { MutableRefObject } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import type { Options } from 'react-hotkeys-hook'

import useDuplicateNodes from '@app/hooks/useDuplicateNodes'
import useGetSelectedAndCollapsedNodes from '@app/hooks/useGetSelectedAndCollapsedNodes'
import useMapNodeCreators from '@app/hooks/useMapNodeCreators'
import useMousePosition from '@app/hooks/useMousePosition'
import useImages from '@app/pages/maps/useImages'
import { isMimeTypeImage, nodesToBlob, nodesFromBlob } from '@app/utils/copyPasteHelpers'

type UseMapHotkeys = (strategyId: string, imageInputRef: MutableRefObject<HTMLInputElement>, options?: Options) => void

const useMapHotkeys: UseMapHotkeys = (strategyId, imageInputRef, options = {}) => {
  const { createNote, createSection, createMetric, createWork, createBasicCard, createCommentThread } =
    useMapNodeCreators(strategyId)
  const selectedNodes = useGetSelectedAndCollapsedNodes(strategyId)
  const { duplicate } = useDuplicateNodes(selectedNodes)
  const { createImageNodes } = useImages(strategyId)
  const { screenToFlowPosition } = useReactFlow()
  const getLatestMousePosition = useMousePosition()

  useHotkeys('w', () => createWork(), options)
  useHotkeys('m', () => createMetric(), options)
  useHotkeys('c', () => createBasicCard(), options)
  useHotkeys('t', () => createCommentThread(), options)

  useHotkeys(
    's',
    (e) => {
      // Prevents the `s` key from being typed into the name bar
      e.preventDefault()

      createSection()
    },
    options
  )
  useHotkeys('n', () => createNote(), options)
  useHotkeys('i', () => imageInputRef.current.click(), options)

  useHotkeys(
    ['ctrl+d', 'meta+d'],
    (e) => {
      e.preventDefault() // Prevent the browser from bookmarking the page

      return duplicate()
    },
    options
  )

  useHotkeys(
    ['mod+c', 'mod+v'],
    async (e, handler) => {
      let blob: BlobPart
      let copiedItem: ClipboardItem
      const selectionLength = window.getSelection().toString().length

      switch (handler.keys.join('')) {
        case 'c':
          if (selectionLength === 0 && selectedNodes && selectedNodes.length > 0) {
            // This could be much easier if Safari's Async Clipboard API supported writing blobs
            // with custom mimetypes.
            blob = nodesToBlob(selectedNodes)
            copiedItem = new ClipboardItem({ [blob.type]: blob })

            await navigator.clipboard.write([copiedItem])
          }

          break
        case 'v':
          try {
            const clipboardItems = await navigator.clipboard.read()
            const mousePosition = getLatestMousePosition()
            const pastePosition = screenToFlowPosition(mousePosition)

            clipboardItems.forEach((clipboardItem) => {
              clipboardItem.types.forEach(async (type) => {
                blob = await clipboardItem.getType(type)

                // If the clipboard item is an image, create an image node
                if (isMimeTypeImage(type)) {
                  const file = new File([blob], 'clipboard-image', { type })
                  createImageNodes(file)
                  // If the clipboard has text, see if we can parse it
                } else if (type === 'text/plain') {
                  const text = await blob.text()

                  // If the text includes a DLN, parse it as nodes
                  if (text.includes('DLN:')) {
                    const { nodes: clipboardNodes, boundingBox } = await nodesFromBlob(blob)

                    const variables = {
                      offsetX: pastePosition.x - boundingBox.x,
                      offsetY: pastePosition.y - boundingBox.y
                    }

                    if (clipboardNodes[0].strategyId === strategyId) {
                      await duplicate(clipboardNodes, null, { variables })
                    } else {
                      await duplicate(clipboardNodes, strategyId, { variables })
                    }
                  }
                }
              })
            })
          } catch (_err) {
            // console.error(err.name, err.message)
          }

          return true
        default:
        // None shall pass
      }

      return false
    },
    options
  )
}

export default useMapHotkeys
