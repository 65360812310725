import { Box, Card, HStack, IconButton, Link as ChakraLink, Spacer, Spinner } from '@chakra-ui/react'
import type { Identifier } from 'dnd-core'
import type { FC, MutableRefObject } from 'react'
import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { PiDotsThree } from 'react-icons/pi'
import { Link } from 'react-router-dom'

import { MenuContent, MenuItem, MenuRoot, MenuTrigger } from '@app/components/ui/menu'
import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import { ItemTypes, cardHeight } from '@app/pages/aiDashboard/components/constants'
import MessageContent from '@app/pages/maps/components/aiChat/components/messageContent'
import RecurringReportConfigurationDelete from '@app/pages/reports/recurring/components/recurringReportConfigurationDelete'
import RecurringReportConfigurationRunButton from '@app/pages/reports/recurring/components/recurringReportConfigurationRunButton'
import { useStore } from '@app/store'
import type { RecurringReportConfigurationsByServiceQuery } from '@graphql/queries'
import { useRecurringReportConfigurationUpdateMutation } from '@graphql/queries'
import type { Report } from '@graphql/types'

export type DashboardItem =
  RecurringReportConfigurationsByServiceQuery['recurringReportConfigurations']['collection'][0]

interface DashboardCardContentProps {
  report?: Pick<Report, 'id' | 'summary'>
}

interface DragItem {
  index: number
  id: string
  type: string
}

interface Props {
  recurringReportConfiguration: DashboardItem
  index: number
}

const DashboardCardContent: FC<DashboardCardContentProps> = ({ report }) => {
  if (report && report.summary) {
    return <MessageContent message={{ id: report.id, content: report.summary }} />
  }

  return (
    <Box h="full" w="full" display="flex" alignItems="center" justifyContent="center">
      <Spinner size="xl" />
    </Box>
  )
}

const DashboardCard: FC<Props> = ({ recurringReportConfiguration, index }) => {
  const reports =
    useGetObjectsByProperties('report', {
      strategyId: recurringReportConfiguration.strategyId,
      recurringReportConfigurationId: recurringReportConfiguration.id
    }) || []

  const sortedReports = reports.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
  const report = sortedReports[0]

  const ref: MutableRefObject<HTMLDivElement> = useRef()
  const updateObjects = useStore.use.updateObjects()
  const [, updateRecurringReport] = useRecurringReportConfigurationUpdateMutation()

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },
    drop(item, _monitor) {
      updateRecurringReport({
        input: {
          recurringReportConfigurationId: recurringReportConfiguration.id,
          name: recurringReportConfiguration.name,
          service: recurringReportConfiguration.service,
          schedule: recurringReportConfiguration.schedule,
          position: item.index
        }
      }).then(() => {})

      return undefined
    },
    hover(item: DragItem, monitor) {
      const dragIndex = item.index
      const hoverIndex = index

      if (!ref.current) {
        return
      }

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Time to actually perform the action
      const configuration1 = {
        recurringReportConfiguration: { id: recurringReportConfiguration.id, position: dragIndex }
      }

      const configuration2 = {
        recurringReportConfiguration: { id: monitor.getItem().id, position: hoverIndex }
      }

      updateObjects([configuration1, configuration2], true).then(() => {})

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    }
  })

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => ({ id: recurringReportConfiguration.id, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  const gradientProps = report
    ? {
        bgGradient: 'to-b',
        gradientFrom: 'rgba(139,167,32,0)',
        gradientTo: 'bg.panel'
      }
    : {}

  return (
    <Card.Root
      h={cardHeight}
      variant="elevated"
      _hover={{ cursor: 'grab' }}
      _grabbed={{ cursor: 'grabbing' }}
      ref={ref}
      opacity={opacity}
      data-handler-id={handlerId}
    >
      <Card.Header>
        <HStack>
          <Card.Title>{recurringReportConfiguration.name}</Card.Title>
          <Spacer />
          <MenuRoot>
            <MenuTrigger asChild>
              <IconButton variant="ghost">
                <PiDotsThree />
              </IconButton>
            </MenuTrigger>
            <MenuContent>
              <RecurringReportConfigurationRunButton
                recurringReportConfigurationId={recurringReportConfiguration?.id}
              />
              <MenuItem value="edit" asChild>
                <ChakraLink asChild>
                  <Link to={`reports/recurring/${recurringReportConfiguration.id}/edit`}>Edit</Link>
                </ChakraLink>
              </MenuItem>
              <MenuItem value="delete_configuration">
                <RecurringReportConfigurationDelete recurringReportConfigurationId={recurringReportConfiguration?.id} />
              </MenuItem>
            </MenuContent>
          </MenuRoot>
        </HStack>
      </Card.Header>
      <Card.Body
        overflow="hidden"
        pt={1}
        _after={{
          content: '""',
          position: 'absolute',
          left: 0,
          right: 0,
          height: '25%',
          bottom: '48px',
          pointerEvents: 'none',
          ...gradientProps
        }}
      >
        <DashboardCardContent report={report} />
      </Card.Body>
      <Card.Footer alignSelf="center">
        {report && (
          <ChakraLink fontWeight="semibold" textAlign="center" color="blue.500" asChild focusRingStyle="none">
            <Link to={recurringReportConfiguration.id}>Expand...</Link>
          </ChakraLink>
        )}
      </Card.Footer>
    </Card.Root>
  )
}

export default DashboardCard
