import type { XYPosition } from '@xyflow/react'
import { useReactFlow, useStoreApi } from '@xyflow/react'
import throttle from 'lodash/throttle'
import type { FC, MutableRefObject } from 'react'
import { useMemo, useEffect } from 'react'

import Cursor from './cursor'
import useBoundingClientRectRef from './useBoundingClientRectRef'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import { useUserPresencesContext } from '@app/pages/maps/userPresencesContext'
import type { DomainEdge, DomainNode } from '@app/types'

// adapted from https://liveblocks.io/examples/live-cursors-advanced/nextjs

type Props = {
  cursorPanel: MutableRefObject<HTMLElement | null>
}

type Unproject = (cursor: XYPosition) => XYPosition

// cribbed from @react-flow/core/src/utils/graph.ts
const LiveCursors: FC<Props> = ({ cursorPanel }) => {
  const { user: currentUser } = useStoreCurrentUser()
  const { screenToFlowPosition } = useReactFlow<DomainNode, DomainEdge>()

  const storeApi = useStoreApi<DomainNode, DomainEdge>()

  const unproject: Unproject = ({ x, y }) => {
    const { transform } = storeApi.getState()
    const [tx, ty, tScale] = transform

    return {
      x: x * tScale + tx,
      y: y * tScale + ty
    }
  }

  const { presences, channel } = useUserPresencesContext()

  const others = useMemo(
    () => presences.filter((presence) => presence?.position && presence?.user?.id !== currentUser?.id),
    [presences, currentUser]
  )
  const rectRef = useBoundingClientRectRef(cursorPanel)

  useEffect(() => {
    if (!(cursorPanel?.current instanceof HTMLElement)) {
      return null
    }

    // If cursorPanel, add live cursor listeners
    const updateCursor = throttle((event: PointerEvent) => {
      if (!cursorPanel?.current) {
        return
      }

      const x = event.clientX
      const y = event.clientY

      const cursor = screenToFlowPosition({
        x: Math.round(x),
        y: Math.round(y)
      })

      channel.whisper({
        action: 'updatePosition',
        userId: currentUser?.id,
        position: {
          x: cursor.x,
          y: cursor.y
        }
      })
    }, 100)

    if (!currentUser) {
      return null
    }

    const removeCursor = () => {
      channel.whisper({
        action: 'updatePosition',
        userId: currentUser?.id,
        position: null
      })
    }

    const currentRef = cursorPanel.current
    currentRef.addEventListener('pointermove', updateCursor)
    currentRef.addEventListener('pointerleave', removeCursor)

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('pointermove', updateCursor)
        currentRef.removeEventListener('pointerleave', removeCursor)
      }
    }
  }, [rectRef, cursorPanel, screenToFlowPosition, currentUser, channel])

  return (
    <>
      {others.map((other) => {
        if (!other.position) {
          return null
        }

        // note that flowToScreenPosition *does not work* here
        const otherCursor = unproject(other.position)

        return <Cursor key={other.user.id} position={otherCursor} name={other.user.name} />
      })}
    </>
  )
}

export default LiveCursors
