import { Box, Center, Link as ChakraLink, Stack, useCheckboxGroup } from '@chakra-ui/react'
import type { FC } from 'react'
import { memo, useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'

import StrategyListActions from './components/strategyListActions'
import StrategyName from './components/strategyName'

import { Button } from '@app/components/ui/button'
import { Checkbox } from '@app/components/ui/checkbox'
import { EmptyState } from '@app/components/ui/empty-state'
import useGetObjectPage from '@app/hooks/useGetObjectPage'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import CreateMapButton from '@app/pages/maps/components/createMapButton'
import { ListFilterContextProvider } from '@app/pages/shared/listFilterContext'
import MultiOps from '@app/pages/shared/multiOps'
import SearchRow from '@app/pages/shared/searchRow'
import SelectAllCheckboxHeader from '@app/pages/shared/selectAllCheckboxHeader'
import useGenerateSelectableTableRowFormatter from '@app/pages/shared/useGenerateSelectableTableRowFormatter'
import Can from '@app/shared/authorization/can'
import { AppShell, PageHeader, PageStack } from '@app/shared/layout'
import SuspenseLoader from '@app/shared/loaders/suspenseLoader'
import Pagination from '@app/shared/pagination'
import Table from '@app/shared/table'
import SortHeader from '@app/shared/table/components/sortHeader'
import { SortHeaderContextProvider } from '@app/shared/table/contexts/sortHeaderContext'
import type { Column } from '@app/shared/table/types'
import withAwait from '@app/shared/withAwait'
import type { DomainStrategy } from '@app/types'
import { Routes } from '@app/utils/routeHelpers'

type Strategy = DomainStrategy

type MakeColumns = (args: {
  getCheckboxProps: ReturnType<typeof useCheckboxGroup>['getItemProps']
  selected: ReturnType<typeof useCheckboxGroup>['value']
  setSelected: ReturnType<typeof useCheckboxGroup>['setValue']
  formatDateTimeInUserTimeZone: ReturnType<typeof useStoreCurrentUser>['formatDateTimeInUserTimeZone']
}) => Column<Strategy>[]

const makeColumns: MakeColumns = ({ getCheckboxProps, selected, setSelected, formatDateTimeInUserTimeZone }) => [
  {
    header: <SelectAllCheckboxHeader collectionType="strategy" selected={selected} setSelected={setSelected} />,
    width: '5%',
    key: 'checkbox',
    cell: ({ data: strategy }) => (
      <Center>
        <Checkbox {...getCheckboxProps({ value: strategy.id })} />
      </Center>
    )
  },
  {
    header: <SortHeader field="name" title="Name" emptyState={false} />,
    key: 'name',
    width: '40%',
    cell: StrategyName
  },
  {
    header: <SortHeader field="createdAt" title="Created at" emptyState={false} />,
    key: 'createdAt',
    cell: ({ data }: { data: DomainStrategy }) => formatDateTimeInUserTimeZone(data.createdAt, 'L/d/yyyy')
  },
  {
    header: '',
    cell: function StrategyActions({ data: strategy }) {
      return <StrategyListActions strategy={strategy} />
    }
  }
]

const awaited = (Component: FC) =>
  withAwait(
    Component,
    'strategies',
    <Box>
      <SuspenseLoader />
    </Box>
  )

const Populated = ({ collection, metadata }) => {
  const { page, limitValue, totalCount } = metadata
  const {
    value: selected,
    getItemProps: getCheckboxProps, // TODO: This might not be correct, start here for bugs
    setValue: setSelected
  } = useCheckboxGroup({
    defaultValue: []
  })
  const { formatDateTimeInUserTimeZone } = useStoreCurrentUser()

  const columns = useMemo(
    () => makeColumns({ getCheckboxProps, selected, setSelected, formatDateTimeInUserTimeZone }),
    [formatDateTimeInUserTimeZone, getCheckboxProps, selected, setSelected]
  )
  const onBulkDelete = useCallback(() => {
    setSelected([])
  }, [setSelected])
  const rowFormatter = useGenerateSelectableTableRowFormatter<DomainStrategy>('strategy', selected, setSelected)

  return (
    <Stack gap="0">
      <SearchRow
        leftSummarySibling={<MultiOps type="strategy" selected={selected} fontSize="sm" onDelete={onBulkDelete} />}
        page={page}
        limitValue={limitValue}
        totalCount={totalCount}
      />
      <Box overflow="auto">
        <SortHeaderContextProvider defaultState={{ name: 'asc' }}>
          <Table columns={columns} data={collection} rowFormatter={rowFormatter} />
        </SortHeaderContextProvider>
        <Pagination px={6} py={4} {...{ ...metadata }} scrollContainer="main" />
      </Box>
    </Stack>
  )
}

const AwaitedPopulated = awaited(Populated)

const Empty = () => (
  <Box px={2}>
    <SearchRow page={0} limitValue={0} totalCount={0} />
    <EmptyState title="No maps" description="Create a map from a playbook to see it here.">
      <Button asChild size="sm">
        <Link to="/playbooks">Check out our Playbooks</Link>
      </Button>
    </EmptyState>
  </Box>
)

const AwaitedEmpty = awaited(Empty)

const List = () => {
  const { collection, metadata } = useGetObjectPage('strategy')
  const subtitle = (
    <>
      Build separate strategy maps for each team or initiative.{' '}
      <ChakraLink color="link" href={Routes.docsStrategyConcepts} rel="noopener noreferrer" target="_blank">
        Learn more.
      </ChakraLink>
    </>
  )

  return (
    <AppShell>
      <ListFilterContextProvider searchField="filter">
        <PageStack>
          <PageHeader title="Maps" subtitle={subtitle}>
            <Can I="create" a="strategy">
              <CreateMapButton />
            </Can>
          </PageHeader>
          <Box>
            {collection.length ? <AwaitedPopulated collection={collection} metadata={metadata} /> : <AwaitedEmpty />}
          </Box>
        </PageStack>
      </ListFilterContextProvider>
    </AppShell>
  )
}

export default memo(List)
