import { useNavigate, useParams } from 'react-router-dom'

import type { DashboardItem } from './index'

import {
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogHeader,
  DialogRoot,
  DialogTitle
} from '@app/components/ui/dialog'
import useGetObject from '@app/hooks/useGetObject'
import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import MessageContent from '@app/pages/maps/components/aiChat/components/messageContent'

const Show = () => {
  const { recurringReportId, strategyId } = useParams()
  const navigate = useNavigate()
  const recurringReportConfiguration = useGetObject(recurringReportId, 'recurringReportConfiguration') as DashboardItem

  const reports =
    useGetObjectsByProperties('report', {
      strategyId,
      recurringReportConfigurationId: recurringReportId
    }) || []

  const sortedReports = reports.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
  const report = sortedReports[0]

  const onOpenChange = (_e) => {
    navigate('..')
  }

  return (
    <DialogRoot open placement="center" onOpenChange={onOpenChange} size="xl">
      <DialogContent maxH="75%">
        <DialogHeader>
          <DialogTitle>{recurringReportConfiguration.name}</DialogTitle>
          <DialogCloseTrigger />
        </DialogHeader>
        <DialogBody overflowY="auto">
          <MessageContent message={{ id: report?.id || '-1', content: report?.summary || 'No summary available' }} />
        </DialogBody>
      </DialogContent>
    </DialogRoot>
  )
}

export default Show
