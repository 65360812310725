import { HStack, Icon, List, Stack, Text, Textarea } from '@chakra-ui/react'
import type { FC } from 'react'

import { Field } from '@app/components/ui/field'
import ICON_MAP from '@app/utils/iconMap'
import type { MetricSource as BaseMetricSource } from '@graphql/types'

type Metric = BaseMetricSource['metric']
type MetricSource = Pick<BaseMetricSource, 'id' | 'configuration'> & {
  metric: Pick<Metric, 'id'>
}

const HelpList = ({ title, items }) => (
  <Stack>
    <Text fontSize="md">{title}</Text>
    <List.Root>
      {items.map((item, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <List.Item key={i}>{item}</List.Item>
      ))}
    </List.Root>
  </Stack>
)

const HelperText = () => (
  <Stack>
    <HStack>
      <Icon color="gray.500" fontSize="xl">
        <ICON_MAP.Details />
      </Icon>
      <Stack>
        <HelpList
          title={'Referencing Metrics'}
          items={[
            'Metric IDs must begin with a non-numeric character and may only contain alphanumeric characters and underscores.',
            'You can copy a metric Id by clicking the "ID" button in the toolbar for that metric.'
          ]}
        />
        <HelpList
          title={'Shorthand for Source Metrics'}
          items={[
            <>
              Use <code>sources</code> to reference all metrics that point to the current metric. This allows you to
              avoid manually listing metric IDs.
            </>
          ]}
        />
        <HelpList
          title={'Supported Functions'}
          items={[
            <>
              <code>SUM(sources)</code>: Adds up all source metrics pointing to the current metric. Comma-separated IDs
              can be used as well.
            </>,
            <>
              <code>MULTIPLY(sources)</code>: Multiplies all source metrics pointing to the current metric.
              Comma-separated IDs can be used as well.
            </>
          ]}
        />
        <HelpList
          title={'Valid Operations'}
          items={[
            <>
              Standard arithmetic operators: <code>+</code>, <code>-</code>, <code>*</code>, <code>/</code>, and{' '}
              <code>^</code> (exponentiation).
            </>,
            <>
              Use parentheses <code>()</code> to group operations.
            </>
          ]}
        />
      </Stack>
    </HStack>
  </Stack>
)

export interface Props {
  metricSource: MetricSource | null
  disabled: boolean
}

const Calculated: FC<Props> = ({ metricSource, disabled }) => (
  <Field label="Formula" required disabled={disabled} helperText={<HelperText />}>
    <Textarea
      w="full"
      maxW="full"
      defaultValue={metricSource?.configuration?.formula}
      name="configuration.formula"
      placeholder="Enter a formula..."
    />
  </Field>
)

export default Calculated
