import type { BoxProps } from '@chakra-ui/react'
import { Box, useToken } from '@chakra-ui/react'
import {
  BarController,
  BarElement,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Tooltip
} from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'
import zoomPlugin from 'chartjs-plugin-zoom'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import sortBy from 'lodash/sortBy'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import type { ChartProps } from 'react-chartjs-2'
import { Chart } from 'react-chartjs-2'

import { useColorModeValue as mode } from '@app/components/ui/color-mode'
import 'chartjs-adapter-date-fns'
import type { GraphAggregate } from '@app/types'
import type { ImpactfulEntity } from '@app/utils/graphHelpers'
import { buildGraphEvents, buildGraphImpactBars, buildGraphMetrics } from '@app/utils/graphHelpers'
import type { Metric } from '@graphql/types'

ChartJS.register(
  annotationPlugin,
  zoomPlugin,
  TimeScale,
  LinearScale,
  BarController,
  BarElement,
  LineController,
  LineElement,
  Legend,
  PointElement,
  Tooltip
)

type GraphDataPoint = {
  x: Date
  y: number
  forecasted?: boolean
}

type MetricsGraphData = ChartProps<'line', GraphDataPoint[]>['data']['datasets'][0]

interface Props extends BoxProps {
  height?: number
  width?: number
  eventsData?: GraphAggregate
  impactfulEntities?: ImpactfulEntity[]
  metric?: Pick<Metric, 'name' | 'metricDataPoints' | 'positiveDirection'>
  grow?: number
  shrink?: number
  basis?: string
  my?: number
}

const Graph: FC<Props> = ({
  height = 165,
  width = undefined,
  eventsData = null,
  impactfulEntities = null,
  metric = null,
  ...boxProps
}) => {
  const [eventsGraphData, setEventsGraphData] = useState(null)
  const [metricsGraphData, setMetricsGraphData] = useState<MetricsGraphData>(null)
  const [annotations, setAnnotations] = useState([])
  const graphDataPoints = buildGraphMetrics(metric?.metricDataPoints || [])

  const [metricColor, impactPositive, impactNegative, eventsColor, eventsHoverColor] = useToken('colors', [
    'blue.400',
    'green.500',
    'red.500',
    'gray.300',
    'gray.500'
  ])

  const gridTickColor = mode('#eee', '#666')
  const graphTextColor = mode('#666', '#eee')

  useEffect(() => {
    if (eventsData) {
      setEventsGraphData({
        type: 'bar',
        label: 'Events',
        order: 2,
        data: buildGraphEvents(eventsData),
        borderRadius: 2,
        backgroundColor: eventsColor,
        hoverBackgroundColor: eventsHoverColor
      })
    }
  }, [eventsData, eventsColor, eventsHoverColor])

  useEffect(() => {
    if (impactfulEntities) {
      setAnnotations(buildGraphImpactBars(impactfulEntities, impactPositive, impactNegative))
    }
  }, [impactfulEntities, impactNegative, impactPositive])

  useEffect(() => {
    const data = sortBy(graphDataPoints || [], 'x')
    const forecasted = data.filter((point) => point.forecasted)
    const lastUnforecasted = data.filter((point) => !point.forecasted).pop()
    const lastForecasted = forecasted.pop()

    const increased = lastUnforecasted?.y < lastForecasted?.y

    if (!isEmpty(graphDataPoints) && !isEqual(data, metricsGraphData?.data)) {
      setMetricsGraphData({
        type: 'line',
        order: 0,
        cubicInterpolationMode: 'monotone',
        tension: 0.8,
        yAxisID: 'y2',
        label: metric.name,
        data,
        backgroundColor: (ctx) => {
          const rawValue: Pick<GraphDataPoint, 'forecasted'> = ctx.raw
          const color = increased && metric.positiveDirection === 'up' ? impactPositive : impactNegative

          return rawValue?.forecasted ? color : metricColor
        },
        borderColor: metricColor,
        pointRadius: 3,
        segment: {
          borderDash: (ctx) => {
            const rawValue: Pick<GraphDataPoint, 'forecasted'> = ctx.p1.getProps(['raw']).raw

            // returns [dash length, space length], undefined for default styling
            return rawValue.forecasted ? [3, 3] : undefined
          },
          borderColor: (ctx) => {
            const rawValue: Pick<GraphDataPoint, 'forecasted'> = ctx.p1.getProps(['raw']).raw
            const color = increased && metric.positiveDirection === 'up' ? impactPositive : impactNegative

            return rawValue.forecasted ? color : undefined
          }
        }
      })
    } else if (isEmpty(graphDataPoints) && !isEqual({}, metricsGraphData)) {
      setMetricsGraphData(null)
    }
  }, [
    graphDataPoints,
    metricColor,
    metric.name,
    metricsGraphData,
    metricsGraphData?.data,
    metric.positiveDirection,
    impactPositive,
    impactNegative
  ])

  const datasets = !eventsGraphData ? [] : [eventsGraphData]
  if (!isEmpty(metricsGraphData)) {
    datasets.push(metricsGraphData)
  }

  const allAnnotations = [...annotations].filter((annotation) => annotation !== null)

  return (
    <Box w="100%" h={height} {...boxProps}>
      <Chart
        type="bar"
        height={height}
        width={width}
        data={{
          labels: [],
          datasets
        }}
        options={{
          layout: {
            padding: { left: 0 }
          },
          maintainAspectRatio: false,
          // tick: {
          //   major: true
          // },
          // tickOptions: {
          //   major: true
          // },
          elements: {
            lineAnnotation: {
              drawTime: 'beforeDatasetsDraw',
              label: {
                drawTime: 'afterDraw'
              }
            }
          },
          plugins: {
            annotation: {
              annotations: allAnnotations
            },
            legend: {
              position: 'bottom',
              labels: {
                color: graphTextColor
              }
            },
            tooltip: {
              callbacks: {
                label: (ctx) => {
                  const rawValue: Pick<GraphDataPoint, 'forecasted'> = ctx.raw

                  return rawValue?.forecasted
                    ? `${ctx.dataset.label} (forecasted): ${ctx.formattedValue}`
                    : `${ctx.dataset.label}: ${ctx.formattedValue}`
                }
              }
            },
            zoom: {
              zoom: {
                drag: {
                  enabled: true
                },
                wheel: {
                  enabled: true
                },
                pinch: {
                  enabled: true
                },
                mode: 'x'
              }
            }
          },
          // grid: {
          //   display: false
          // },
          scales: {
            x: {
              type: 'time',
              display: true,
              offset: true,
              border: {
                display: true
              },
              grid: {
                drawTicks: true,
                drawOnChartArea: false,
                display: true,
                color: gridTickColor
              },
              time: {
                unit: 'week',
                tooltipFormat: 'MMMM dd, yyyy'
              },
              ticks: {
                color: graphTextColor,
                maxRotation: 0,
                major: {
                  enabled: false
                }
              }
            },
            y: {
              border: {
                width: 0
              },
              ticks: {
                display: false,
                color: gridTickColor
              },
              grid: {
                color: gridTickColor,
                display: true
              }
            },
            y2: {
              border: {
                width: 0
              },
              grid: {
                display: false
              },
              ticks: {
                display: false,
                major: {
                  enabled: false
                }
              }
            }
          }
        }}
      />
    </Box>
  )
}

export default Graph
