import { Box, HStack, Text } from '@chakra-ui/react'
import type { TextProps } from '@chakra-ui/react'
import type { FC } from 'react'
import { useEffect, useRef, useState } from 'react'

import { Avatar } from '@app/components/ui/avatar'
import { PopoverRoot, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger } from '@app/components/ui/popover'
import useGetObject from '@app/hooks/useGetObject'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import { SearchList, SearchListItem } from '@app/shared/searchList'
import TooltipAvatar from '@app/shared/tooltipAvatar'
import { useStore } from '@app/store'
import type { DomainBasicCard, DomainEntity, DomainMetric, DomainStrategy } from '@app/types'
import profileImage from '@app/utils/profileImage'
import { loaderQuery } from '@graphql/client'
import { Accounts } from '@graphql/documents/account.graphql'
import type { NodeObjectInput } from '@graphql/types'

type Owned = DomainStrategy | DomainEntity | DomainBasicCard | DomainMetric
type DomainObjectWithOwner = Pick<Owned, 'id' | 'classType' | 'ownerId'>

interface OwnerPopoverProps extends TextProps {
  object: DomainObjectWithOwner
  avatarProps?: Record<string, unknown>
  showName?: boolean
}

const OwnerPopover: FC<OwnerPopoverProps> = ({ object, avatarProps = { size: 'xs' }, showName = false, ...rest }) => {
  const { user } = useStoreCurrentUser()
  const [loading, setLoading] = useState(true)
  const [members, setMembers] = useState([])
  const initialFocusRef = useRef()
  const [open, setOpen] = useState(false)
  const updateObject = useStore.use.updateObject()
  const { id, classType, ownerId } = object
  const owner = useGetObject(ownerId, 'user')

  useEffect(() => {
    if (loading) {
      loaderQuery(Accounts)
        .then(({ data }) => {
          if (data?.accounts) {
            setMembers(data.accounts.sort((a, b) => a.name.localeCompare(b.name)))
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [loading])

  const viewElement = (
    <HStack>
      <TooltipAvatar name={owner?.name} src={profileImage(owner?.email)} {...avatarProps} />
      {showName && (
        <Text color="fg.muted" {...rest}>
          {owner?.name}
        </Text>
      )}
    </HStack>
  )

  if (!['admin', 'editor'].includes(user?.role)) {
    return owner ? viewElement : null
  }

  const handleItemClick = (value: string) => {
    const updated = { [classType]: { id, ownerId: value } } as NodeObjectInput
    updateObject(updated)
    // @ts-expect-error have to set owner this way or it won't update until the data has refreshed
    updateObject({ [classType]: { id, owner: members.find((member) => member.id === value) } }, true)

    setOpen(!open)
  }

  return (
    <PopoverRoot
      portalled
      initialFocusEl={initialFocusRef.current}
      lazyMount
      onOpenChange={(e) => setOpen(e.open)}
      open={open}
    >
      <PopoverTrigger>
        <Box display="inline" cursor="pointer" onClick={() => setOpen(!open)}>
          {viewElement}
        </Box>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody m={0} p={0}>
          <SearchList
            searchField="owner"
            currentValue={owner?.id}
            initialFocusRef={initialFocusRef}
            onChange={handleItemClick}
            loading={loading}
          >
            <SearchListItem
              key={-1}
              icon={<Avatar name={null} size="2xs" src={profileImage(null)} />}
              text="No owner"
              value={null}
            />
            {members.map((member) => (
              <SearchListItem
                key={member.userId}
                icon={<Avatar name={member.name} size="2xs" src={profileImage(member?.user?.email, { s: '32' })} />}
                text={member.name}
                value={member.userId}
              />
            ))}
          </SearchList>
        </PopoverBody>
      </PopoverContent>
    </PopoverRoot>
  )
}

export default OwnerPopover
