import type { StackProps } from '@chakra-ui/react'
import { Box, Stack } from '@chakra-ui/react'
import type { FC, ReactElement, ReactNode } from 'react'

export interface Props extends StackProps {
  date?: string
  children: ReactNode
  variant?: string
  colorPalette?: string
  size?: string
}

const LayoutRow: FC<Props> = ({ date = null, children, ...rest }) => {
  // const { variant, colorPalette, size } = rest

  let dateDisplay: string | ReactElement = ''
  if (date) {
    dateDisplay = (
      <>
        Week of <span style={{ whiteSpace: 'nowrap' }}>{date}</span>
      </>
    )
  }

  return (
    <Stack
      flexDir={{ base: 'column', lg: 'row' }}
      mx={4}
      mt={4}
      pt={4}
      borderColor="bg.muted"
      borderTop="1px"
      _first={{ border: 'none', mt: 0 }}
      data-testid="layout-row"
      {...rest}
    >
      <Box w={{ base: '100%', lg: '20%' }} mt={4} pt={2} color="gray.500" fontSize="sm" fontWeight={500}>
        {dateDisplay}
      </Box>
      <Box w={{ base: '100%', lg: '80%' }} ml={0} px={{ base: 0, lg: 4 }}>
        <Box maxW={{ base: '100%', lg: '600px' }}>{children}</Box>
      </Box>
    </Stack>
  )
}

export default LayoutRow
