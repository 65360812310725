import { Link as ChakraLink, Box, Center, Stack, Text, useCheckboxGroup } from '@chakra-ui/react'
import truncate from 'lodash/truncate'
import type { FC } from 'react'
import { memo, useCallback, useMemo } from 'react'
import { Link, useSearchParams } from 'react-router-dom'

import { Checkbox } from '@app/components/ui/checkbox'
import { EmptyState } from '@app/components/ui/empty-state'
import useGetObjectPage from '@app/hooks/useGetObjectPage'
import BasicCardTypePopover from '@app/next/basicCardTypePopover'
import MapFilterSelectInput from '@app/next/forms/mapFilterSelectInput'
import BasicCardListActions from '@app/pages/basicCards/components/basicCardListActions'
import { ListFilterContextProvider } from '@app/pages/shared/listFilterContext'
import ListLabels from '@app/pages/shared/listLabels'
import MapsCell from '@app/pages/shared/mapsCell'
import MultiOps from '@app/pages/shared/multiOps'
import SearchRow from '@app/pages/shared/searchRow'
import SelectAllCheckboxHeader from '@app/pages/shared/selectAllCheckboxHeader'
import useGenerateSelectableTableRowFormatter from '@app/pages/shared/useGenerateSelectableTableRowFormatter'
import { PageHeader, PageStack } from '@app/shared/layout'
import Pagination from '@app/shared/pagination'
import Table from '@app/shared/table'
import SortHeader from '@app/shared/table/components/sortHeader'
import { SortHeaderContextProvider } from '@app/shared/table/contexts/sortHeaderContext'
import type { Column } from '@app/shared/table/types'
import withAwait from '@app/shared/withAwait'
import type { DomainBasicCard } from '@app/types'
import type { BasicCard } from '@graphql/types'

type MakeColumns = (args: {
  getCheckboxProps: ReturnType<typeof useCheckboxGroup>['getItemProps']
  selected: ReturnType<typeof useCheckboxGroup>['value']
  setSelected: ReturnType<typeof useCheckboxGroup>['setValue']
}) => Column<BasicCard>[]

type CardData = { data: BasicCard }

const BasicCardName: FC<CardData> = ({ data }) => (
  <Stack>
    <ChakraLink
      asChild
      data-cy="card-name"
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <Link to={data.id}>
        <Text minW="xs" fontSize="md" whiteSpace="normal" wordBreak="break-all" title={data.name}>
          {truncate(data.name, { length: 50 })}
        </Text>
      </Link>
    </ChakraLink>
    {data.containeeCount > 0 ? <Text>`${data.containeeCount} work items`</Text> : null}
    <MapsCell data={data} />
    <ListLabels labels={data?.labels} />
  </Stack>
)

const BasicCardType: FC<CardData> = ({ data }) => <BasicCardTypePopover basicCard={data} fontSize="sm" boxSize={4} />

const makeColumns: MakeColumns = ({ getCheckboxProps, selected, setSelected }) => [
  {
    header: <SelectAllCheckboxHeader collectionType="basicCard" selected={selected} setSelected={setSelected} />,
    width: '5%',
    key: 'checkbox',
    cell: ({ data: basicCard }) => (
      <Center>
        <Checkbox {...getCheckboxProps({ value: basicCard.id })} />
      </Center>
    )
  },
  {
    header: <SortHeader field="name" title="Name" emptyState />,
    key: 'name',
    width: '40%',
    cell: BasicCardName
  },
  {
    header: <SortHeader field="cardTypeName" title="Type" emptyState />,
    key: 'cardType',
    cell: BasicCardType
  },
  {
    header: '',
    cell: BasicCardListActions
  }
]

const awaited = (Component: FC) => withAwait(Component, 'basicCards')

const Empty = ({ strategyId }) => (
  <Box p={2}>
    <SearchRow
      leftChild={<MapFilterSelectInput field="strategyId" defaultValue={strategyId} />}
      page={0}
      limitValue={0}
      totalCount={0}
    />
    <EmptyState title="No cards" description="Add some cards on your maps to see them here." />
  </Box>
)

const AwaitedEmpty = awaited(Empty)

const Populated = ({ strategyId, collection, metadata }) => {
  const { page, totalCount } = metadata
  const {
    value: selected,
    getItemProps: getCheckboxProps,
    setValue: setSelected
  } = useCheckboxGroup({
    defaultValue: []
  })
  const columns = useMemo(
    () => makeColumns({ getCheckboxProps, selected, setSelected }),
    [getCheckboxProps, selected, setSelected]
  )
  const onBulkDelete = useCallback(() => {
    setSelected([])
  }, [setSelected])
  const baseRowProps = useGenerateSelectableTableRowFormatter<DomainBasicCard>('basicCard', selected, setSelected)
  const rowFormatter = useCallback((basicCard: BasicCard) => baseRowProps(basicCard), [baseRowProps])

  return (
    <Stack gap="0">
      <SearchRow
        leftChild={<MapFilterSelectInput field="strategyId" defaultValue={strategyId} />}
        leftSummarySibling={<MultiOps type="basicCard" selected={selected} fontSize="sm" onDelete={onBulkDelete} />}
        page={page}
        limitValue={collection.length}
        totalCount={totalCount}
      />
      <Box overflow="auto">
        <SortHeaderContextProvider defaultState={{ name: 'asc' }}>
          <Table<(typeof collection)[0]> columns={columns} data={collection} rowFormatter={rowFormatter} />
        </SortHeaderContextProvider>
        <Pagination px={6} py={4} {...{ ...metadata }} scrollContainer="main" />
      </Box>
    </Stack>
  )
}

const AwaitedPopulated = awaited(Populated)

const List: FC = () => {
  const { collection, metadata } = useGetObjectPage('basicCard')
  const [searchParams] = useSearchParams()
  const strategyId = searchParams.get('strategyId')
  const subtitle =
    'Your cards include your bets, objectives, any other type of card you create outside of work, metrics, and maps.'

  return (
    <ListFilterContextProvider searchField="filter">
      <PageStack>
        <PageHeader title="Cards" subtitle={subtitle} />
        <Box>
          {collection.length ? (
            <AwaitedPopulated strategyId={strategyId} collection={collection} metadata={metadata} />
          ) : (
            <AwaitedEmpty strategyId={strategyId} />
          )}
        </Box>
      </PageStack>
    </ListFilterContextProvider>
  )
}

export default memo(List)
