import { Flex, HStack, Spacer, Stack } from '@chakra-ui/react'
import type { FC } from 'react'
import { useEffect, useState } from 'react'

import { NativeSelectField, NativeSelectRoot } from '@app/components/ui/native-select'
import GraphDataFetcher from '@app/fetchers/graphDataFetcher'
import { DEFAULT_INTERVAL } from '@app/lib/globals'
import CumulativeToggle from '@app/pages/shared/cumulativeToggle'
import Graph from '@app/shared/graph/graph'
import type { DomainMetric } from '@app/types'
import { IntervalEnum } from '@graphql/types'

type GraphDomainObject = {
  id: string
  type: string
}

interface Props {
  metric?: Pick<DomainMetric, 'id' | 'rollUp' | 'metricDataPointsCount'>
  domainObject?: GraphDomainObject
}

const MetricGraph: FC<Props> = ({ metric = null, domainObject }) => {
  const [activeDomainObject, setActiveDomainObject] = useState(domainObject)
  const [activeMetric, setActiveMetric] = useState(metric)
  const [cumulative, setCumulative] = useState(false)
  const [interval, setInterval] = useState(DEFAULT_INTERVAL)

  useEffect(() => {
    if (domainObject?.id !== activeDomainObject?.id || domainObject?.type !== activeDomainObject?.type) {
      setActiveDomainObject(domainObject)
      setInterval(DEFAULT_INTERVAL)
    }

    if (metric?.id !== activeMetric?.id) {
      setActiveMetric(metric)
      setInterval(DEFAULT_INTERVAL)
    }
  }, [activeDomainObject?.id, activeDomainObject?.type, activeMetric?.id, domainObject, metric])

  if (!activeMetric) {
    return null
  }

  if (!activeMetric?.metricDataPointsCount) {
    return null
  }

  const filters = { interval, range: 'all' }
  const graphDataFetcherParams = activeDomainObject ? { [`${activeDomainObject.type}Id`]: activeDomainObject.id } : {}

  return (
    <Stack gap={0} borderTop="1px" borderTopColor="gray.200">
      <HStack px={4}>
        <NativeSelectRoot>
          <NativeSelectField
            key="interval"
            bg="bg"
            name="interval_select"
            onChange={(e) => setInterval(e.target.value as IntervalEnum)}
            value={interval}
          >
            <option value={IntervalEnum.Day}>Day</option>
            <option value={IntervalEnum.Week}>Week</option>
            <option value={IntervalEnum.Month}>Month</option>
            <option value={IntervalEnum.Quarter}>Quarter</option>
            <option value={IntervalEnum.Year}>Year</option>
          </NativeSelectField>
        </NativeSelectRoot>
        <Spacer />
        <CumulativeToggle metric={metric} cumulative={cumulative} setCumulative={setCumulative} />
      </HStack>
      <GraphDataFetcher
        filters={filters}
        cumulative={cumulative}
        metricId={metric.id}
        fetchEvents={false}
        fetchMetricEvents
        {...graphDataFetcherParams}
      >
        {(eventsData, impactfulEntities, metricData) => (
          <Graph
            as={Flex}
            grow={0}
            shrink={0}
            basis="165px"
            my={1}
            px={2}
            eventsData={eventsData}
            impactfulEntities={impactfulEntities}
            metric={metricData}
          />
        )}
      </GraphDataFetcher>
    </Stack>
  )
}

export default MetricGraph
