import { HStack, Icon, TagLabel } from '@chakra-ui/react'
import capitalize from 'lodash/capitalize'
import type { FC } from 'react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'

import { Tag } from '@app/components/ui/tag'
import ContainmentTag from '@app/shared/entity/containmentTag'
import type { DomainEntity } from '@app/types'
import { ImpactEnum, ImportanceEnum } from '@graphql/queries'

export interface Props {
  entity: Pick<DomainEntity, 'entityType' | 'impact' | 'importance' | 'containerEntityContainments'>
}

const Tags: FC<Props> = ({ entity, ...rest }) => {
  const { entityType: type, impact, importance } = entity

  if (!type && !impact && !(importance === ImportanceEnum.Major)) {
    return null
  }

  const impactMap = {
    positive: {
      icon: FiChevronUp,
      colorPalette: 'green'
    },

    negative: {
      icon: FiChevronDown,
      colorPalette: 'red'
    }
  }

  const IconEl = impactMap[impact]?.icon

  return (
    <HStack wrap="wrap" {...rest}>
      {importance === ImportanceEnum.Major && (
        <Tag colorPalette="orange">
          <TagLabel>Major update</TagLabel>
        </Tag>
      )}
      {type && <Tag>{capitalize(type)}</Tag>}
      {[ImpactEnum.Positive, ImpactEnum.Negative].includes(impact) && (
        <Tag colorPalette={impactMap[impact].colorPalette}>
          <Icon>
            <IconEl />
          </Icon>
        </Tag>
      )}
      {entity.containerEntityContainments?.map((containment) => (
        <ContainmentTag key={containment.id} containment={containment} />
      ))}
    </HStack>
  )
}

export default Tags
