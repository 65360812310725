import { useCallback } from 'react'

import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import type { DomainChat } from '@app/types'

const useGetPipelineChats = (pipelineId: string, strategyId: string) => {
  const slug = pipelineId.replaceAll('_', '-')
  const filter = useCallback(
    (chat: DomainChat) => !!chat.mostRecentPrompt && chat.pipelineSlug === slug && chat.strategyId === strategyId,
    [slug, strategyId]
  )

  return useGetObjectsByProperties('chat', filter)
}

export default useGetPipelineChats
