import type { FC } from 'react'
import { memo } from 'react'

import { stringToColor } from '@app/utils/stringHelpers'

interface CursorProps {
  position?: { x: number; y: number }
  name?: string
}

const Cursor: FC<CursorProps> = ({ position, name }) => {
  if (!position) {
    return null
  }

  const { x, y } = position

  // Do not render the cursor if offscreen
  if (x < 0 || y < 0 || x > window.innerWidth || y > window.innerHeight) {
    return null
  }

  const color = stringToColor(name)

  return (
    <div
      style={{
        position: 'absolute',
        pointerEvents: 'none',
        userSelect: 'none',
        left: 0,
        top: 0,
        zIndex: 4,
        transition: 'transform 1s cubic-bezier(.17,.93,.38,1)',
        transform: `translateX(${x}px) translateY(${y}px)`
      }}
    >
      <svg
        className="cursor"
        width="24"
        height="36"
        viewBox="0 0 24 36"
        fill="none"
        stroke="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.65376 12.3673H5.46026L5.31717 12.4976L0.500002 16.8829L0.500002 1.19841L11.7841 12.3673H5.65376Z"
          fill={color}
        />
      </svg>

      <div
        style={{
          backgroundColor: color,
          borderRadius: 3,
          position: 'absolute',
          top: 15,
          left: 12,
          padding: '2px 4px'
        }}
      >
        <p
          style={{
            whiteSpace: 'nowrap',
            fontSize: 14,
            color: 'white'
          }}
        >
          {name}
        </p>
      </div>
    </div>
  )
}

export default memo(Cursor)
