import type { FC } from 'react'
import { useState } from 'react'

import { Button } from '@app/components/ui/button'
import { toaster } from '@app/components/ui/toaster'
import { useStrategyRegressionScoresClearMutation } from '@graphql/queries'

type ClearStrategyRegressionAnalysisProps = {
  strategyId: string
}

const ClearStrategyRegressionAnalysis: FC<ClearStrategyRegressionAnalysisProps> = ({ strategyId }) => {
  const [loading, setLoading] = useState(false)
  const [, clearStatsMutation] = useStrategyRegressionScoresClearMutation()

  const handleClearStats = async () => {
    setLoading(true)
    try {
      const response = await clearStatsMutation({
        input: { strategyId }
      })

      if (response.error) {
        toaster.create({
          title: 'Error',
          description: response.error.message,
          placement: 'bottom-end',
          type: 'error'
        })
      } else {
        toaster.create({
          title: 'Success',
          description: 'Regression scores cleared successfully',
          placement: 'bottom-end',
          type: 'success'
        })
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Button
      aria-label="Clear Stats"
      colorPalette="red"
      onClick={handleClearStats}
      variant="ghost"
      size="2xs"
      loading={loading}
    >
      Clear Stats
    </Button>
  )
}

export default ClearStrategyRegressionAnalysis
