import type { TextProps } from '@chakra-ui/react'
import { Box, HStack, Icon, StackSeparator, Text } from '@chakra-ui/react'
import capitalize from 'lodash/capitalize'
import type { FC } from 'react'
import { useMemo, useRef, useState } from 'react'
import { PiCaretDown, PiPlaceholder } from 'react-icons/pi'

import { PopoverRoot, PopoverTrigger, PopoverContent, PopoverBody, PopoverArrow } from '@app/components/ui/popover'
import useCookie from '@app/hooks/useCookie'
import useGetObject from '@app/hooks/useGetObject'
import useGetObjects from '@app/hooks/useGetObjects'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import { BASIC_CARD_TYPE_COOKIE } from '@app/lib/globals'
import BasicTypeModal from '@app/pages/maps/components/nodes/components/basicType/basicTypeModal'
import { iconMap } from '@app/pages/maps/components/nodes/components/basicType/customIcons'
import { SearchList, SearchListItem } from '@app/shared/searchList'
import { useStore } from '@app/store'
import type { DomainBasicCard } from '@app/types'

interface Props extends TextProps {
  basicCard: Pick<DomainBasicCard, 'id' | 'cardTypeId'>
}

const useSortedCardTypes = () => {
  const cardTypes = useGetObjects('cardType')
  return useMemo(() => cardTypes.sort((a, b) => a.name.localeCompare(b.name)), [cardTypes])
}

const BasicCardTypePopover: FC<Props> = ({ basicCard, fontSize = 'md', boxSize = 5 }) => {
  const [, setCardTypeId] = useCookie(BASIC_CARD_TYPE_COOKIE)
  const { user } = useStoreCurrentUser()
  const cardTypes = useSortedCardTypes()
  const initialFocusRef = useRef()
  const [open, setOpen] = useState(false)
  const [disclosureOpen, setDisclosureOpen] = useState(false)
  const updateObject = useStore.use.updateObject()
  const { id, cardTypeId } = basicCard
  const cardType = useGetObject(cardTypeId, 'cardType')
  const IconComponent = cardType?.icon ? iconMap[cardType?.icon] : PiPlaceholder
  const elementColor = cardType?.color ? `${cardType.color}.500` : 'gray.500'

  const viewElement = (
    <HStack>
      <Icon boxSize={boxSize} color={elementColor}>
        <IconComponent />
      </Icon>
      <Text color={elementColor} fontSize={fontSize} fontWeight="semibold">
        {capitalize(cardType?.name || '')}
      </Text>
    </HStack>
  )

  if (!['admin', 'editor'].includes(user?.role)) {
    return viewElement
  }

  const handleItemClick = (value: string) => {
    setCardTypeId(value) // Set the BASIC_CARD_TYPE_COOKIE so we can remember the last selected type

    updateObject({ basicCard: { id, cardTypeId: value } })

    setOpen(!open)
  }

  const handleManageClick = () => {
    setDisclosureOpen(true)
    setOpen(!open)
  }

  const handleBasicTypeModalClose = (e) => {
    setDisclosureOpen(e.open)
  }

  return (
    <>
      <BasicTypeModal cardTypes={cardTypes} open={disclosureOpen} onOpenChange={handleBasicTypeModalClose} />
      <PopoverRoot
        portalled
        initialFocusEl={initialFocusRef.current}
        lazyMount
        onOpenChange={(e) => setOpen(e.open)}
        open={open}
      >
        <PopoverTrigger>
          <HStack gap={1} cursor="pointer" onClick={() => setOpen(!open)}>
            {viewElement}
            <Icon color={elementColor}>
              <PiCaretDown />
            </Icon>
          </HStack>
        </PopoverTrigger>
        <PopoverContent maxW={60}>
          <PopoverArrow />
          <PopoverBody m={0} p={0}>
            <SearchList
              searchField="type"
              currentValue={cardType?.id}
              initialFocusRef={initialFocusRef}
              onChange={handleItemClick}
              maxH="500px"
            >
              <SearchListItem text="Manage types..." value={null} onClick={handleManageClick} />
              <SearchListItem
                text="None"
                value={null}
                icon={
                  <Icon boxSize={boxSize} color="gray.500">
                    <PiPlaceholder />
                  </Icon>
                }
              />
              <StackSeparator my={2} />
              {cardTypes.map((types) => {
                const ListItemIconComponent = types.icon ? iconMap[types.icon] : PiPlaceholder

                return (
                  <SearchListItem
                    key={types.id}
                    icon={
                      types.icon ? (
                        <Icon boxSize={boxSize} color={types?.color ? `${types.color}.500` : 'gray.500'}>
                          <ListItemIconComponent />
                        </Icon>
                      ) : (
                        <Box pr={boxSize} />
                      )
                    }
                    text={capitalize(types.name)}
                    value={types.id}
                    color={types.color}
                  />
                )
              })}
            </SearchList>
          </PopoverBody>
        </PopoverContent>
      </PopoverRoot>
    </>
  )
}

export default BasicCardTypePopover
