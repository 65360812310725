import type { StackProps } from '@chakra-ui/react'
import { Stack, Separator } from '@chakra-ui/react'
import type { FC } from 'react'

const Card: FC<StackProps> = ({ color = null, children, ...props }) => {
  const cardProps = {} as { borderBottomColor?: string; borderBottomWidth?: number }

  if (color) {
    cardProps.borderBottomColor = `${color}.400`
    cardProps.borderBottomWidth = 8
  }

  return (
    <Stack
      bg="bg.panel"
      borderWidth="1px"
      borderStyle="solid"
      borderColor="border.emphasized"
      borderRadius="6px"
      shadow="sm"
      {...cardProps}
      {...props}
      gap={0}
      separator={<Separator borderColor="border.emphasized" />}
    >
      {children}
    </Stack>
  )
}

export default Card
