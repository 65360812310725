import { Heading, HStack, Link as ChakraLink, Stack } from '@chakra-ui/react'
import type { FC } from 'react'
import { Link } from 'react-router-dom'

import Actions from './actions'
import EditorDetails from './editorDetails'
import EntityDate from './entityDate'
import ExternalLink from './externalLink'
import MinorEntity from './minorEntity'
import Tags from './tags'

import EntityImages from '@app/shared/entity/entityImages'
import Section from '@app/shared/entity/section'
import MarkdownDisplay from '@app/shared/markdownDisplay'
import type { DomainEntity } from '@app/types'
import { pathWithSearchParams } from '@app/utils/routeHelpers'

export interface Props {
  entity: DomainEntity
  isListView?: boolean
  scrollContainer?: string | object
  variant?: string
  colorPalette?: string
  size?: string
}

const Entity: FC<Props> = ({ entity, isListView = false, scrollContainer = '#react-root', ...rest }) => {
  const entityPath = pathWithSearchParams(`${entity.id}`)

  // this does not feel like a good way to handle the conditional display
  if (entity.importance === 'minor' && isListView) {
    return <MinorEntity entity={entity} entityPath={entityPath} />
  }

  const { name, happenedAt, summaryFull, linkTitle, linkUrl, goalHypothesis, results } = entity

  const heading = isListView ? (
    <ChakraLink asChild>
      <Link to={entityPath}>{name}</Link>
    </ChakraLink>
  ) : (
    name
  )

  return (
    <Stack flexDir="column" gap={6} mb={6} py={2} _last={{ mb: 0 }} {...rest}>
      <Stack direction={['column', 'row']}>
        <Stack gap={3}>
          <Tags entity={entity} />
          <HStack>
            <Heading color="muted" fontSize="xl" fontWeight="bold" lineHeight="32px" wordBreak="break-all">
              {heading}
            </Heading>
          </HStack>
          {!isListView && <EntityDate happenedAt={happenedAt} />}
          {!isListView && <Actions entity={entity} isListView={isListView} />}
        </Stack>
      </Stack>
      <EntityImages entity={entity} scrollContainer={scrollContainer} isListView={isListView} />
      <MarkdownDisplay text={summaryFull} />
      <Section title="Goal / Hypothesis" isDisplayed={!!goalHypothesis}>
        <MarkdownDisplay text={goalHypothesis} />
      </Section>
      <Section title="Results" isDisplayed={!!results}>
        <MarkdownDisplay text={results} />
      </Section>
      <ExternalLink title={linkTitle} url={linkUrl} />
      <EditorDetails entity={entity} isListView={isListView} />
    </Stack>
  )
}

export default Entity
