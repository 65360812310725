import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'

import { Provider } from '@app/components/ui/provider'
import { Toaster } from '@app/components/ui/toaster'
import router from '@app/router'
import Loader from '@app/shared/loader'
import '@fontsource-variable/inter'

const container = document.getElementById('react-root')
container.style.height = '100%'

const root = createRoot(container)

root.render(
  <Provider>
    <RouterProvider router={router} fallbackElement={<Loader />} />
    <Toaster />
  </Provider>
)
