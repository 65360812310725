import camelCase from 'lodash/camelCase'
import get from 'lodash/get'
import setWith from 'lodash/setWith'
import type { OperationResult } from 'urql'

import type { DomainObject } from '@app/types'

type DataObject = Pick<DomainObject, 'id' | 'classType' | '__typename'>

type Config = {
  // __typenames that always skip normalization
  skipTypenames?: string[]
}

function isDataObject(obj): obj is DataObject {
  return obj && obj.id && (obj.classType || obj.__typename)
}

class Normalizer {
  private config: Config

  constructor(config: Config) {
    this.config = config
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static call({ input, config = {} }: { input: Record<string, any>; config?: Config }) {
    const data = input?.data || input || {}
    const normalizer = new Normalizer(config)

    return normalizer.normalize(data)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  normalize(data: OperationResult<any>): Record<string, Record<string, object>> {
    const [acc] = this.normalizeValue({}, data)

    return acc
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private normalizeArray(acc: Record<string, Record<string, object>>, values: any[]): [typeof acc, typeof values] {
    let currentAcc = acc

    return [
      currentAcc,
      values.map((v) => {
        const [newAcc, newVal] = this.normalizeValue(currentAcc, v)
        currentAcc = newAcc
        return newVal
      })
    ]
  }

  private normalizeDataObject(
    acc: Record<string, Record<string, object>>,
    value: DataObject
  ): [typeof acc, typeof value] {
    let currentAcc = acc

    const normalized = Object.entries(value).reduce((normalizedOb, [innerKey, innerValue]) => {
      const [newAcc, mapped] = this.normalizeValue(currentAcc, innerValue)
      currentAcc = newAcc
      normalizedOb[innerKey] = mapped

      return normalizedOb
    }, {})

    const key = `${value.classType || camelCase(value.__typename)}.${value.id}`

    setWith(
      currentAcc,
      key,
      {
        ...(get(acc, key) || {}),
        ...normalized
      },
      Object
    )

    return [
      currentAcc,
      {
        id: value.id,
        classType: value.classType,
        __typename: value.__typename
      }
    ]
  }

  private normalizeNonDataObject(
    acc: Record<string, Record<string, object>>,
    value: object
  ): [typeof acc, typeof value] {
    let currentAcc = acc

    const normalized = Object.entries(value).reduce((normalizedOb, [innerKey, innerValue]) => {
      const [newAcc, mapped] = this.normalizeValue(currentAcc, innerValue)
      normalizedOb[innerKey] = mapped
      currentAcc = newAcc

      return normalizedOb
    }, {})

    return [currentAcc, normalized]
  }

  private normalizeValue(acc: Record<string, Record<string, object>>, value): [typeof acc, typeof value] {
    if (Array.isArray(value)) {
      return this.normalizeArray(acc, value)
    }

    if (isDataObject(value)) {
      if (!value.classType || this.config.skipTypenames?.includes(value.__typename)) {
        return this.normalizeNonDataObject(acc, value) // continue normalizing any child fields
        // return [acc, value] // or stop normalization immediately?
      }

      return this.normalizeDataObject(acc, value)
    }

    if (value && typeof value === 'object') {
      return this.normalizeNonDataObject(acc, value)
    }

    return [acc, value]
  }
}

export default Normalizer
