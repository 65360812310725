import { Children, cloneElement, useCallback } from 'react'
import type { FC, ReactElement, ReactNode, ChangeEvent, FocusEvent } from 'react'

import { Field } from '@app/components/ui/field'
import type { FieldProps } from '@app/components/ui/field'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import { useStore } from '@app/store'
import type { DomainObject } from '@app/types'
import type { NodeObjectInput } from '@graphql/types'

interface Props extends FieldProps {
  domainObject: DomainObject
  domainObjectUpdateId?: string
  children: ReactNode
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
}

const InlineUpdaterRoot: FC<Props> = ({
  children,
  domainObject,
  domainObjectUpdateId = 'id',
  onChange: onChangeOverride,
  onBlur: onBlurOverride,
  ...rest
}) => {
  const childArray = Children.toArray(children)
  const child = childArray[0] as ReactElement
  const updateObject = useStore.use.updateObject()

  const { user } = useStoreCurrentUser()
  const editor = ['admin', 'editor'].includes(user?.role)

  const onBlur = useCallback(
    (event) => {
      const updatedObject = {
        [domainObject.classType]: {
          id: domainObject.id,
          [event.target.name]: event.target.value
        }
      }

      updateObject(updatedObject as NodeObjectInput)

      // THIS IS CRAPPY. IN THE CASE THIS IS SET ITS GOING TO DO A FAILED UPDATE TO THE BACKEND IN THE FIRST ONE TO ONLY DO IT RIGHT THE SECOND TIME
      if (domainObjectUpdateId !== 'id') {
        updateObject({
          [domainObject.classType]: {
            id: domainObject[domainObjectUpdateId],
            [event.target.name]: event.target.value
          }
        } as NodeObjectInput)
      }
    },
    [domainObject, domainObjectUpdateId, updateObject]
  )

  const onChange = useCallback(
    (event) => {
      const updatedObject = {
        [domainObject.classType]: {
          id: domainObject.id,
          [event.target.name]: event.target.value
        }
      }

      updateObject(updatedObject as NodeObjectInput, true)
    },
    [domainObject, updateObject]
  )

  const upgradedProps = {
    onChange: onChangeOverride ?? onChange,
    onBlur: onBlurOverride ?? onBlur,
    'data-1p-ignore': true
  }

  return (
    <Field disabled={!editor} {...rest}>
      {cloneElement(child, upgradedProps)}
    </Field>
  )
}

export default InlineUpdaterRoot
