import { Card, HStack, IconButton, Link, Spacer } from '@chakra-ui/react'
import type { FC } from 'react'
import { PiDotsThreeVertical } from 'react-icons/pi'

import { DataListItem, DataListRoot } from '@app/components/ui/data-list'
import { MenuContent, MenuItem, MenuRoot, MenuTrigger } from '@app/components/ui/menu'
import LookerDashboardDeleteDialog from '@app/pages/settings/integrations/components/lookerDashboard/lookerDashboardDeleteDialog'
import type { LookerDashboard as LookerDashboardType } from '@graphql/types'

interface Props {
  dashboard: LookerDashboardType
}

const LookerDashboardCard: FC<Props> = ({ dashboard }) => {
  const lastRunText = dashboard.lastRunAt
    ? Intl.DateTimeFormat(undefined, { dateStyle: 'short', timeStyle: 'short' }).format(new Date(dashboard.lastRunAt))
    : 'Running'

  return (
    <Card.Root>
      <Card.Header>
        <Card.Title>
          <HStack>
            <Link target="_blank" rel="onoopener noreferrer" href={dashboard.dashboardUrl}>
              {dashboard.title}
            </Link>
            <Spacer />
            <MenuRoot>
              <MenuTrigger asChild>
                <IconButton variant="ghost">
                  <PiDotsThreeVertical />
                </IconButton>
              </MenuTrigger>
              <MenuContent>
                <MenuItem color="fg.error" _hover={{ bg: 'bg.error', color: 'fg.error' }} value="delete_dashboard">
                  <LookerDashboardDeleteDialog lookerDashboardId={dashboard.id}>Delete</LookerDashboardDeleteDialog>
                </MenuItem>
              </MenuContent>
            </MenuRoot>
          </HStack>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <DataListRoot orientation="horizontal">
          <DataListItem label="Last run:" value={lastRunText}></DataListItem>
        </DataListRoot>
      </Card.Body>
    </Card.Root>
  )
}

export default LookerDashboardCard
