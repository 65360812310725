import { Container, For, SimpleGrid } from '@chakra-ui/react'
import type { FC } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Outlet, useParams } from 'react-router-dom'

import { EmptyState } from '@app/components/ui/empty-state'
import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import { minChildWidth } from '@app/pages/aiDashboard/components/constants'
import DashboardCard from '@app/pages/aiDashboard/components/dashboardCard'
import DashboardCardNew from '@app/pages/aiDashboard/components/dashboardCardNew'
import type { RecurringReportConfigurationsByServiceQuery } from '@graphql/queries'
import { RecurringReportConfigurationServiceEnum } from '@graphql/types'

export type DashboardItem =
  RecurringReportConfigurationsByServiceQuery['recurringReportConfigurations']['collection'][0]

const Index: FC = () => {
  const { strategyId } = useParams()
  const dashboardItems = useGetObjectsByProperties('recurringReportConfiguration', {
    strategyId,
    service: RecurringReportConfigurationServiceEnum.EmitReport
  })

  const sortedDashboardItems = dashboardItems.sort((a, b) => (a.position || 0) - (b.position || 0))

  return (
    <>
      <Outlet />
      <DndProvider backend={HTML5Backend}>
        <Container maxW="8xl" px={4} py={6}>
          <SimpleGrid minChildWidth={minChildWidth} gap={6}>
            <For each={sortedDashboardItems} fallback={<EmptyState title="No AI reports created yet" />}>
              {(item) => (
                <DashboardCard
                  key={item.id}
                  recurringReportConfiguration={item as DashboardItem}
                  index={item.position}
                />
              )}
            </For>
            <DashboardCardNew />
          </SimpleGrid>
        </Container>
      </DndProvider>
    </>
  )
}

export default Index
