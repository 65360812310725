import type { Channel } from '@anycable/web'
import cable from '@cable/client'
import omit from 'lodash/omit'
import queryString from 'query-string'
import type { Dispatch } from 'react'
import { useEffect, useMemo, useReducer, useState } from 'react'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import type { User } from '@graphql/types'

type PresenceUser = Pick<User, 'id' | 'name' | 'email'>
export type Payload =
  | { action: 'addUser'; user: PresenceUser | null }
  | { action: 'removeUser'; userId: string | null }
  | { action: 'userInfo' }
  | { action: 'updatePosition'; userId: string; position: { x: number; y: number } | null }

export type UserPresence = {
  user: PresenceUser
  position?: { x: number; y: number }
}
export type MapPresenceState = Record<string, UserPresence>
export type MapPresenceChannel = Channel<{ strategy_id: string; token?: string }, Payload>
type UseMapPresences = (strategyId: string) => [MapPresenceState, Dispatch<Payload>, MapPresenceChannel]

const reducer = (state: MapPresenceState, payload: Payload): MapPresenceState => {
  switch (payload.action) {
    case 'addUser':
      if (!payload.user) {
        return state
      }

      return {
        ...state,
        [payload.user.id]: { ...(state[payload.user.id] || {}), user: payload.user }
      }
    case 'removeUser':
      if (!payload.userId || !state[payload.userId]) {
        return state
      }

      return omit(state, payload.userId)

    case 'updatePosition':
      if (!state[payload.userId]) {
        return state
      }

      return {
        ...state,
        [payload.userId]: {
          ...state[payload.userId],
          position: payload.position
        }
      }
    default:
      return state
  }
}

const useMapPresences: UseMapPresences = (strategyId) => {
  const { user } = useStoreCurrentUser()
  const [state, dispatch] = useReducer<typeof reducer, MapPresenceState>(reducer, null, () => ({}))

  const [channel] = useState(() => {
    if (!user) {
      return
    }

    const { token } = queryString.parse(window.location.search)
    const chan = cable.subscribeTo('MapPresencesChannel', {
      strategy_id: strategyId,
      token: Array.isArray(token) ? token[0] : token
    })

    chan.on('connect', () => {
      const u: Payload = {
        action: 'addUser',
        user
      }

      dispatch(u) // add myself
      // ask for everyone else
      chan.whisper({
        action: 'userInfo'
      })
      chan.whisper(u) // let others know I'm here
    })

    chan.on('message', (payload: Payload) => {
      if (payload.action === 'userInfo') {
        chan.whisper({
          action: 'addUser',
          user
        })

        return
      }

      // Ignore removeUser messages for the current user (e.g. they closed a second tab with the same map open)
      if (payload.action === 'removeUser' && payload.userId === user?.id) {
        return
      }

      dispatch(payload)
    })

    return chan as MapPresenceChannel
  })

  useEffect(() => {
    const unloadCallback = (_event) => {
      channel.whisper({
        action: 'removeUser',
        userId: user?.id
      })
    }

    // handle the user closing the browser, rather than navigating away from the map
    window.addEventListener('beforeunload', unloadCallback)

    return () => {
      window.removeEventListener('beforeunload', unloadCallback)

      unloadCallback(null)

      channel.disconnect()
    }
  }, [channel, user?.id])

  return useMemo(() => [state, dispatch, channel], [state, channel])
}

export default useMapPresences
