import { IconButton } from '@chakra-ui/react'
import type { FC } from 'react'

import { MenuContent, MenuItem, MenuRoot, MenuTrigger } from '@app/components/ui/menu'
import d3Hierarchy from '@app/hooks/autolayout/algorithms/d3Hierarchy'
import dagre from '@app/hooks/autolayout/algorithms/dagre'
import elkjs from '@app/hooks/autolayout/algorithms/elk'
import type { LayoutAlgorithmOptions } from '@app/hooks/autolayout/types'
import { getSourceHandlePosition, getTargetHandlePosition } from '@app/hooks/autolayout/utils'
import useGetEdges from '@app/hooks/useGetEdges'
import useGetNodes from '@app/hooks/useGetNodes'
import Can from '@app/shared/authorization/can'
import { useStore } from '@app/store'
import ICON_MAP from '@app/utils/iconMap'

type Props = {
  strategyId: string
}

const AutoLayoutButton: FC<Props> = ({ strategyId }) => {
  const options: LayoutAlgorithmOptions = { direction: 'BT', spacing: [500, 500] }
  const storeNodes = useGetNodes(strategyId)
  const storeEdges = useGetEdges(strategyId)
  const updateObjects = useStore.use.updateObjects()

  const elements = {
    nodes: storeNodes,
    edges: storeEdges
  }

  const layoutAlgorithms = {
    dagre,
    'd3-hierarchy': d3Hierarchy,
    elk: elkjs
  }

  const runLayout = async (algorithmName: keyof typeof layoutAlgorithms) => {
    if (elements.nodes.length === 0) {
      return
    }

    const layoutAlgorithm = layoutAlgorithms[algorithmName]

    if (!layoutAlgorithm) {
      throw new Error(`No layout algorithm found for ${algorithmName}`)
    }

    const nodes = elements.nodes.map((node) => ({ ...node }))
    const edges = elements.edges.map((edge) => ({ ...edge }))

    const { nodes: nextNodes } = await layoutAlgorithm(nodes, edges, options)

    for (const node of nextNodes) {
      node.style = { ...node.style, opacity: 1 }
      node.sourcePosition = getSourceHandlePosition(options.direction)
      node.targetPosition = getTargetHandlePosition(options.direction)
    }

    // This shouldn't be done here but until we settle on an algorithm it's a better solution till we can have it configured to return integers
    // create an array of objects with the format { node: { id: node.id, position: { x, y } }.  Ensure all x,y positions are integers
    const objects = nextNodes.map((node) => {
      const updateObject = {
        // @ts-expect-error - we are not using the full node object
        node: { id: node.nodeId, position: { x: Math.round(node.position.x), y: Math.round(node.position.y) } }
      }
      return updateObject
    })

    updateObjects(objects)
  }

  return (
    <Can I="view" an="admin">
      <MenuRoot>
        <MenuTrigger aria-label="Run layout cleanup" colorPalette="gray" asChild>
          <IconButton size="2xs" variant="ghost">
            <ICON_MAP.ReLayout />
          </IconButton>
        </MenuTrigger>
        <MenuContent>
          <MenuItem value="Dagre" onClick={() => runLayout('dagre')}>
            DaGre
          </MenuItem>
          <MenuItem value="D3 Hierarchy" onClick={() => runLayout('d3-hierarchy')}>
            D3 Hierarchy
          </MenuItem>
          <MenuItem value="Elk" onClick={() => runLayout('elk')}>
            Elk
          </MenuItem>
        </MenuContent>
      </MenuRoot>
    </Can>
  )
}

export default AutoLayoutButton
