import sortBy from 'lodash/sortBy'

import type { GraphAggregate } from '@app/types'
import { ImpactEnum } from '@graphql/queries'
import type { Entity, MetricDataPoint } from '@graphql/types'

export const middleOfDay = (date: string) => new Date(date).setHours(12, 0, 0, 0)

export const buildGraphEvents = (data: GraphAggregate) => {
  const events = Object.keys(data?.buckets || {}).map((bucket) => ({
    x: new Date(`${bucket}T12:00:00`),
    y: data?.buckets[bucket].doc_count
  }))

  return sortBy(events, 'x')
}

export type ImpactfulEntity = Pick<Entity, 'id' | 'happenedAt' | 'impact' | 'name'>

export const buildGraphImpactBars = (
  impactfulEntities: ImpactfulEntity[],
  impactPositiveColor: string,
  impactNegativeColor: string
) =>
  impactfulEntities?.map((entity) => ({
    id: entity.id,
    drawTime: 'afterDatasetsDraw',
    type: 'line',
    xMin: middleOfDay(entity.happenedAt),
    xMax: middleOfDay(entity.happenedAt),
    enter: ({ element }) => {
      element.label.options.display = true
      return true
    },
    leave: ({ element }) => {
      element.label.options.display = false
      return true
    },
    label: {
      position: 'start',
      backgroundColor: entity.impact === ImpactEnum.Positive ? impactPositiveColor : impactNegativeColor,
      color: '#FFF',
      content: entity.name,
      font: {
        family: 'sans-serif'
      }
    },
    backgroundColor: '#aaaaaa',
    borderColor: entity.impact === ImpactEnum.Positive ? impactPositiveColor : impactNegativeColor,
    borderWidth: 2,
    impact: entity.impact
  })) || []

export const buildGraphMetrics = (metricDataPoints: Pick<MetricDataPoint, 'date' | 'value' | 'forecasted'>[]) =>
  metricDataPoints?.map((mdp) => ({
    x: new Date(`${mdp.date}T12:00:00`),
    y: mdp.value,
    forecasted: mdp.forecasted
  })) || []
