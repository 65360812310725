import { HStack, Icon, IconButton, Spacer, Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { PiArrowsClockwise } from 'react-icons/pi'
import { Link } from 'react-router-dom'

import RecurringReportConfigurationDelete from './recurringReportConfigurationDelete'

import { MenuContent, MenuItem, MenuRoot, MenuTrigger } from '@app/components/ui/menu'
import RecurringReportConfigurationRunButton from '@app/pages/reports/recurring/components/recurringReportConfigurationRunButton'
import ICON_MAP from '@app/utils/iconMap'
import type { RecurringReportConfiguration } from '@graphql/types'

type Config = Pick<RecurringReportConfiguration, 'id' | 'name' | 'scheduleDisplay'>

type ListItemProps = {
  recurringReportConfiguration: Config
}

type ListProps = {
  recurringReportConfigurations: Config[]
}

const DrawerListItem: FC<ListItemProps> = ({ recurringReportConfiguration }) => {
  const { id, name, scheduleDisplay } = recurringReportConfiguration

  return (
    <Stack gap={1} px={4} py={3} _hover={{ bgColor: 'bg.subtle' }}>
      <HStack>
        <Icon>
          <PiArrowsClockwise />
        </Icon>
        <Stack gap={0}>
          <Text asChild>
            <Link to={`recurring/${id}/edit`}>{name}</Link>
          </Text>
          <Text color="subtle" fontSize="sm">
            {scheduleDisplay}
          </Text>
        </Stack>
        <Spacer />
        <MenuRoot>
          <MenuTrigger fontSize="sm" aria-label="Additional actions" asChild>
            <IconButton aria-label="Additional actions" variant="ghost">
              <Icon>
                <ICON_MAP.Ellipsis />
              </Icon>
            </IconButton>
          </MenuTrigger>
          <MenuContent>
            <RecurringReportConfigurationRunButton recurringReportConfigurationId={id} />
            <MenuItem value="delete_configuration" asChild>
              <RecurringReportConfigurationDelete recurringReportConfigurationId={id} />
            </MenuItem>
          </MenuContent>
        </MenuRoot>
      </HStack>
    </Stack>
  )
}

const DrawerList: FC<ListProps> = ({ recurringReportConfigurations }) => (
  <Stack>
    {recurringReportConfigurations.map((config) => (
      <DrawerListItem key={`recurring-report-list-item-${config.id}`} recurringReportConfiguration={config} />
    ))}
  </Stack>
)

export default DrawerList
