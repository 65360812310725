import { Box, IconButton } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'

import SuggestDownstreamMetricsButton from './suggestDownstreamMetricsButton'
import SuggestProductInitiativesButton from './suggestProductInitiativesButton'

import { Button } from '@app/components/ui/button'
import type { ButtonProps } from '@app/components/ui/button'
import { PopoverRoot, PopoverTrigger, PopoverContent, PopoverBody } from '@app/components/ui/popover'
import { Tooltip } from '@app/components/ui/tooltip'
import type { DomainNode } from '@app/types'
import ICON_MAP from '@app/utils/iconMap'

const buttonStyles = {
  borderRadius: 'none'
}

const bodyButtonStyles: Partial<ButtonProps> = {
  w: '100%',
  px: 2,
  outline: 'none',
  variant: 'ghost',
  textAlign: 'start'
}

type Props = {
  node: Pick<DomainNode, 'nodeId' | 'data'>
}

const AiAssistantButton: FC<Props> = ({ node }) => {
  // The popover is controlled because PopoverTrigger and Tooltip will not work together by default, and
  // putting another component around it to catch the Tooltip ref will break the Group attached behavior
  const [open, setOpen] = useState(false)

  return (
    <PopoverRoot portalled open={open}>
      <PopoverTrigger asChild>
        <Tooltip content="AI Assistant">
          <IconButton
            aria-label="AI Assistant"
            onClick={() => setOpen(!open)}
            {...buttonStyles}
            size="sm"
            variant="outline"
          >
            <ICON_MAP.AiPrompt />
          </IconButton>
        </Tooltip>
      </PopoverTrigger>
      <PopoverContent w="fit-content">
        <PopoverBody>
          <Box w="fit-content">
            <SuggestProductInitiativesButton node={node} onComplete={() => setOpen(false)}>
              {({ onClick }) => (
                <Button onClick={onClick} {...bodyButtonStyles}>
                  Suggest initiative name
                </Button>
              )}
            </SuggestProductInitiativesButton>
            <SuggestDownstreamMetricsButton node={node}>
              {({ onClick }) => {
                const closer: typeof onClick = (e) => {
                  onClick(e)
                  setOpen(false)
                }

                return (
                  <Button onClick={closer} {...bodyButtonStyles}>
                    Suggest downstream metrics
                  </Button>
                )
              }}
            </SuggestDownstreamMetricsButton>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </PopoverRoot>
  )
}

export default AiAssistantButton
