import { Icon, IconButton } from '@chakra-ui/react'
import { Resizable } from 're-resizable'
import { useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { PiArrowLineRight } from 'react-icons/pi'
import { Outlet } from 'react-router-dom'

import useCookie from '@app/hooks/useCookie'
import { AI_CHAT_DIMENSIONS_COOKIE } from '@app/lib/globals'
import { useDrawerContext } from '@app/pages/maps/drawerContext'

const CustomHandle = (props) => (
  <IconButton
    minW={0}
    p={0}
    cursor="col-resize"
    aria-label="Close"
    colorPalette="gray"
    size="lg"
    variant="subtle"
    {...props}
  >
    <Icon boxSize={4} p={0}>
      <PiArrowLineRight />
    </Icon>
  </IconButton>
)

const ChatDrawer = () => {
  const [chatDimensions, setChatDimensions] = useCookie(AI_CHAT_DIMENSIONS_COOKIE)
  const storedWidth = chatDimensions ? JSON.parse(chatDimensions).width : 600
  const [width] = useState<number>(storedWidth)
  const { onDrawerClose } = useDrawerContext()

  useHotkeys('esc', () => onDrawerClose())

  const handleResize = (_event, _direction, ref, _delta) => {
    setChatDimensions(JSON.stringify({ width: ref.clientWidth, height: ref.clientHeight }))
  }

  return (
    <Resizable
      style={{
        position: 'absolute',
        overflow: 'hidden',
        top: '24px',
        bottom: '80px',
        left: '74px',
        borderRadius: 'var(--chakra-radii-sm)',
        backgroundColor: 'var(--chakra-colors-bg)',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
      }}
      minWidth={600}
      maxWidth="95%"
      defaultSize={{ width }}
      enable={{
        top: false,
        right: true,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false
      }}
      onResizeStop={handleResize}
      handleComponent={{
        right: <CustomHandle />
      }}
      handleStyles={{
        right: {
          width: 'auto',
          top: '50px',
          height: '83%',
          right: '16px',
          alignContent: 'center'
        }
      }}
    >
      <Outlet />
    </Resizable>
  )
}

export default ChatDrawer
