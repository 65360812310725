import type { RouteObject } from 'react-router-dom'

import AiDashboard from '@app/pages/aiDashboard'
import Show from '@app/pages/aiDashboard/show'
import ReportRoutes from '@app/routes/reports'
import { useStore } from '@app/store'
import { RecurringReportConfigurationsByService } from '@graphql/documents/recurring_report_configuration.graphql'
import { RecurringReportConfigurationServiceEnum } from '@graphql/types'

const loadDashboardItems = async ({ params }) => {
  const strategyId = params.strategyId
  const { loaderQuery } = useStore.getState()

  const res = await loaderQuery(RecurringReportConfigurationsByService, {
    strategyId,
    service: RecurringReportConfigurationServiceEnum.EmitReport
  })
  const dashboardItems = res?.data?.recurringReportConfigurationsByService || []

  return { dashboardItems }
}

const routes: RouteObject = {
  path: 'dashboard',
  element: <AiDashboard />,
  loader: loadDashboardItems,
  children: [
    {
      path: ':recurringReportId',
      element: <Show />
    },
    ReportRoutes
  ]
}

export default routes
