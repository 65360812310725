import { Group, HStack, Input, Spacer, Stack, StackSeparator, Textarea } from '@chakra-ui/react'
import type { FC } from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import { Form, useLocation } from 'react-router-dom'
import type { FormProps } from 'react-router-dom'
import { RRule } from 'rrule'

import ChannelInput from '../../components/channelInput'

import { Button } from '@app/components/ui/button'
import { Field } from '@app/components/ui/field'
import { toaster } from '@app/components/ui/toaster'
import RRuleInput from '@app/next/forms/rruleInput'
import useForm from '@app/next/forms/useForm'
import ServiceSelect from '@app/pages/reports/recurring/components/serviceSelect'
import type { DomainStrategy } from '@app/types'
import type { RecurringReportConfigurationQuery } from '@graphql/queries'
import { useRecurringReportConfigurationUpdateMutation } from '@graphql/queries'
import { RecurringReportConfigurationServiceEnum } from '@graphql/types'

type Props = FormProps & {
  strategy?: DomainStrategy
  recurringReportConfiguration?: RecurringReportConfigurationQuery['recurringReportConfiguration']
  onCancel?: () => void
}

const RecurringReportConfigurationForm: FC<Props> = ({
  strategy,
  recurringReportConfiguration,
  onCancel,
  ...formProps
}) => {
  const { state } = useLocation()
  const { errors } = useForm({})
  const [service, setService] = useState<RecurringReportConfigurationServiceEnum | null>(null)
  const [, updateRecurringReport] = useRecurringReportConfigurationUpdateMutation()
  const formRef = useRef<HTMLFormElement>(null)
  // const serviceRef = useRef<HTMLSelectElement>(null)
  let defaultNameValue = recurringReportConfiguration?.name
  let defaultServiceSelectValue = recurringReportConfiguration?.service

  if (state && state.reportType) {
    defaultNameValue = `${strategy?.name} - ${state.reportType === RecurringReportConfigurationServiceEnum.SendGoals ? 'Key Results' : 'Changelog'}`
    defaultServiceSelectValue = state.reportType
  }

  const handleFormChange = (e) => {
    if (e.target.name === 'service') {
      setService(e.target.value)
    }
  }

  const handleSaveAndRun = async (_e) => {
    const formData = new FormData(formRef.current)
    const input = Object.fromEntries(formData.entries()) as Record<string, string>

    await updateRecurringReport({
      input: {
        ...input,
        recurringReportConfigurationId: recurringReportConfiguration.id,
        name: input.name || 'Untitled Report',
        schedule: input.schedule || 'FREQ=WEEKLY;BYDAY=MO',
        service:
          (input.service as RecurringReportConfigurationServiceEnum) ||
          RecurringReportConfigurationServiceEnum.EmitReport
      }
    })

    toaster.create({
      title: 'Report updated!',
      description: 'Your recurring report was successfully saved',
      type: 'success'
    })
  }

  return (
    <Form {...formProps} ref={formRef} onChange={(e) => handleFormChange(e)}>
      <Stack gap={4} pt={4} separator={<StackSeparator />}>
        <Field label="Report title" required invalid={!!errors?.name} errorText={errors?.name?.message}>
          <Input autoComplete="off" defaultValue={defaultNameValue} name="name" placeholder="Untitled" />
        </Field>
        <RRuleInput
          defaultValue={recurringReportConfiguration?.schedule}
          name="schedule"
          availableFrequencies={[RRule.DAILY, RRule.WEEKLY, RRule.MONTHLY]}
        />
        <Field label="Action" required>
          <ServiceSelect defaultValue={defaultServiceSelectValue} />
        </Field>
        {[null, RecurringReportConfigurationServiceEnum.EmitReport].includes(service) && (
          <Field required label="AI prompt" invalid={!!errors?.aiPrompt} errorText={errors?.aiPrompt?.message}>
            <Textarea
              autoComplete="off"
              defaultValue={recurringReportConfiguration?.aiPrompt}
              name="aiPrompt"
              placeholder="Summarize the work and metrics that are on the map."
            />
            {recurringReportConfiguration && (
              <HStack w="full">
                <Spacer />
                <Button size="xs" variant="surface" onClick={handleSaveAndRun}>
                  Save & Run now
                </Button>
              </HStack>
            )}
          </Field>
        )}
        <ChannelInput report={recurringReportConfiguration} />
        <Group justifyContent="end">
          <Button onClick={onCancel} variant="outline">
            Cancel
          </Button>
          <Button type="submit">Save</Button>
        </Group>
      </Stack>
    </Form>
  )
}

export default RecurringReportConfigurationForm
