import { type FC } from 'react'

import type { ButtonProps } from '@app/components/ui/button'
import { ClipboardButton, ClipboardRoot } from '@app/components/ui/clipboard'
import type { DomainStrategy } from '@app/types'
import { SharingTypesEnum } from '@graphql/types'

type Props = ButtonProps & {
  strategy: DomainStrategy
}

const CopyEmbedCodeButton: FC<Props> = ({ strategy }) => {
  const { id, sharing } = strategy
  const baseUrl = `${window.location.protocol}//${window.location.host}/strategy/embed/${id}/map`
  const strategyToken = sharing === SharingTypesEnum.TokenAccess ? strategy.token : null

  const url = strategyToken ? `${baseUrl}?token=${strategyToken}` : baseUrl
  const embedCode = `<iframe src="${url}" width="100%" height="500" frameborder="0" allowfullscreen></iframe>`

  return (
    <ClipboardRoot value={embedCode}>
      <ClipboardButton size="sm" />
    </ClipboardRoot>
  )
}

export default CopyEmbedCodeButton
