import { Container, Stack } from '@chakra-ui/react'
import groupBy from 'lodash/groupBy'
import type { FC } from 'react'
import { memo, useState } from 'react'

import { EmptyState } from '@app/components/ui/empty-state'
import TasksFetcher from '@app/fetchers/tasksFetcher'
import useGetObjectPage from '@app/hooks/useGetObjectPage'
import useGetObjects from '@app/hooks/useGetObjects'
import TaskDateGroup from '@app/pages/tasks/components/taskDateGroup'
import { PageStack } from '@app/shared/layout'
import Pagination from '@app/shared/pagination'

const Tasks: FC = () => {
  const [loading, setLoading] = useState(false)
  const { collection, metadata } = useGetObjectPage('task')
  const newTasks = useGetObjects('task')
  const tasks = collection
  const firstTaskId = tasks?.[0]?.id || '0'
  const appendNewlyCreatedTasks = (firstTaskId || loading === false) && metadata?.page === 1
  let fauxSubscriptionTasks = []

  // Since the task list is paginated, new store tasks created via subscription will not be displayed.
  // We grab all the tasks off the store and filter to just the ones with a higher ID than the first task in the list.
  // This only occurs on page 1 of the task list.
  if (appendNewlyCreatedTasks) {
    fauxSubscriptionTasks = newTasks.filter((task) => parseInt(task.id, 10) > parseInt(firstTaskId, 10))
  }

  // Group tasks by date and sort by id
  const groupedTasks = groupBy(
    fauxSubscriptionTasks.concat(tasks).sort((a, b) => parseInt(b.id, 10) - parseInt(a.id, 10)) || [],
    (task) => new Intl.DateTimeFormat().format(new Date(task.updatedAt))
  )

  return (
    <PageStack>
      <Container maxW="3xl">
        <TasksFetcher loading={loading} setLoading={setLoading} />
        {loading === false && (!groupedTasks || Object.keys(groupedTasks).length === 0) && (
          <EmptyState title="No tasks yet" description="Connect some metric to see import data appear here." />
        )}
        <Stack gap={8}>
          {Object.entries(groupedTasks).map(([date, taskGroup]) => (
            <TaskDateGroup key={date} date={date} tasks={taskGroup} />
          ))}
        </Stack>
        <Pagination px={6} py={4} {...{ ...metadata }} scrollContainer="main" />
      </Container>
    </PageStack>
  )
}

export default memo(Tasks)
