import { IconButton } from '@chakra-ui/react'
import ahoy from 'ahoy.js'
import type { FC } from 'react'
import { useRef, useState } from 'react'
import { FiTrash2 } from 'react-icons/fi'

import { Button } from '@app/components/ui/button'
import {
  DialogActionTrigger,
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle
} from '@app/components/ui/dialog'
import { Tooltip } from '@app/components/ui/tooltip'
import { useStore } from '@app/store'

interface Props {
  entityId: string
  onDelete?: () => void
}

const EntityDeleteButton: FC<Props> = ({ entityId, onDelete = () => {} }) => {
  const [open, setOpen] = useState(false)
  const cancelRef = useRef()

  const deleteObject = useStore.use.deleteObject()

  const deleteEntity = () => {
    setOpen(false)
    deleteObject({ id: entityId, classType: 'entity' }, { updatePageCounts: true }).then(() => {
      ahoy.track('entity:deleted', {
        entity_id: entityId
      })

      onDelete()
    })
  }

  return (
    <>
      <Tooltip content="Delete event">
        <IconButton
          aria-label="Delete event"
          colorPalette="gray"
          onClick={() => setOpen(true)}
          size="xs"
          variant="ghost"
        >
          <FiTrash2 />
        </IconButton>
      </Tooltip>

      <DialogRoot
        initialFocusEl={cancelRef?.current}
        onOpenChange={(e) => setOpen(e.open)}
        open={open}
        placement="center"
        role="alertdialog"
      >
        <DialogBackdrop />
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete event</DialogTitle>
          </DialogHeader>

          <DialogBody>
            Are you sure you want to delete all the data for this metric? This can&apos;t be undone.
          </DialogBody>

          <DialogFooter>
            <DialogActionTrigger asChild>
              <Button ref={cancelRef} variant="outline">
                Cancel
              </Button>
            </DialogActionTrigger>
            <Button ml={3} bgColor="error" onClick={deleteEntity}>
              Delete
            </Button>
          </DialogFooter>
          <DialogCloseTrigger />
        </DialogContent>
      </DialogRoot>
    </>
  )
}

export default EntityDeleteButton
