import isEmpty from 'lodash/isEmpty'
import type { FC } from 'react'
import { memo, useRef } from 'react'

import Node from './components/node'
import BasicCardToolbar from './components/toolbar/basicCardToolbar'

import useGetNode from '@app/hooks/useGetNode'
import BasicCard from '@app/shared/cards/basicCard/basicCard'
import type { DomainNode } from '@app/types'

const BasicCardNode: FC<DomainNode> = (rfNode) => {
  const { id } = rfNode
  const node = useGetNode(id)
  const ref = useRef()
  const strategyId = node?.strategyId

  const basicCard = node?.data

  if (!basicCard || isEmpty(basicCard)) {
    return null
  }

  return (
    <Node
      topHandle={{ type: 'source' }}
      leftHandle={{ type: 'source' }}
      rightHandle={{ type: 'source' }}
      bottomHandle={{ type: 'source' }}
      nodeRef={ref}
      node={node}
    >
      <BasicCardToolbar node={node} />
      <BasicCard strategyId={strategyId} basicCard={basicCard} node={node} />
    </Node>
  )
}

export default memo(BasicCardNode)
