import { Box, Center, HStack, Link as ChakraLink, Stack, useCheckboxGroup } from '@chakra-ui/react'
import type { FC } from 'react'
import { memo, useCallback, useMemo } from 'react'
import { Link, useSearchParams } from 'react-router-dom'

import MetricDataPoints from './components/columns/metricDataPoints'
import MetricName from './components/columns/metricName'
import MetricSourceName from './components/columns/metricSourceName'

import { Button } from '@app/components/ui/button'
import { Checkbox } from '@app/components/ui/checkbox'
import { EmptyState } from '@app/components/ui/empty-state'
import useGetObjectPage from '@app/hooks/useGetObjectPage'
import MapFilterSelectInput from '@app/next/forms/mapFilterSelectInput'
import ListActionsMenu from '@app/pages/metrics/components/listActionsMenu'
import MetricListActions from '@app/pages/metrics/components/metricListActions'
import { ListFilterContextProvider } from '@app/pages/shared/listFilterContext'
import MultiOps from '@app/pages/shared/multiOps'
import SearchRow from '@app/pages/shared/searchRow'
import SelectAllCheckboxHeader from '@app/pages/shared/selectAllCheckboxHeader'
import useGenerateSelectableTableRowFormatter from '@app/pages/shared/useGenerateSelectableTableRowFormatter'
import Can from '@app/shared/authorization/can'
import { PageHeader, PageStack } from '@app/shared/layout'
import SuspenseLoader from '@app/shared/loaders/suspenseLoader'
import Pagination from '@app/shared/pagination'
import Table from '@app/shared/table'
import SortHeader from '@app/shared/table/components/sortHeader'
import { SortHeaderContextProvider } from '@app/shared/table/contexts/sortHeaderContext'
import type { Column } from '@app/shared/table/types'
import withAwait from '@app/shared/withAwait'
import type { DomainMetric } from '@app/types'
import { Routes } from '@app/utils/routeHelpers'
import type { Metric } from '@graphql/types'

type MakeColumns = (args: {
  getCheckboxProps: ReturnType<typeof useCheckboxGroup>['getItemProps']
  selected: ReturnType<typeof useCheckboxGroup>['value']
  setSelected: ReturnType<typeof useCheckboxGroup>['setValue']
}) => Column<Metric>[]

const SEARCH_FIELD = 'filter'

const makeColumns: MakeColumns = ({ getCheckboxProps, selected, setSelected }) => [
  {
    header: <SelectAllCheckboxHeader collectionType="metric" selected={selected} setSelected={setSelected} />,
    width: '5%',
    key: 'checkbox',
    cell: ({ data: metric }) => (
      <Center>
        <Checkbox {...getCheckboxProps({ value: metric.id })} />
      </Center>
    )
  },
  {
    header: <SortHeader field="name" title="Name" emptyState />,
    key: 'name',
    width: '40%',
    cell: MetricName
  },
  {
    header: <SortHeader field="metricDataPointsCount" title="Data Points" emptyState />,
    key: 'metricDataPointsCount',
    cell: MetricDataPoints
  },
  {
    header: <SortHeader field="sourceName" title="Source" emptyState />,
    key: 'sourceName',
    cell: MetricSourceName
  },
  {
    header: '',
    cell: MetricListActions
  }
]

const awaited = (Component: FC) =>
  withAwait(
    Component,
    'metrics',
    <Box>
      <SuspenseLoader />
    </Box>
  )

const Empty = ({ strategyId }) => (
  <Box p={2}>
    <SearchRow
      leftChild={<MapFilterSelectInput field="strategyId" defaultValue={strategyId} />}
      page={0}
      limitValue={0}
      totalCount={0}
      searchField={SEARCH_FIELD}
    />
    <EmptyState title="No metrics" description="Add some metrics on your maps to see them here.">
      <Button asChild variant="subtle" size="sm">
        <Link to="new">Create metric</Link>
      </Button>
    </EmptyState>
  </Box>
)

const AwaitedEmpty = awaited(Empty)

const Populated = ({ strategyId, collection, metadata }) => {
  const { page, totalCount } = metadata
  const {
    value: selected,
    getItemProps: getCheckboxProps,
    setValue: setSelected
  } = useCheckboxGroup({
    defaultValue: []
  })
  const columns = useMemo(
    () => makeColumns({ getCheckboxProps, selected, setSelected }),
    [getCheckboxProps, selected, setSelected]
  )
  const onBulkDelete = useCallback(() => {
    setSelected([])
  }, [setSelected])
  const baseRowProps = useGenerateSelectableTableRowFormatter<DomainMetric>('metric', selected, setSelected)
  const rowFormatter = useCallback(
    (metric: Metric) => {
      if (metric.metricSourceErrored) {
        return {
          ...baseRowProps(metric),
          bg: 'bg.subtle'
        }
      }

      return baseRowProps(metric)
    },
    [baseRowProps]
  )

  return (
    <Stack gap="0">
      <SearchRow
        leftChild={<MapFilterSelectInput field="strategyId" defaultValue={strategyId} />}
        leftSummarySibling={<MultiOps type="metric" selected={selected} fontSize="sm" onDelete={onBulkDelete} />}
        page={page}
        limitValue={collection.length}
        totalCount={totalCount}
        searchField={SEARCH_FIELD}
      />
      <Box overflow="auto">
        <SortHeaderContextProvider defaultState={{ name: 'asc' }}>
          <Table<(typeof collection)[0]> columns={columns} data={collection} rowFormatter={rowFormatter} />
        </SortHeaderContextProvider>
        <Pagination px={6} py={4} {...{ ...metadata }} scrollContainer="main" />
      </Box>
    </Stack>
  )
}

const AwaitedPopulated = awaited(Populated)

const List: FC = () => {
  const { collection, metadata } = useGetObjectPage('metric')

  const [searchParams] = useSearchParams()
  const strategyId = searchParams.get('strategyId')

  const subtitle = (
    <>
      Track the leading and lagging indicators that drive your business.{' '}
      <ChakraLink color="link" href={Routes.docsUsingMetrics} rel="noopener noreferrer" target="_blank">
        Learn more.
      </ChakraLink>
    </>
  )

  return (
    <ListFilterContextProvider searchField={SEARCH_FIELD}>
      <PageStack>
        <PageHeader title="Metrics" subtitle={subtitle}>
          <Can I="create" a="metric">
            <HStack>
              <Button asChild>
                <Link to="new">Create Metric</Link>
              </Button>
              <ListActionsMenu />
            </HStack>
          </Can>
        </PageHeader>
        <Box>
          {collection.length ? (
            <AwaitedPopulated strategyId={strategyId} collection={collection} metadata={metadata} />
          ) : (
            <AwaitedEmpty strategyId={strategyId} />
          )}
        </Box>
      </PageStack>
    </ListFilterContextProvider>
  )
}

export default memo(List)
