import type { Popover } from '@chakra-ui/react'
import { Group, Stack, Text } from '@chakra-ui/react'
import type { FC, PropsWithChildren } from 'react'
import { useState } from 'react'

import { BulkLabelsContextProvider, useBulkLabelsContext } from './bulkLabelsContext'

import { Button } from '@app/components/ui/button'
import { Checkbox } from '@app/components/ui/checkbox'
import {
  PopoverArrow,
  PopoverBody,
  PopoverCloseTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverRoot,
  PopoverTitle,
  PopoverTrigger
} from '@app/components/ui/popover'
import LabelsAutocomplete from '@app/shared/autocomplete/labels/labelsAutocomplete'
import { useStore } from '@app/store'
import type { DomainBasicCard, DomainEntity, DomainMetric, DomainStrategy } from '@app/types'
import type { Label } from '@graphql/types'

export type BulkLabelableObject = Omit<DomainBasicCard | DomainEntity | DomainMetric | DomainStrategy, 'labels'> & {
  labels: Pick<Label, 'name'>[]
}

const Checkboxes: FC = () => {
  const { labels, dispatch } = useBulkLabelsContext()

  return Object.keys(labels)
    .sort()
    .map((label) => {
      const state = labels[label]
      const isChecked = state === 'checked'
      const isIndeterminate = state === 'indeterminate'

      const onChange = (event) => {
        const { checked } = event

        dispatch({
          type: checked ? 'check' : 'uncheck',
          payload: label
        })
      }

      return (
        <Checkbox key={label} checked={isIndeterminate ? 'indeterminate' : isChecked} onCheckedChange={onChange}>
          <Text>{label}</Text>
        </Checkbox>
      )
    })
}

type BodyProps = {
  domainObjects: BulkLabelableObject[]
  onClose: () => void
}
const Body: FC<BodyProps> = ({ domainObjects, onClose }) => {
  const bulkLabel = useStore.use.bulkLabel()
  const [loading, setLoading] = useState(false)
  const { labels, dispatch } = useBulkLabelsContext()

  const updateLabels = async () => {
    const added = Object.keys(labels).filter((label) => labels[label] === 'checked')
    const removed = Object.keys(labels).filter((label) => labels[label] === 'unchecked')

    setLoading(true)

    try {
      await bulkLabel(domainObjects, added, removed)
      onClose()
    } finally {
      setLoading(false)
    }
  }

  const onChange = (newValue) => {
    dispatch({
      type: 'check',
      payload: newValue.label
    })
  }

  return (
    <Stack>
      <LabelsAutocomplete
        placeholder={null}
        onChange={onChange}
        value={null}
        menuPortalTarget={document.body}
        isMulti={false}
      />

      <Checkboxes />
      <Group justifyContent="flex-end" display="flex">
        <Button onClick={onClose} variant="outline">
          Cancel
        </Button>
        <Button loading={loading} onClick={updateLabels}>
          Update
        </Button>
      </Group>
    </Stack>
  )
}

type Props = PropsWithChildren<Omit<Popover.RootProps, 'children'>> & Pick<BodyProps, 'domainObjects'>

const BulkLabelsPopover: FC<Props> = ({ domainObjects, children, ...rest }) => {
  const [open, setOpen] = useState(false)

  return (
    <PopoverRoot
      portalled
      open={open}
      onOpenChange={(e) => setOpen(e.open)}
      lazyMount
      {...rest}
      closeOnInteractOutside={false}
    >
      <PopoverTrigger asChild>{children}</PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseTrigger />
        <PopoverHeader>
          <PopoverTitle>Labels</PopoverTitle>
        </PopoverHeader>
        <PopoverBody>
          <BulkLabelsContextProvider domainObjects={domainObjects}>
            <Body domainObjects={domainObjects} onClose={() => setOpen(false)} />
          </BulkLabelsContextProvider>
        </PopoverBody>
      </PopoverContent>
    </PopoverRoot>
  )
}

export default BulkLabelsPopover
