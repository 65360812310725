import { Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'

import { Field } from '@app/components/ui/field'
import useGetObject from '@app/hooks/useGetObject'
import ShowFieldToggle from '@app/pages/shared/showFieldToggle'
import type { DomainBasicCard, DomainEntity, DomainMetric, DomainNode, DomainStrategy } from '@app/types'

interface BasicCardFieldsProps {
  basicCard?: DomainBasicCard
}

const BasicCardFields: FC<BasicCardFieldsProps> = ({ basicCard }) => {
  if (!basicCard) {
    return null
  }

  return (
    <Stack gap={4}>
      <Field label="Show description?">
        <ShowFieldToggle domainObject={basicCard} name="showDescription" defaultValue={basicCard?.showDescription} />
      </Field>
      <Field label="Show goal / hypothesis?">
        <ShowFieldToggle
          domainObject={basicCard}
          name="showGoalHypothesis"
          defaultValue={basicCard?.showGoalHypothesis}
        />
      </Field>
      <Field label="Show results?">
        <ShowFieldToggle domainObject={basicCard} name="showResults" defaultValue={basicCard?.showResults} />
      </Field>
    </Stack>
  )
}

interface EntityCardFieldsProps {
  entity?: DomainEntity
}

const EntityCardFields: FC<EntityCardFieldsProps> = ({ entity }) => {
  if (!entity) {
    return null
  }

  return (
    <Stack gap={4}>
      <Field label="Show summary?">
        <ShowFieldToggle domainObject={entity} name="showSummary" defaultValue={entity?.showSummary} />
      </Field>
      <Field label="Show goal / hypothesis?">
        <ShowFieldToggle domainObject={entity} name="showGoalHypothesis" defaultValue={entity?.showGoalHypothesis} />
      </Field>
      <Field label="Show results?">
        <ShowFieldToggle domainObject={entity} name="showResults" defaultValue={entity?.showResults} />
      </Field>
    </Stack>
  )
}

interface MetricCardFieldsProps {
  metric?: DomainMetric
}

const MetricCardFields: FC<MetricCardFieldsProps> = ({ metric }) => {
  if (!metric) {
    return null
  }

  return (
    <Stack gap={4}>
      <Field label="Show description?">
        <ShowFieldToggle domainObject={metric} name="showDescription" defaultValue={metric?.showDescription} />
      </Field>
      <Field label="Show technical description?">
        <ShowFieldToggle
          domainObject={metric}
          name="showTechnicalDescription"
          defaultValue={metric?.showTechnicalDescription}
        />
      </Field>
    </Stack>
  )
}

interface StrategyCardFieldsProps {
  strategy?: DomainStrategy
}

const StrategyCardFields: FC<StrategyCardFieldsProps> = ({ strategy }) => {
  if (!strategy) {
    return null
  }

  return <Text>No fields to toggle for Submaps</Text>
}

interface Props {
  node: DomainNode
}

const CardFields: FC<Props> = ({ node }) => {
  const { data } = node
  const { id, classType } = data
  const domainObject = useGetObject(id, classType)

  switch (node.type) {
    case 'basicCard':
      return <BasicCardFields basicCard={domainObject} />
    case 'entity':
      return <EntityCardFields entity={domainObject} />
    case 'metric':
      return <MetricCardFields metric={domainObject} />
    case 'strategy':
      return <StrategyCardFields strategy={domainObject} />
    default:
  }

  return null
}

export default CardFields
