import { Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { useMemo } from 'react'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from '@app/components/ui/button'
import {
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverRoot,
  PopoverTitle,
  PopoverTrigger
} from '@app/components/ui/popover'
import type { RadioProps } from '@app/components/ui/radio'
import { Radio, RadioGroup } from '@app/components/ui/radio'
import { toaster } from '@app/components/ui/toaster'
import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import type { DomainMetric } from '@app/types'
import ICON_MAP from '@app/utils/iconMap'
import { useStrategyMetricMultiRegressionMutation } from '@graphql/queries'

type RowProps = Partial<RadioProps> & { metric: DomainMetric }

const MIN_DATA_POINTS = 5 // This is related to the number of features in the regression model.

const Row: FC<RowProps> = ({ metric, ...rest }) => {
  const disabled = metric.metricDataPointsCount < MIN_DATA_POINTS
  const color = disabled ? 'fg.muted' : 'fg'

  return (
    <Radio value={metric.id} disabled={disabled} {...rest}>
      <Text color={color}>
        {metric.name}
        {disabled && ' (not enough data)'}
      </Text>
    </Radio>
  )
}

type Props = { strategyId: string }

const AnalyzeVariance: FC<Props> = ({ strategyId }) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const metricNodes = useGetObjectsByProperties('node', { strategyId })
  const rfIds = useMemo(() => new Set(metricNodes.map((node) => node.id)), [metricNodes])
  const propCb = useCallback((metric: DomainMetric) => rfIds.has(metric.rfId), [rfIds])
  const metrics = useGetObjectsByProperties('metric', propCb)
  const sorted = useMemo(() => metrics.sort((a, b) => a.name.localeCompare(b.name)), [metrics])
  const [, mutation] = useStrategyMetricMultiRegressionMutation()
  const navigate = useNavigate()

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault()
      const formData = new FormData(e.target)
      const dependentId = formData.get('dependentId') as string

      setLoading(true)
      try {
        const response = await mutation({
          input: {
            strategyId,
            dependentId
          }
        })

        if (response.error) {
          toaster.create({
            title: 'Error',
            description: response.error.message,
            placement: 'bottom-end',
            type: 'error'
          })

          setLoading(false)
          return
        }

        const reportId = response.data.strategyMetricMultiRegression.report.id
        navigate(`/strategy/${strategyId}/map/reports/${reportId}`)
        setOpen(false)
      } finally {
        setLoading(false)
      }
    },
    [mutation, navigate, strategyId]
  )

  return (
    <PopoverRoot portalled lazyMount open={open}>
      <PopoverTrigger>
        <Button
          aria-label="Analyze Variance"
          colorPalette="gray"
          onClick={() => setOpen(!open)}
          variant="ghost"
          size="2xs"
        >
          <ICON_MAP.AnalyzeVariance />
          Analyze Variance
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader m={0} px={4} pt={2}>
          <PopoverTitle>Root metric</PopoverTitle>
        </PopoverHeader>
        <PopoverBody m={0} px={4} pt={4} maxH="80%" overflowY="auto">
          <Stack>
            <form onSubmit={onSubmit}>
              <RadioGroup name="dependentId" defaultValue="" mb={2}>
                <Stack>
                  {(sorted || []).map((metric) => (
                    <Row key={metric.id} metric={metric} />
                  ))}
                </Stack>
              </RadioGroup>
              <Button type="submit" loading={loading} w="100%">
                Run variance analysis
              </Button>
            </form>
            <Text fontSize="xs">
              After running the analysis, you will see:
              <br />
              1. The percentage of variance in your root metric explained by the model (known as R-squared).
              <br />
              2. The percentage of the explained variance attributed to each individual metric.
            </Text>
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </PopoverRoot>
  )
}

export default AnalyzeVariance
