import { HStack, Icon, Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'

import cardFontSize from '@app/shared/cards/cardFontSize'
import type { CardSize, DomainMetric } from '@app/types'
import { formatTotal, statChangeColor, statChangeIcon, statChangeText } from '@app/utils/metricHelpers'
import type { DisplayFormatEnum } from '@graphql/types'

interface Props {
  period: string
  total: number
  change: number

  displayFormat?: DisplayFormatEnum

  metric?: Pick<
    DomainMetric,
    | 'compactDisplay'
    | 'maximumDisplayPrecision'
    | 'minimumDisplayPrecision'
    | 'positiveDirection'
    | 'redecimalPercentages'
  >

  size?: CardSize
  hideChange?: boolean
}

const CardStat: FC<Props> = ({ period, total, change, displayFormat, metric, size = 'normal', hideChange = false }) => {
  const { positiveDirection } = metric

  const changeColor = statChangeColor(change, positiveDirection)
  const ChangeIcon = statChangeIcon(change)
  const changeText = statChangeText(change)
  const smallFontSize = cardFontSize('sm', size)
  const fontSize = cardFontSize('md', size)
  const boxSize = size === 'large' ? 6 : 5

  return (
    <Stack gap={0}>
      <Text color="fg.subtle" fontSize={smallFontSize} fontWeight="semibold">
        {period}
      </Text>
      <Text fontSize={fontSize} fontWeight="bold">
        {formatTotal(total, displayFormat, metric)}
      </Text>
      {!hideChange && (
        <HStack gap={0}>
          <Text color={changeColor} fontSize={fontSize}>
            {changeText}
          </Text>
          {change && (
            <Icon boxSize={boxSize} color={changeColor}>
              <ChangeIcon />
            </Icon>
          )}
        </HStack>
      )}
    </Stack>
  )
}

export default CardStat
