import { useToken } from '@chakra-ui/react'
import { CustomerPortal, useStiggContext } from '@stigg/react-sdk'
import { useEffect, useState } from 'react'

import { useColorModeValue } from '@app/components/ui/color-mode'
import Paywall from '@app/pages/settings/billing/components/paywall'
import { PageStack } from '@app/shared/layout'

const Billing = () => {
  const [, setCustomer] = useState(null)
  const { stigg } = useStiggContext()

  useEffect(() => {
    const getCustomer = async () => {
      if (stigg) {
        setCustomer(await stigg.getCustomer())
      }
    }

    getCustomer()
  }, [stigg])

  const [gray600, gray300, gray900] = useToken('colors', ['gray.600', 'gray.300', 'gray.900'])
  const backgroundColor = useColorModeValue('white', gray900)
  const iconsColor = useColorModeValue(gray600, gray300)

  const theme = {
    backgroundColor,
    iconsColor
  }

  return (
    <PageStack>
      <CustomerPortal
        paywallComponent={<Paywall onProvision={async () => setCustomer(await stigg.getCustomer())} />}
        theme={theme}
      />
    </PageStack>
  )
}

export default Billing
