import { Box, Flex, Stack } from '@chakra-ui/react'
import type { FC } from 'react'
import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import type { OperationResult } from 'urql'

import EmptyStatePrompt from './components/emptyStatePrompt'

import ChatDrawerHeader from '@app/pages/maps/components/aiChat/components/chatDrawerHeader'
import PromptForm from '@app/pages/maps/components/aiChat/components/promptForm'
import PipelineInstructions from '@app/pages/maps/components/aiChat/pipelineInstructions'
import type { PipelineRunMutation } from '@graphql/queries'
import type { PipelineEnum } from '@graphql/types'

type Props = {
  pipelineId: PipelineEnum
  strategyId?: string | null
}

const New: FC<Props> = ({ pipelineId, strategyId: propStrategyId }) => {
  const { strategyId: paramStrategyId } = useParams()
  const strategyId = propStrategyId || paramStrategyId
  const navigate = useNavigate()
  const onSuccess = useCallback(
    (result: OperationResult<PipelineRunMutation>) => {
      const chatId = result?.data?.pipelineRun?.chat?.id

      if (chatId) {
        navigate(`../${chatId}`)
      }
    },
    [navigate]
  )

  return (
    <Flex pos="relative" direction="column" overflow="hidden" h="100%" bg="bg.canvas">
      <ChatDrawerHeader />
      <Flex overflowY="auto" pt="0" pb="40" grow={1}>
        <Stack gap="10" w="100%" mx="auto" px={4}>
          <EmptyStatePrompt>
            <PipelineInstructions pipelineId={pipelineId} />
          </EmptyStatePrompt>
        </Stack>

        <Box
          pos="absolute"
          bottom="0"
          mx="4"
          py="4"
          bgGradient="to-t"
          gradientFrom="bg.canvas 80%"
          gradientTo="rgba(0,0,0,0)"
          insetX="0"
        >
          <Stack mx="auto">
            <PromptForm pipelineId={pipelineId} strategyId={strategyId} onSuccess={onSuccess} />
          </Stack>
        </Box>
      </Flex>
    </Flex>
  )
}

export default New
